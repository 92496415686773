import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/enterpriseOrg/types";

import getEnterpriseOrg from "./getEnterpriseOrg";
import getEnterpriseOrgSeat from "./getEnterpriseOrgSeat";
import updateEnterpriseOrg from "./updateEnterpriseOrg";
import getEnterpriseOrgSeats from "./getEnterpriseOrgSeats";
import getEnterprisesList from "./getEnterprisesList";
import getVolumeTiersForEnterprise from "./getVolumeTiersForEnterprise";
import getEnterpriseCobranding from "./getEnterpriseCobranding";
import postEnterpriseAsset from "./postEnterpriseAsset";
import deleteEnterpriseAsset from "./deleteEnterpriseAsset";
import getEnterpriseCustomFields from "./getEnterpriseCustomFields";
// IMPORT_PT (for script, do not remove)

function* watchEnterpriseOrgSagas() {
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_ORG_REQUEST, getEnterpriseOrg);

  yield trackRequests(takeEvery, types.GET_ENTERPRISE_ORG_SEAT_REQUEST, getEnterpriseOrgSeat);

  yield trackRequests(takeEvery, types.UPDATE_ENTERPRISE_ORG_REQUEST, updateEnterpriseOrg);

  yield trackRequests(takeEvery, types.GET_ENTERPRISE_ORG_SEATS, getEnterpriseOrgSeats);
  yield trackRequests(takeEvery, types.GET_ENTERPRISES_LIST, getEnterprisesList);
  yield trackRequests(takeEvery, types.GET_VOLUME_TIERS_FOR_ENTERPRISE, getVolumeTiersForEnterprise);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_COBRANDING, getEnterpriseCobranding);
  yield trackRequests(takeEvery, types.POST_ENTERPRISE_ASSETS, postEnterpriseAsset);
  yield trackRequests(takeEvery, types.DELETE_ENTERPRISE_ASSET, deleteEnterpriseAsset);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_CUSTOM_FIELDS, getEnterpriseCustomFields);

  // INSERTION_PT (for script, do not remove)
}

export default watchEnterpriseOrgSagas;

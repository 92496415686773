import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";
import { setBlockerAssets } from "../../projectBlockers/slice";
import { ProjectBlockerAssetType } from "../../projectBlockers/enums";
import { PROJECT_BLOCKER_TAGS } from "../../projectBlockers/tags";
import { projectBlockersApi } from "../../projectBlockers/endpoints";

function* postNewAsset(action) {
  const { leadId, body, fileUrl, urls, blockerId, ownerId } = action.payload;

  const id = leadId || ownerId;

  let url = `/provider_leads/${id}/assets`;

  if (blockerId) {
    url += "/blocker";
  }

  const payload = {
    body,
    ...(blockerId ? { file_urls: urls, blocker_id: blockerId } : { file_url: fileUrl }),
  };

  const { success, data, error } = yield* makeRequest.post(url, payload);

  if (success && data) {
    const assets = keysToCamelCase(data.body.assets);
    const blockerAssetTypes = Object.values(ProjectBlockerAssetType);
    const blockerAssets = assets.filter((asset) => blockerAssetTypes.includes(asset.assetType));

    yield put(setBlockerAssets(blockerAssets));
    yield put(actions.setAssets(assets));
    if (blockerId) {
      yield put(projectBlockersApi.util.invalidateTags([PROJECT_BLOCKER_TAGS.PROJECT_BLOCKERS]));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_POSTING_NEW_ASSET,
    });
  }
  return null;
}

export default postNewAsset;

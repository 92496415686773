import { styled } from "@mui/material/styles";

export const SelectSponsorContainer = styled("div")(() => ({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.colors.white,
  borderRadius: "10px",
  padding: "39px",
  margin: "50px",
  width: "fit-content",
  maxWidth: "1160px",
  backgroundImage: "url('/img/background-lines-top-right-3.png')",
  backgroundSize: "350px",
  backgroundPosition: "right top",
  backgroundRepeat: "no-repeat",
}));

export const SelectSponsorHeader = styled("div")(() => ({ theme }) => ({
  width: "70%",
  minwidth: "315px",
  marginBottom: "40px",
  marginTop: "20px",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "44px",
  lineHeight: "53px",
  color: theme.colors["blue-ribbon"],
}));

export const SelectSponsorNote = styled("div")(() => ({ theme }) => ({
  display: "flex",
  backgroundColor: theme.colors["clear-skies"],
  padding: "16px",
  borderRadius: "2px",
  marginBottom: "24px",
}));

export const SelectSponsorNoteText = styled("div")(() => ({ theme }) => ({
  marginLeft: "16px",
  fontWeight: 500,
  fontSize: "16px",
  color: theme.colors["darkest-blue"],
}));

export const SponsorCardContainer = styled("div")(() => () => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "12px",
}));

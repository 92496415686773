import { GettingStartedIcon } from "@icons";
import tabs from "containers/Sales/gettingStarted/tabs";
import l from "@loadable/component";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/GettingStartedBreadCrumbs";

const content = l(() => import("containers/Sales/gettingStarted"));

export default {
  key: "getting-started",
  title: breadCrumbs,
  path: "/getting-started/:tab?/:modal?",
  linkTarget: "/getting-started/learn-more",
  linkTitle: "Getting Started",
  linkCategory: "Learn",
  icon: GettingStartedIcon,
  basePath: "getting-started",
  requiredParams: ["tabs"],
  optionalParams: ["modal"],
  tabs,
  content,
  sidebar: null,
};

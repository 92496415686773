import Cookies from "universal-cookie";
import _ from "lodash";
import SettingsClient from "util/SettingsClient";
import jwtDecode from "jwt-decode";
import SessionUser from "../models/SessionUser";

export type CallByDotNotationFuncTypes = "isExternal" | "isSeatedNonOwnerUser";

class SessionClient {
  setSession(data) {
    const { localStorage } = window;
    if (!data.body.jwt) {
      throw Error("invalid session, no jwt present");
    }

    localStorage.setItem("session", JSON.stringify(data.body));
  }

  setUserProps(props) {
    this.user = Object.assign(this.user?.props, props);
  }

  get adminJwt() {
    let adminLogin;
    document.cookie.split("; ").forEach((c) => {
      const name = c.split("=")[0];
      const value = c.split("=")[1];
      if (name === "admin_login") {
        adminLogin = value;
      }
    });
    return adminLogin;
  }

  get jwt() {
    return this.adminJwt || this.data.jwt;
  }

  get data() {
    return JSON.parse(localStorage.getItem("session") || "{}") || {};
  }

  get user() {
    if (!this.data.user) {
      return null;
    }
    return new SessionUser(this.data.user);
  }

  set user(userData) {
    const body = this.data;
    body.user = userData;
    this.setSession({ body });
  }

  get rbac() {
    if (!this.data.rbac) {
      return null;
    }
    return this.data.rbac;
  }

  set rbac(resData) {
    const body = this.data;
    body.rbac = resData.rbac;
    body.jwt = resData.jwt;
    this.setSession({ body });
  }

  isSessionUser(id) {
    return id === this.user?.props?.id;
  }

  get impersonating() {
    if (!this.data.impersonator) {
      return null;
    }
    return this.data.impersonator;
  }

  set impersonating(data) {
    const body = this.data;
    body.impersonator = data;
    this.setSession({ body });
  }

  // deprecate
  get showBanner() {
    return this.impersonating || this.userPhase === "up6";
  }

  get enrollmentStatus() {
    if (!this.user) {
      return null;
    }

    return this.user.props.subscriptionStatus;
  }

  showLeaderboards() {
    if (!this.user) {
      return null;
    }
    const show = this.user.props.enterprise?.recognitionEmails;
    if (typeof show === "boolean") {
      return show;
    }
    return true;
  }

  get enrollments() {
    if (!this.user) {
      return null;
    }
    return this.user.props.enrollments;
  }

  get seatAssignments() {
    if (!this.user) {
      return null;
    }
    return this.user.props.seatAssignments;
  }

  get activePowurLiveService() {
    // Merges users' enrollments array and seat-assignments array to find any powur_live slug:
    const powurLiveEnrollment = this.enrollments.find((e) => e?.service?.slug === "powur_live");
    const powurLiveSeatAssignment = this.seatAssignments?.find((e) => e?.slug === "powur_live");
    const now = new Date(Date.now()) as unknown as number;
    const end = new Date(powurLiveSeatAssignment?.end_day || now) as unknown as number;
    const ended = now - end > 0;
    const status =
      powurLiveEnrollment?.payment_status === "active" ||
      powurLiveEnrollment?.payment_status === "canceling" ||
      (powurLiveSeatAssignment?.id && !ended);
    return status;
  }

  get pendingEnrollment() {
    // bypass certification wall if seated consultant
    if (this.isSeatedSeller) return false;

    return this.enrollmentStatus === "needs_certification";
  }

  get noEnrollment() {
    return this.enrollmentStatus === "never_subscribed";
  }

  get canceledEnrollment() {
    return this.enrollmentStatus === "canceled";
  }

  get banned() {
    return this.enrollmentStatus === "banned";
  }

  get activeEnrollment() {
    return !this.pendingEnrollment && !this.noEnrollment && !this.canceledEnrollment && !this.banned;
  }

  get isAmbassador() {
    return this.rbac.permissions.some((p) => p.name === "ambassador");
  }

  get isFormerAmbassador() {
    return this.isAmbassador && !this.noEnrollment;
  }

  get isEnterpriseStaff() {
    return this.rbac.permissions.some((p) =>
      ["enterprise_admin", "enterprise_support", "enterprise_lead_generator"].includes(p.name),
    );
  }

  get isEnterpriseStaffOnly() {
    const isStaffRole = this.rbac.permissions.some((p) => {
      return ["enterprise_admin", "enterprise_support", "enterprise_lead_generator"].includes(p.name);
    });
    const isOrgSeller = this.user?.props.roles.some((r) => r.name === "org_seller");
    return isStaffRole && !isOrgSeller;
  }

  get isEnterprise() {
    // Enterprise, not seller pro
    return this.user?.props.enterpriseType === "powur_enterprise";
  }

  get isEnterpriseProOwner() {
    return this.isOrgOwner && this.isEnterprise;
  }

  get isEnterpriseProSeatedUser() {
    return this.hasSeat && this.isEnterprise;
  }

  get portalAccess() {
    return this.rbac?.permissions?.some((p) => p.name === "portal_access");
  }

  get universityOnly() {
    return this.rbac.permissions.some((p) => p.name === "university_only");
  }

  get hasSeat() {
    return this.user?.props.seatId;
  }

  set banners(data) {
    const body = this.data;
    body.banners = data;
    this.setSession({ body });
  }

  get banners() {
    return this.data.banners;
  }

  get isOrgOwner() {
    return this.user?.props.enterprise?.isOwner;
  }

  get isSeatedSeller() {
    return this.portalAccess && this.hasSeat && !this.isOrgOwner;
  }

  get isSeatedNonOwnerUser() {
    return this.hasSeat && !this.isOrgOwner;
  }

  get isExternal() {
    return !!this.user?.props.enterprise?.external;
  }

  get userPhase() {
    const { displayRoles, dashboardPhase } = this.user?.props;
    const userPhaseRoleExceptions = ["Powur Enterprise", "Org Manager", "Org Support", "Org Seller"];

    const isUserPhaseSkipped = displayRoles.some((displayedRole) =>
      userPhaseRoleExceptions.some((role) => role === displayedRole[0]),
    );
    if (isUserPhaseSkipped && (dashboardPhase !== 1 || this.isSeatedSeller)) return "up7";
    return `up${this.user?.props.dashboardPhase}`;
  }

  get backOfficeAccess() {
    // (This method must be updated in tandem with BE's User.subscription_status)
    switch (this.enrollmentStatus) {
      // No back office access:
      case "banned":
      case null:
        return false;
      // preview_user w/'needs_activation' access:
      case "never_subscribed":
      case "canceled":
        return "needs_activation";
      // preview_user w/'needs_certification' access:
      case "needs_certification":
        return "needs_certification";
      case "integrity_restricted":
        return "integrity_restricted";
      // full, permission dependent, back office access:
      case "active":
      case "canceling":
      case "only_seated":
      case "past_due":
        return true;
      // Error catch for unexpected enrollment statuses:
      default:
        throw new Error(`Error with '${this.enrollmentStatus}' enrollmentStatus`);
    }
  }

  get backOfficeAccessTrue() {
    return this.backOfficeAccess === true;
  }

  get username() {
    return localStorage.getItem("username");
  }

  set username(name) {
    if (typeof name === "string") {
      localStorage.setItem("username", name);
    }
  }

  clearUsername() {
    localStorage.removeItem("username");
  }

  set uiState(data) {
    localStorage.setItem("uiState", JSON.stringify(data));
  }

  get uiState() {
    return JSON.parse(localStorage.getItem("uiState") || "{}");
  }

  setUiStateAttributes(obj) {
    const state = JSON.parse(localStorage.getItem("uiState") || "{}");
    const newState = { ...state, ...obj };
    localStorage.setItem("uiState", JSON.stringify(newState));
  }

  get expires(): string | null {
    try {
      return jwtDecode<{ expires: string }>(this.jwt).expires;
    } catch (e) {
      return null;
    }
  }

  get created_at(): string | null {
    try {
      return jwtDecode<{ created_at: string }>(this.jwt).created_at;
    } catch (e) {
      return null;
    }
  }

  get validSession() {
    const settings = new SettingsClient();
    if (this.expires) {
      const exp = new Date(this.expires.replace(" UTC", ""));
      exp.setHours(exp.getHours() - 8);
      return exp > new Date();
    }
    if (this.created_at) {
      const exp = new Date(this.created_at.replace(" UTC", ""));
      exp.setHours(exp.getHours() - 8);
      return exp > new Date(Date.now() - settings.data.max_login_lifetime);
    }
    return false;
  }

  clearAdmin() {
    new Cookies().remove("admin_login");
  }

  callByDotNotation(path: CallByDotNotationFuncTypes) {
    return _.get(this, path);
  }

  clear() {
    this.clearAdmin();
    localStorage.removeItem("session");
    localStorage.removeItem("uiState");
    localStorage.removeItem("i18nextLng");
  }
}

export default SessionClient;

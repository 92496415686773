// import content from "containers/Account/account";
import tabs from "containers/Account/Billing/tabs";
import l from "@loadable/component";
import SessionClient from "util/SessionClient";

const session = new SessionClient();
const { backOfficeAccess } = session;

const content = l(() => import("containers/Account/Billing"));

export default {
  key: "account",
  title: "Account",
  path: "/account/:tab?/:modal?",
  linkTarget: "/account",
  linkTitle: "Account",
  linkCategory: "Personal",
  icon: () => null,
  basePath: "account",
  requiredParams: [],
  optionalParams: ["tab", "modal"],
  tabs: backOfficeAccess === "needs_activation" && !session.user.props.enrollment?.endDay ? null : tabs,
  content,
  sidebar: null,
};

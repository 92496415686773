import CAN_SUBMIT_PROPOSAL from "./canSubmitProposal";
import CAN_CREATE_PROJECT from "./canCreateProject";
import PLATFORM_CONFIGURATION from "./platformConfig";
import CAREERS_UPDATES from "./careersUpdates";
import STATE_LICENSES_UPDATES from "./stateLicensesUpdates";
import FINANCE_MANAGEMENT from "./financeManagement";
import FINANCE_MANAGEMENT_READ_ONLY from "./financeManagementReadOnly";
import PROJECT_USER_UPDATES from "./projectUserUpdates";
import OPERATIONS_MANAGEMENT from "./operationsManagement";
import PLATFORM_COMMUNICATIONS from "./platformCommunications";
import PREVIEW_USER from "./previewUser";
import SERVICE_AREA_UPDATES from "./serviceAreaUpdates";
import ADMIN_DASHBOARD_VIEW from "./adminDashboardView";
import TICKETS_VIEW from "./ticketsView";
import PROJECTS_VIEW from "./projectsView";
import MARKETING_VIEW from "./marketingView";
import EARNINGS_VIEW from "./earningsView";
import REPORTS_VIEW from "./reportsView";
import TEAM_VIEW from "./teamView";
import SALES_DASHBOARD_VIEW from "./salesDashboardView";
import ENTERPRISE_LEAD_GENERATOR from "./enterpriseLeadGenerator";
import PORTAL_ACCESS from "./portalAccess";
import POWUR_AMBASSADOR from "./ambassador";
import PROJECT_MANAGEMENT from "./projectManagement";
import PROPOSAL_MANAGEMENT from "./proposalManagement";
import USER_IMPERSONATE from "./userImpersonate";
import USERS_READ from "./usersRead";
import USERS_UPDATE from "./usersUpdate";
import ENTERPRISE_ADMIN from "./enterpriseAdmin";
import ENTERPRISE_ADMIN_PRO from "./enterpriseAdminPro";
import ENTERPRISES_SUPER_ADMIN from "./enterprisesSuperAdmin";
import ENTERPRISE_SUPPORT from "./enterpriseSupport";
import CREATE_OFFERS from "./createOffers";
import VIEW_FINANCIALS from "./viewFinancials";
import SITE_SURVEYOR from "./siteSurveyor";
import LOGGED_ACTIONS from "./loggedActions";
import HAS_VISION_ACCESS from "./hasVisionAccess";
import ROOF_SUBMIT from "./roofSubmit";
import ROOF_DESIGN_TEAM from "./roofDesignTeam";
import ROOF_DESIGN_ADMIN from "./roofDesignAdmin";
import CAN_REQUEST_VISION_SELF_DESIGN from "./canRequestVisionSelfDesign";
import AMBASSADOR_CREATE from "./canCreateAmbassadorLead";
import DEVELOPER from "./developer";
import ENTERPRISE_EARNINGS_VIEW from "./enterpriseEarningsView";
import SUNNOVA_CERTIFIED from "./sunnovaCertified";
import SUNNOVA_DESIGN_TEAM from "./sunnovaDesignTeam";
import NEVADA_SELLER from "./nevadaSeller";
import RSM_AUTHORITY from "./rsmAuthority";
import VISION_SERIVCE_BUREAU_ADMIN from "./visionServiceBureauAdmin";
import VISION_ADMIN from "./visionAdmin";
import COBRANDING_ACCESS from "./cobrandingAccess";
import ENTERPRISE_CUSTOM_FIELDS_ACCESS from "./enterpriseCustomFieldsAccess";
import { DecisionMethodsT } from "../types";

export const decisions: DecisionMethodsT = {
  CAN_SUBMIT_PROPOSAL,
  CAN_CREATE_PROJECT,
  PLATFORM_CONFIGURATION,
  CAREERS_UPDATES,
  STATE_LICENSES_UPDATES,
  PORTAL_ACCESS,
  POWUR_AMBASSADOR,
  PROJECT_MANAGEMENT,
  PROPOSAL_MANAGEMENT,
  USER_IMPERSONATE,
  USERS_READ,
  USERS_UPDATE,
  ENTERPRISE_ADMIN,
  ENTERPRISE_ADMIN_PRO,
  ENTERPRISE_SUPPORT,
  CREATE_OFFERS,
  OPERATIONS_MANAGEMENT,
  PLATFORM_COMMUNICATIONS,
  PREVIEW_USER,
  SERVICE_AREA_UPDATES,
  FINANCE_MANAGEMENT,
  FINANCE_MANAGEMENT_READ_ONLY,
  PROJECT_USER_UPDATES,
  ADMIN_DASHBOARD_VIEW,
  TICKETS_VIEW,
  PROJECTS_VIEW,
  MARKETING_VIEW,
  EARNINGS_VIEW,
  REPORTS_VIEW,
  TEAM_VIEW,
  SALES_DASHBOARD_VIEW,
  ENTERPRISE_LEAD_GENERATOR,
  ENTERPRISES_SUPER_ADMIN,
  VIEW_FINANCIALS,
  SITE_SURVEYOR,
  LOGGED_ACTIONS,
  HAS_VISION_ACCESS,
  ROOF_SUBMIT,
  ROOF_DESIGN_TEAM,
  ROOF_DESIGN_ADMIN,
  CAN_REQUEST_VISION_SELF_DESIGN,
  AMBASSADOR_CREATE,
  DEVELOPER,
  ENTERPRISE_EARNINGS_VIEW,
  SUNNOVA_CERTIFIED,
  SUNNOVA_DESIGN_TEAM,
  NEVADA_SELLER,
  RSM_AUTHORITY,
  VISION_SERIVCE_BUREAU_ADMIN,
  VISION_ADMIN,
  COBRANDING_ACCESS,
  ENTERPRISE_CUSTOM_FIELDS_ACCESS,
};

export default decisions;

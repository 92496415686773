import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { MagnifyingGlassBold } from "@icons";
import { Input, Button, Popover } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { parse } from "query-string";
import useRouteMatcher from "hooks/useRouteMatcher";
import styles from "./SidebarSearch.module.less";

const SidebarSearch = (props) => {
  const { navigate, searchKey, label, customSearchPopover } = props;

  const [searchValue, setSearchValue] = useState("");

  const location = useLocation();
  const { tab: currentTab } = useParams();
  const { search } = location;

  const projectLocations = ["/projects", "/admin-projects", "/org-projects"];
  const { isEnterprise } = useRouteMatcher();

  const searchTipDisplay = projectLocations.some((pl) => location.pathname.includes(pl));

  const handleSearchChange = (e) => {
    const removeBadWhitespace = e.target.value.replace(/, /g, ",");
    setSearchValue(removeBadWhitespace);
  };

  const handleSearchSubmit = useCallback(() => {
    navigate({ [searchKey]: searchValue });
  }, [searchValue, navigate, searchKey]);

  const handleClear = useCallback(() => {
    const query = parse(search);
    setSearchValue("");
    if (query[searchKey]) {
      navigate({ [searchKey]: null });
    }
  }, [navigate, searchKey, search]);

  const buildSearchLabel = useMemo(() => {
    if (label !== "Search" || !currentTab) return label;
    if (currentTab === "list") return "Search";
    if (currentTab === "leads" && !isEnterprise) return "Invites Search";
    if (currentTab === "installs") return "Installed Search";
    if (currentTab === "users" && isEnterprise) return "Seated Users Search";
    return `${currentTab} Search`;
  }, [currentTab, label, isEnterprise]);

  const defaultPopoverContent = (
    <>
      <div className={styles.title}>Search by</div>
      <div className={styles.contentRow}>
        • <span className={styles.bold}>Seller </span>
        (First and/or Last Name)
      </div>
      <div className={styles.contentRow}>
        • <span className={styles.bold}>Project ID </span>
        (Single or Multiple separated by commas)
      </div>
      <div className={styles.contentRow}>
        • <span className={styles.bold}>Phone Number </span>
        (Format: XXX.XXX.XXXX)
      </div>
    </>
  );

  const searchButton = useMemo(() => {
    const query = parse(search);
    if (searchValue && searchValue.length > 0 && query[searchKey]) {
      return (
        <Button
          className={styles.inlineClearButton}
          icon={<CloseOutlined className={styles.closeIcon} />}
          onClick={handleClear}
        />
      );
    }

    return (
      <Button
        data-test-id="sidebarSearch-button"
        type="primary"
        onClick={handleSearchSubmit}
        size="small"
        className={styles.inlineSubmitButton}
        icon={
          <span className={styles.icon}>
            <MagnifyingGlassBold width="10px" height="10px" fill="white" />
          </span>
        }
      />
    );
  }, [searchValue, handleClear, handleSearchSubmit, search, searchKey]);

  useEffect(() => {
    const query = parse(search);
    if (query[searchKey]) setSearchValue(query[searchKey]);
    else setSearchValue("");
  }, [search, searchKey]);

  return (
    <div className={styles.searchContainer}>
      <div className={styles.label}>
        {buildSearchLabel}
        {searchTipDisplay && (
          <Popover
            overlayClassName="searchTip"
            placement="bottomLeft"
            arrowPointAtCenter
            content={customSearchPopover || defaultPopoverContent}
          >
            <div className={styles.searchBadge}>?</div>
          </Popover>
        )}
      </div>
      <div className={styles.inputContainer}>
        <Input
          data-test-id="SidebarSearch-input"
          className={styles.search}
          value={searchValue}
          onChange={handleSearchChange}
          onPressEnter={handleSearchSubmit}
          suffix={searchButton}
        />
      </div>
    </div>
  );
};

SidebarSearch.propTypes = {
  navigate: PropTypes.func.isRequired,
  searchKey: PropTypes.string,
  label: PropTypes.string,
  customSearchPopover: PropTypes.node,
};

SidebarSearch.defaultProps = {
  searchKey: "q",
  label: "Search",
};

export default SidebarSearch;

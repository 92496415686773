import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import SessionClient from "../util/SessionClient";
import UserClass from "./User";

export default class SessionUser extends UserClass {
  constructor(props) {
    const userProps = { ...props };
    if (!props.enrollment) {
      userProps.enrollment = {};
    }
    if (!props.address) {
      userProps.address = {};
    }
    super(userProps);
    this.session = new SessionClient();
  }

  patch() {
    return super.patch().then(this.resToSession);
  }

  acceptTos() {
    return this.api.patch("/user/tos", {}).then(this.resToSession);
  }

  resToSession = (res) => {
    if (res.body.user) this.session.user = res.body.user;
    if (res.body.impersonating) this.session.impersonating = res.body.impersonating;
    if (res.body.rbac) this.session.rbac = res.body;
    else this.session.user = res.body;
    return res;
  };

  reload() {
    return this.get().then(this.resToSession).then(this.initStore);
  }

  initStore = (data) => {
    const { user, rbac, notices, mentor_rating } = data.body;

    if (user) {
      store.dispatch(actions.setCurrentUser(camelizeKeys(user)));
    }

    if (rbac && rbac.permissions) {
      const { permissions } = rbac;
      store.dispatch(actions.setUserPermissions({ permissions }));
    }

    if (notices) {
      store.dispatch(actions.updateUnviewedNotices(notices));
    }

    if (mentor_rating) {
      store.dispatch(actions.setMentorRating(camelizeKeys(mentor_rating)));
    }
  };

  clearStore = () => {
    store.dispatch(actions.clearRbac());
  };
}

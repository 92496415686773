export default {
  // general/success
  SUCCESS_INVITE_NEW_TEAM_LEAD: "Invitation email sent to new team lead.",
  ARCHIVING_TEAM_LEAD_SUCCESS: "Team lead archived.",
  UNARCHIVING_TEAM_LEAD_SUCCESS: "Team lead unarchived.",
  SUCCESS_UPDATE_PENDING_INVITE: "Pending Invite Updated",
  INVITE_DELETE_SUCCESS: "Successfully Deleted Invite",

  // errors
  ERROR_FETCHING_TEAM_LEADS_LIST: "There was an error retrieving team leads list",
  ERROR_FETCHING_TEAM_LIST: "There was an error retrieving team list",
  ERROR_FETCHING_AMBASSADOR_LIST: "There was an error retrieving ambassador list",
  ERROR_FETCHING_TEAM_UPLINE_LIST: "There was an error retrieving leadership list",
  ERROR_FETCHING_USER_DETAILS: "There was an error retrieving user details",
  ERROR_FETCHING_USER_RECRUITS: "There was an error retrieving this user's recruits",
  ERROR_SENDING_INVITE_TO_NEW_TEAM_LEAD: "There was an error sending an invitation to new team lead",
  ERROR_ARCHIVING_TEAM_LEAD: "There was an error archiving the team lead.",
  ERROR_UNARCHIVING_TEAM_LEAD: "There was an error unarchiving the team lead.",
  ERROR_FETCHING_INVITE_DETAILS: "There was an error getting details for this invite",
  ERROR_UPDATING_PENDING_INVITE: "There was an error updating this invite",
  ERROR_DELETING_INVITE: "There was an error deleting this invite",
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getRdxSelectionMapper } from "@rdxprops";

import useNavigation from "@navigation";
import SidebarSearch from "components/PlatformLayout/components/Sidebar/Search";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import ResetFiltersButton from "@buttons/ResetFilters";
import LeadsFilters from "@sales/team/components/Sidebar/leadsfilters";
import styles from "@enterprise/enterprisePersonnel/EnterprisePersonnel.module.less";

const EnterprisePersonnelSidebar = (props) => {
  const { filters, setIsHidden, setIsCollapsed } = props;

  const location = useLocation();
  const { tab } = useParams();

  const isInvites = location.pathname.includes("invites");

  const { navigate, override } = useNavigation("org-team", ["tab"], ["userId"]);

  const menus = [
    {
      key: "user_role",
      label: "User Role",
      options: [
        {
          key: "any-role",
          label: "All",
          default: true,
          index: 0,
          parentKey: "user_role",
        },
      ],
    },
    {
      key: "location",
      label: "Location",
      options: [
        {
          key: "any-location",
          label: "All",
          default: true,
          index: 1,
          parentKey: "location",
        },
      ],
    },
  ];

  if (filters.user_roles) {
    menus[0].options.push(
      ...filters.user_roles.values.map((r) => ({
        key: r,
        label: r,
        default: false,
        index: 0,
        parentKey: "user_role",
      })),
    );
  }

  useEffect(() => {
    if (tab === "seat-approvals") {
      setTimeout(() => {
        setIsHidden(true);
        setIsCollapsed(true);
      }, 100);
    } else {
      setIsHidden(false);
      setIsCollapsed(false);
    }
  }, [setIsHidden, setIsCollapsed, tab]);

  if (filters.locations) {
    menus[1].options.push(
      ...filters.locations.values.map((l) => ({
        key: Object.keys(l)[0],
        label: Object.values(l)[0],
        default: false,
        index: 1,
        parentKey: "location",
      })),
    );
  }

  return (
    tab !== "seat-approvals" && (
      <div className={styles.enterprisePersonnelSidebar}>
        <SidebarSearch navigate={navigate} />
        {isInvites ? (
          <LeadsFilters navigate={navigate} override={override} isEnterprise />
        ) : (
          <SidebarFilters menus={menus} navigate={navigate} override={override} />
        )}
        <ResetFiltersButton />
      </div>
    )
  );
};

export default connect(getRdxSelectionMapper({ filters: "selectEnterprisePersonnelFilters" }))(
  EnterprisePersonnelSidebar,
);

export default {
  // general
  OPEN_DELETE_LEAD_MODAL: "Open delete lead modal.",
  LEAD_CREATE_SUCCESS: "Lead created successfully.",
  LEAD_UPDATE_SUCCESS: "Lead details saved.",
  LEAD_DELETE_SUCCESS: "Lead has been deleted.",
  LEAD_SUBMIT_SUCCESS: "Lead submitted.",
  EDIT_LEAD_FORM_TOUCHED: "Edit Lead form touched.",
  LEAD_FORM_RESET: "Edit Lead Form Reset.",
  SAVE_LEAD_INFO: "Update Lead Selected.",
  HOMEOWNER_AVAILABILITY_DELETE_SUCCESS: "Homeowner Availabilities have been deleted",
  CONFIRM_SITE_LOCATION_SUCCESS: "Site location confirmed.",
  CLOSE_LEAD_SUCCESS: "Lead closed.",
  REOPEN_LEAD_SUCCESS: "Lead reopened.",
  LEAD_OWNER_UPDATED: "Lead owner updated.",

  // errors
  ERROR_CREATING_LEAD: "There was an error creating this lead",
  ERROR_UPDATING_LEAD: "There was an error updating lead details",
  ERROR_SUBMITTING_LEAD: "There was an error submitting this lead",
  ERROR_DELETING_LEAD: "There was an error deleting this lead",
  ERROR_FETCHING_LEAD_DETAILS: "There was an error getting details for this lead",
  ERROR_FETCHING_LEADS: "There was an error getting leads list",
  ERROR_FETCHING_EVENTS: "There was an error getting events list",
  ERROR_DELETING_HOMEOWNER_AVAILABILITY: "There was an error deleting a homeowner availability.",
  ERROR_FETCHING_UTILITY_COMPANIES: "There was an error getting eligible utility companies.",
  ERROR_FETCHING_HOMEOWNER_NOTES: "There was an error getting the notes for this homeowner.",
  ERROR_CONFIRMING_SITE_LOCATION: "There was an error confirming this site location.",
  ERROR_CLOSING_LEAD: "There was an error closing this lead",
  ERROR_REOPENING_LEAD: "There was an error reopening this lead",
  ERROR_UPDATING_LEAD_OWNER: "There was an error updating the owner of this lead",
  ERROR_CREATING_LEAD_ASSET: "There was an error saving an asset for this lead",
};

// React
import React, { useState, useEffect } from "react";

// Ant Design
import { Layout } from "antd";

// Util
import ReactResizeDetector from "react-resize-detector";
import { Helmet } from "react-helmet";

// Models
import User from "models/User";
import Router from "models/Router";

// Components
import { Header, Footer, GetQuoteModal } from "components/HomepageComponents";
import JoinModal from "components/HomepageComponents/JoinModal";
import PageHeader from "./components/PageHeader";
import CounterRow from "./components/CounterRow";
import StepsRow from "./components/StepsRow";
import JoinRow from "./components/JoinRow";

// CSS Modules
import styles from "./AmbassadorJoin.module.less";

const { Content } = Layout;

const AmbassadorJoinContainter = (props) => {
  const { user } = props;
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) setReferrer(new User(user));
  }, [user]);

  // const userAvatar = () => {
  //   return user?.avatar?.large || "/img/default-profile.png";
  // };

  const description =
    "Become a solar ambassador, get paid to share the benefits of solar energy. Help us change the world.";

  return (
    <Layout className={styles.pageLayout}>
      <Helmet>
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <title>Become a Solar Ambassador and Get Paid for Changing The World | Powur Energy</title>
        <meta name="title" content="Become a Solar Ambassador and Get Paid for Changing The World | Powur Energy" />
        <meta
          name="description"
          content="Get paid to share the benefits of solar energy. Every time you refer a homeowner to go solar with Powur, you'll earn $1,000. Click here to learn more."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/ambassador" />
        <meta
          property="og:title"
          content="Become a Solar Ambassador and Get Paid for Changing The World | Powur Energy"
        />
        <meta
          property="og:description"
          content="Get paid to share the benefits of solar energy. Every time you refer a homeowner to go solar with Powur, you'll earn $1,000. Click here to learn more."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/ambassador" />
        <meta
          property="twitter:title"
          content="Become a Solar Ambassador and Get Paid for Changing The World | Powur Energy"
        />
        <meta
          property="twitter:description"
          content="Get paid to share the benefits of solar energy. Every time you refer a homeowner to go solar with Powur, you'll earn $1,000. Click here to learn more."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <ReactResizeDetector
        handleWidth
        refreshMode="debounce"
        refreshRate={100}
        render={() => (
          <Content className={styles.content}>
            <Header user={referrer?.props} />
            <PageHeader setRegisterModalVisible={setRegisterModalVisible} />
            <CounterRow />
            <StepsRow />
            <JoinRow referrer={referrer} setRegisterModalVisible={setRegisterModalVisible} />
            <JoinModal visible={registerModalVisible} setVisible={setRegisterModalVisible} />
          </Content>
        )}
      />
      <GetQuoteModal />
      <Footer user={props.match.params.user} />
    </Layout>
  );
};

AmbassadorJoinContainter.propTypes = {
  user: User.types(),
  match: Router.matchTypes(),
};

export default AmbassadorJoinContainter;

import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/team/types";
import getTeamLeadsList from "./getTeamLeadsList";
import getTeamList from "./getTeamList";
import getAmbassadorList from "./getAmbassadorList";
import getTeamUplineList from "./getTeamUplineList";
import getTeamUserDetails from "./getTeamUserDetails";
import getTeamUserRecruits from "./getTeamUserRecruits";
import archiveTeamLead from "./archiveTeamLead";
import unarchiveTeamLead from "./unarchiveTeamLead";
import inviteNewTeamLead from "./inviteNewTeamLead";
import getInviteDetails from "./getInviteDetails";
import updatePendingInvite from "./updatePendingInvite";
import deleteInvite from "./deleteInvite";
// IMPORT_PT (for script, do not remove)

function* watchTeamSagas() {
  yield trackRequests(takeEvery, types.GET_TEAM_LEADS_LIST_REQUEST, getTeamLeadsList);
  yield trackRequests(takeEvery, types.GET_TEAM_LIST_REQUEST, getTeamList);
  yield trackRequests(takeEvery, types.GET_AMBASSADOR_LIST_REQUEST, getAmbassadorList);
  yield trackRequests(takeEvery, types.GET_TEAM_UPLINE_LIST_REQUEST, getTeamUplineList);
  yield trackRequests(takeEvery, types.GET_TEAM_USER_DETAILS_REQUEST, getTeamUserDetails);
  yield trackRequests(takeEvery, types.GET_TEAM_USER_RECRUITS_REQUEST, getTeamUserRecruits);
  yield trackRequests(takeEvery, types.INVITE_NEW_TEAM_LEAD_REQUEST, inviteNewTeamLead);
  yield trackRequests(takeEvery, types.ARCHIVE_TEAM_LEAD_REQUEST, archiveTeamLead);
  yield trackRequests(takeEvery, types.UNARCHIVE_TEAM_LEAD_REQUEST, unarchiveTeamLead);
  yield trackRequests(takeEvery, types.GET_INVITE_DETAILS, getInviteDetails);
  yield trackRequests(takeEvery, types.UPDATE_PENDING_INVITE, updatePendingInvite);
  yield trackRequests(takeEvery, types.DELETE_INVITE, deleteInvite);
  // INSERTION_PT (for script, do not remove)
}

export default watchTeamSagas;

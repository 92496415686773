import types from "rdx/modules/projectPreSales/types";
import createAction from "rdx/utils/createAction";

export default {
  getProjectsPreSales: (payload) => createAction(types.GET_PROJECTS_PRE_SALES, payload),
  getSelectedPreSalesProject: (payload) => createAction(types.GET_SELECTED_PRE_SALES_PROJECT, payload),
  setProjectsPreSales: (payload) => createAction(types.SET_PROJECTS_PRE_SALES, payload),
  setSelectedPreSalesProject: (payload) => createAction(types.SET_SELECTED_PRE_SALES_PROJECT, payload),
  resetProjectsPreSales: () => createAction(types.RESET_PROJECTS_PRE_SALES),
  // INSERTION_PT (for script, do not remove)
};

import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useTranslation } from "react-i18next";
import { Form, Tabs, Button } from "antd";
import { isEmpty } from "lodash";
import useLoading from "hooks/useLoading";
import types from "@rdxmodules/enterpriseProLaborForm/types";
import EnterpriseApplication from "models/EnterpriseApplication/EnterpriseApplication";
import User from "models/User";
import MessageEvent from "models/MessageEvent";
import enterpriseMessages from "rdx/modules/enterpriseApplications/messages";
import GradientButton from "components/Buttons/GradientButton";
import PersonalForm from "components/HomepageComponents/EnterpriseProSignupModal/PersonalForm";
import BusinessForm from "components/HomepageComponents/EnterpriseProSignupModal/BusinessForm";
import OfficersForm from "components/HomepageComponents/EnterpriseProSignupModal/OfficersForm";
import SECTIONS from "./sections";

import styles from "./EnterpriseApplicationForm.module.less";

const { TabPane } = Tabs;

const KEYS = Object.values(SECTIONS).map((s) => s.key);

const EnterpriseApplicationForm = ({ currentUser, latestMessage, setShowEnterpriseApp, setAlertMessageVisible }) => {
  const { t } = useTranslation(["ent_join_form_labels"]);
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const [preFillForm, setPreFillForm] = useState(false);

  const formValues = useRef({});
  const [personalForm] = Form.useForm();
  const [businessForm] = Form.useForm();
  const [officersForm] = Form.useForm();
  const FORMS = [personalForm, businessForm, officersForm];

  const history = useHistory();

  const isSubmitting = useLoading({
    watchRequests: [EnterpriseApplication.actionTypes.create, types.GET_ENTERPRISE_PRO_LABOR_MODAL_FORM_SUBMIT],
  });

  const currentForm = FORMS[activeTabIdx]; // eslint-disable-line no-unused-vars
  const currentSection = SECTIONS[KEYS[activeTabIdx]];

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      setPreFillForm(true);
      personalForm.setFieldsValue({
        ownerFirstName: currentUser.firstName,
        ownerLastName: currentUser.lastName,
        ownerEmail: currentUser.email,
        ownerPhone: currentUser.phone,
        locale: currentUser.locale,
      });
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleActiveButtonClick = () => {
    if (currentSection.advance) {
      currentForm
        .validateFields()
        .then((values) => {
          formValues.current = {
            ...formValues.current,
            ...values,
          };
          setActiveTabIdx(activeTabIdx + 1);
        })
        .catch((e) => console.warn(e));
    } else if (currentSection.submit) {
      currentForm
        .validateFields()
        .then((values) => {
          formValues.current = {
            ...formValues.current,
            ...values,
          };
          handleSubmit(formValues.current);
        })
        .catch((e) => console.warn(e));
    } else if (currentSection.close) {
      history.push("/dashboard");
    }
  };

  const handleSubmit = (values) => {
    EnterpriseApplication.create(values);
  };

  useEffect(() => {
    if (latestMessage.message === enterpriseMessages.CREATE_ENTERPRISE_APPLICATIONS_SUCCESS) {
      setActiveTabIdx(activeTabIdx + 1);
    } else if (latestMessage.message === enterpriseMessages.ERROR_CREATING_ENTERPRISE_APPLICATIONS) {
      let msg;
      if (latestMessage.message) {
        if (latestMessage.error.status >= 500) {
          msg = `${latestMessage.message}: something went wrong.`;
        } else {
          msg = `${latestMessage.message}: ${latestMessage.error.message || "something is wrong with your request."}`;
        }
      } else {
        msg = "Error: something went wrong.";
      }
      setAlertMessageVisible({
        message: msg,
        severity: "error",
      });
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => {
    if (currentSection.cancel) {
      setShowEnterpriseApp(false);
    } else {
      setActiveTabIdx(activeTabIdx - 1);
    }
  };

  return (
    <div className={styles.enterpriseApplicationContainer}>
      <div className={styles.enterpriseApplication}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>{t(currentSection?.title)}</div>
          <div className={styles.headerDetails}>{t(currentSection?.subtitle)}</div>
        </div>
        <div className={styles.enterpriseApplicationContent}>
          <Tabs
            className={styles.applicationBody}
            activeKey={KEYS[activeTabIdx]}
            defaultActiveKey={KEYS[0]}
            renderTabBar={() => null}
            destroyInactiveTabPane
          >
            <TabPane key={KEYS[0]} className={styles.tab} tab={<></>}>
              <PersonalForm form={personalForm} preFillForm={preFillForm} />
            </TabPane>
            <TabPane key={KEYS[1]} className={styles.tab} tab={<></>}>
              <BusinessForm form={businessForm} />
            </TabPane>
            <TabPane key={KEYS[2]} className={styles.tab} tab={<></>}>
              <OfficersForm form={officersForm} formValues={formValues} hideLaborPartner />
            </TabPane>
            <TabPane key={KEYS[3]} className={styles.tab} tab={<></>}>
              <div className={styles.confirmationText}>
                <div className={styles.confirmEmail}>Your application has been sent.</div>
                <div className={styles.confirmEmail}>
                  We will reach out to you at <span className={styles.boldText}>{formValues.current.ownerEmail}</span>{" "}
                  for next steps.
                </div>
              </div>
            </TabPane>
          </Tabs>
          <div className={styles.buttonContainer}>
            <GradientButton
              onClick={handleActiveButtonClick}
              className={styles.nextStepButton}
              loading={!!isSubmitting}
            >
              {t(currentSection?.buttonTxt)}
            </GradientButton>
            {activeTabIdx < 3 && (
              <Button onClick={handleCancel} className={styles.cancelButton} type="link">
                {activeTabIdx === 0 ? t("cancel") : t("back")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EnterpriseApplicationForm.propTypes = {
  currentUser: User.types(),
  latestMessage: MessageEvent.types(),
  setShowEnterpriseApp: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    latestMessage: "getLatestMessageEvt",
    requests: "getActiveRequests",
    currentUser: "getCurrentUser",
  }),
  getRdxActionMapper(["clearLatestMessage", "setAlertMessageVisible"]),
)(EnterpriseApplicationForm);

import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  usersList: {
    users: [],
    filters: {},
    paging: {
      item_count: 0,
      page_number: 1,
      page_size: 50,
    },
  },
  rolesList: {
    roles: [],
    permissions: [],
    entities: [],
    operations: [],
  },
  userDetails: {
    managerOptions: [],
    id: null,
  },
  mentorRatings: {
    paging: {
      item_count: 0,
      page_number: 1,
      page_size: 50,
    },
    list: [],
  },
  autoCompleteSuggestions: [],
  mentorLookupSuggestions: [],
  teamsList: [],
  userName: {},
  litmosLoginLink: null,
  marketingToken: null,
  unsavedMentorRatings: false,
};

export default {
  users: createReducer(initialState, {
    [types.SET_USERS_LIST](state, action) {
      return {
        ...state,
        usersList: {
          ...state.usersList,
          ...action.payload,
        },
      };
    },
    [types.SET_DETAIL_IN_USERS_LIST](state, action) {
      return {
        ...state,
        usersList: {
          ...state.usersList,
          users: state.usersList.users.map((user) =>
            user.id === action.payload.id ? { ...user, enrollment: action.payload.enrollment } : user,
          ),
        },
      };
    },
    [types.SET_USER_ROLES_LIST](state, action) {
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          ...action.payload,
        },
      };
    },
    [types.SET_USER_DETAILS](state, action) {
      return {
        ...state,
        userDetails: action.payload,
      };
    },
    [types.SET_MENTOR_RATINGS](state, action) {
      return {
        ...state,
        mentorRatings: action.payload,
      };
    },
    [types.SET_USER_NAME](state, action) {
      return {
        ...state,
        userName: action.payload,
      };
    },
    [types.SET_DETAIL_IN_USER_DETAILS](state, action) {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    },
    [types.SET_USERS_AUTOCOMPLETE](state, action) {
      return {
        ...state,
        autoCompleteSuggestions: action.payload.users,
      };
    },
    [types.SET_TEAMS_LIST](state, action) {
      return {
        ...state,
        teamsList: action.payload,
      };
    },
    [types.SET_LITMOS_LOGIN_LINK](state, action) {
      return {
        ...state,
        litmosLoginLink: action.payload,
      };
    },
    [types.READD_USER_TO_LMS](state) {
      // passthrough
      return state;
    },
    [types.CANCEL_SUBSCRIPTION_BY_ADMIN](state, action) {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    },
    [types.RENEW_SUBSCRIPTION_BY_ADMIN](state, action) {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    },
    [types.DESTROY_SUBSCRIPTION_BY_ADMIN](state, action) {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    },
    [types.SET_MENTOR_USER_LOOKUPS](state, action) {
      return {
        ...state,
        mentorLookupSuggestions: action.payload.suggestions,
      };
    },
    [types.RESET_MENTOR_USER_LOOKUPS](state) {
      return {
        ...state,
        mentorLookupSuggestions: [],
      };
    },
    [types.SET_UNSAVED_MENTOR_RATINGS](state, action) {
      return {
        ...state,
        unsavedMentorRatings: action.payload.unsaved,
      };
    },
    [types.SET_SELLER_APPLICATION_AGREEMENT](state, action) {
      return {
        ...state,
        applicationAgreement: action.payload,
      };
    },
    [types.SET_MARKETING_TOKEN](state, action) {
      return {
        ...state,
        marketingToken: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};

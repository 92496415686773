import React, { memo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "rdx";

interface Props {
  authUri: string | URL | undefined;
  children?: React.ReactNode;
}

const LoginSocialGoogle = ({ children, authUri }: Props) => {
  const dispatch = useDispatch();
  const loginGoogle = useCallback(() => {
    dispatch(actions.oauth());
  }, [dispatch]);

  useEffect(() => {
    if (!authUri) return;
    window.open(authUri, "_self");
  }, [authUri]);

  return (
    <div role="button" tabIndex={0} onKeyDown={loginGoogle} onClick={loginGoogle}>
      {children}
    </div>
  );
};

export default memo(LoginSocialGoogle);

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Form } from "antd";
import { actions } from "rdx";
import User from "models/User";
import messageTypes from "rdx/modules/enrollment/messages";
import { SettingsContext } from "context/SettingsContext";
import SessionClient from "util/SessionClient";
import AddressForm from "./AddressForm";
import StripeForm from "./StripeForm";

import styles from "./PaymentInfo.module.less";

// const stripePromise = loadStripe(new SettingsClient().data.stripe_api_key);

const PaymentInfo = ({ navigate, selectedService, manualKindSelected, currentUser, setupFee }) => {
  const { t } = useTranslation(["account_types"]);
  const [addressForm] = Form.useForm();
  const session = new SessionClient();
  const history = useHistory();
  const dispatch = useDispatch();

  const values = useContext(SettingsContext);

  if (!values.stripe_api_key) {
    console.error("Stripe API key not found");
    return null;
  }

  const stripePromise = loadStripe(values.stripe_api_key);

  const onStripeSubmitComplete = (msg) => {
    if (msg === messageTypes.ENROLLMENT_SUCCESS) {
      dispatch(
        actions.setAlertMessageVisible({
          message: msg,
          severity: "success",
        }),
      );

      session.user.reload();
      history.push("/dashboard");
    } else {
      dispatch(
        actions.setAlertMessageVisible({
          message: msg,
          severity: "error",
        }),
      );
    }
  };

  if (!selectedService) return null;
  return (
    <div className={styles.cardWithBackground}>
      <div className={styles.paymentInfoContainer}>
        <div className={styles.paymentHeader}>Payment</div>
        <div className={styles.accountTypeContainer}>
          {manualKindSelected && <div className={styles.selected}>{t("pricing_and_plans.payment.selected")}</div>}
          <div className={styles.accountTypeHeader} style={{ color: selectedService.color }}>
            {selectedService.type}
          </div>
          <div className={styles.accountTypeDetails}>
            <div className={styles.description}>{t(`pricing_and_plans.payment.${selectedService.slug}`)}</div>
            <div className={styles.price}>
              <div className={styles.subscription}>
                <span className={styles.cost}>${selectedService.monthly}</span>
                <span className={styles.frequency}>/mo</span>
              </div>
              {!!setupFee && (
                <div className={styles.setupFee}>
                  <span>${setupFee} setup fee</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.backToAccountTypes}>
          {t("pricing_and_plans.payment.other_plans")}
          <span
            className={styles.accountTypesLink}
            tabIndex={0}
            role="button"
            onClick={() => navigate({ tab: "account-types", modal: "none" })}
            onKeyDown={() => navigate({ tab: "account-types", modal: "none" })}
          >
            {t("pricing_and_plans.payment.other_plans_link")}
          </span>
          .
        </div>
        <AddressForm form={addressForm} currentUser={currentUser} />
        <Elements stripe={stripePromise}>
          <StripeForm
            service={selectedService}
            addressForm={addressForm}
            session={session}
            onStripeSubmitComplete={onStripeSubmitComplete}
          />
        </Elements>
      </div>
    </div>
  );
};

PaymentInfo.propTypes = {
  navigate: PropTypes.func,
  selectedService: PropTypes.shape({
    fee: PropTypes.number,
    id: PropTypes.number,
    monthly: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
  }),
  manualKindSelected: PropTypes.bool,
  currentUser: User.types(),
  setupFee: PropTypes.number,
};

export default PaymentInfo;

import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* requestVisionProposal(action) {
  const { id, requestType, isKanban, proposalNotes, cloneId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/request_vision_proposal`, {
    request_type: requestType || "over-night",
    proposal_notes: proposalNotes,
    clone_id: cloneId,
  });

  if (success && data && !data.body?.vision_proposal?.error) {
    const enterpriseProjects = yield select(selectors.getEnterpriseProjectsList);
    const responseData = camelizeKeys(data.body);
    // const projects = enterpriseProjects.projects.filter((p) => p.id !== id);
    const projects = enterpriseProjects.projects.map((project) => ({
      ...project,
      proposalVisionSlug: project.id === responseData.id ? responseData.visionProposalSlug : null,
    }));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.REQUEST_PROPOSAL_SUCCESS,
          payload: responseData,
        }),
      ),
      put(actions.setProjectDetails({ providerLead: responseData })),
      put(actions.setEnterpriseProjectsList({ ...enterpriseProjects, projects })),
      put(actions.getProjectsPreSales()),
      put(
        actions.setAlertMessageVisible({
          message: messages.REQUEST_PROPOSAL_SUCCESS,
          severity: "success",
        }),
      ),
      ...(isKanban ? [put(actions.setProviderLeadStageInKanban({ providerLead: responseData }))] : []),
    ]);
  } else {
    if (requestType === "self-request") put(actions.setCreateVisionReqSuccessful(false));
    return getErrorActions({
      error: error || data.body?.vision_proposal?.error,
      message: messages.ERROR_REQUESTING_PROPOSAL,
    });
  }
  return null;
}

export default requestVisionProposal;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Input, Form } from "antd";

import styles from "./TextAreaInput.module.less";

const { Item } = Form;
const { TextArea } = Input;

const TextAreaInput = (props) => {
  const {
    label,
    placeholder,
    labelClass,
    inputClass,
    inputPrefix,
    onInputChange,
    value,
    itemName,
    rules,
    validateTrigger,
    disabled,
    autoSize,
    maxLength,
    cleared,
    setClear,
  } = props;

  const [characterCount, setCharacterCount] = useState(0);

  const handleChange = (e) => {
    if (onInputChange) {
      onInputChange(e);
    }
    setCharacterCount(e.target.value.length);
  };

  useEffect(() => {
    setCharacterCount(value ? value.length : 0);
    setClear(false);
  }, [cleared]);

  return (
    <div className={styles.textAreaInputContainer}>
      {(label || maxLength) && (
        <div className={styles.labelRow}>
          {label ? <div className={labelClass || styles.label}>{label}</div> : <div className={styles.label} />}
          {maxLength && (
            <div className={labelClass || styles.label}>
              {characterCount}/{maxLength}
            </div>
          )}
        </div>
      )}
      <Item className={styles.row} name={itemName} rules={rules} validateTrigger={validateTrigger}>
        <TextArea
          autoSize={autoSize}
          className={inputClass || styles.input}
          onChange={handleChange}
          placeholder={placeholder}
          prefix={<span className={styles.prefix}>{inputPrefix}</span>}
          maxLength={maxLength}
          value={value}
          disabled={disabled}
        />
      </Item>
    </div>
  );
};

TextAreaInput.defaultProps = {
  oninputChange: () => null,
  validateTrigger: "onSubmit",
  setClear: () => null,
};

TextAreaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  onInputChange: PropTypes.func,
  inputPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  itemName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  // form validation rules
  rules: PropTypes.arrayOf(PropTypes.object),
  validateTrigger: PropTypes.string,
  disabled: PropTypes.bool,
  textArea: PropTypes.bool,
  autoSize: PropTypes.objectOf(PropTypes.number),
  maxLength: PropTypes.number,
};

export default TextAreaInput;

import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* requestAdminProposal(action) {
  const { id, realTime } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/projects/${id}/request_proposal`, {
    real_time: realTime,
    id,
  });

  if (success && data) {
    yield all([
      put(actions.setAdminProjectDetails(keysToCamelCase(data.body))),
      put(actions.newSuccessEvent({ message: messages.REQUEST_ADMIN_PROPOSAL_SUCCESS })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REQUESTING_ADMIN_PROPOSAL,
    });
  }
  return null;
}

export default requestAdminProposal;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, AutoComplete } from "antd";

import styles from "./PaymentInfo.module.less";

const { Option } = AutoComplete;

const StreetAddressLookup = (props) => {
  const { form } = props;
  const [suggestions, setSuggestions] = useState([]);

  const fetchAddressSuggestions = async (value) => {
    const testJson = (input) => {
      try {
        const json = JSON.parse(input);
        if (typeof json === "object") {
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    };

    if (value.length <= 3 || testJson(value)) {
      setSuggestions([]);
      return null;
    }

    const { city, state } = form.getFieldsValue(["city", "state"]);

    const requestUrl = new URL("https://us-autocomplete-pro.api.smartystreets.com/lookup");
    requestUrl.searchParams.append("auth-id", process.env.REACT_APP_SMARTYSTREETS_AUTH_ID);
    requestUrl.searchParams.append("search", value);
    if (city) {
      requestUrl.searchParams.append("city_filter", city);
    }
    if (state) {
      requestUrl.searchParams.append("state_filter", state);
    }

    try {
      const response = await fetch(requestUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();

      return setSuggestions(
        data.suggestions.map((s, idx) => (
          <Option
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            value={`${s.street_line} ${s.city}, ${s.state} ${s.zipcode}`}
            data={s}
          >
            {s.text}
          </Option>
        )),
      );
    } catch (error) {
      return setSuggestions([]);
    }
  };

  const handleAddressSelect = (value, option) => {
    form.setFieldsValue({
      street: option.data.street_line,
      city: option.data.city,
      state: option.data.state,
      zip: option.data.zipcode,
    });
  };

  return (
    <Form.Item
      name="street"
      rules={[
        {
          required: true,
          message: "Street Address Required",
        },
      ]}
    >
      <AutoComplete
        className={styles.select}
        onSearch={fetchAddressSuggestions}
        onSelect={handleAddressSelect}
        size="large"
      >
        {suggestions}
      </AutoComplete>
    </Form.Item>
  );
};

StreetAddressLookup.propTypes = {
  // antd form instance
  form: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default StreetAddressLookup;

import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import _ from "lodash";

const paging = {
  pageSize: 50,
  pageNumber: 1,
  itemCount: 0,
};

const initialState = {
  usersList: {
    paging,
    users: [],
  },
  filters: {},
  services: {},
  userDetails: {},
  seatDetails: {},
  enterpriseSeatChangePreview: {},
  pendingSeat: {
    orgName: "",
    sellerPercentage: 0,
  },
};

export default {
  enterprisePersonnel: createReducer(initialState, {
    [types.SET_ENTERPRISE_PERSONNEL_LIST](state, action) {
      return {
        ...state,
        usersList: {
          ...state.usersList,
          ...action.payload,
        },
      };
    },

    [types.SET_ENTERPRISE_PERSONNEL_LEVEL_ONES](state, action) {
      const {
        payload: {
          userId,
          users: {
            0: { sponsors, level },
          },
        },
      } = action || {};

      const levelOneUserId = sponsors[sponsors.length - level];
      const levelOneUserIdx = state.usersList.users.findIndex((user) => user.id === levelOneUserId);
      const levelOneUserIdxL2 = state.usersList.users.findIndex((user) => user.id === userId); // WIP
      const levelOneUser = state.usersList.users[levelOneUserIdx];
      const levelTwoUser = state.usersList.users[levelOneUserIdxL2]; // WIP
      const updatedUserList = [...state.usersList.users];

      // WIP
      if (levelOneUserIdx === -1) {
        updatedUserList[levelOneUserIdxL2] = _.cloneDeepWith(levelTwoUser, (value) => {
          return value?.id === userId ? { ...value, members: action.payload.users } : undefined;
        });
      } else {
        updatedUserList[levelOneUserIdx] = _.cloneDeepWith(levelOneUser, (value) => {
          return value?.id === userId ? { ...value, members: action.payload.users } : undefined;
        });
      }
      // WIP

      return {
        ...state,
        usersList: {
          ...state.usersList,
          users: updatedUserList,
        },
      };
    },

    [types.SET_LIST_OF_ASSIGNABLE_SERVICES](state, action) {
      return {
        ...state,
        services: action.payload,
      };
    },

    [types.SET_ENTERPRISE_PERSONNEL_FILTERS](state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },

    [types.SET_ENTERPRISE_USER_DETAILS](state, action) {
      return {
        ...state,
        userDetails: action.payload,
      };
    },

    [types.SET_PENDING_SEAT](state, action) {
      return {
        ...state,
        pendingSeat: action.payload,
      };
    },

    [types.RESET_ENTERPRISE_USER_DETAILS](state) {
      return {
        ...state,
        userDetails: initialState.userDetails,
        seatDetails: initialState.seatDetails,
      };
    },

    [types.SET_ENTERPRISE_SERVICES_SEAT_CHANGE_PREVIEW](state, action) {
      return {
        ...state,
        enterpriseSeatChangePreview: action.payload,
      };
    },

    [types.SET_ENTERPRISE_SEAT_DETAILS](state, action) {
      return {
        ...state,
        seatDetails: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};

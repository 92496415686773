import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "@rdxmodules/enterprisePersonnel/messages";
import { camelizeKeys, keysToSnakeCase } from "@helpers";

function* getEnterprisePersonnelLevelOnes(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/enterprise/level_ones", keysToSnakeCase(params));
  if (success && data) {
    if (data.body.users) {
      yield put(actions.setEnterprisePersonnelLevelOnes({ ...camelizeKeys(data.body), ...params }));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_USERS_LEVEL_ONES,
    });
  }
  return null;
}

export default getEnterprisePersonnelLevelOnes;

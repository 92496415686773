import { useLocation } from "react-router-dom";

enum Paths {
  ADMIN = "/admin-",
  ORG = "/org-",
  PROJECTS = "/projects",
}

type RouteMatchResult = {
  isAdmin: boolean;
  isProjects: boolean;
  isEnterprise: boolean;
};

export default function useRouteMatcher(): RouteMatchResult {
  const location = useLocation();
  const currentPath = location.pathname;

  return {
    isAdmin: currentPath.includes(Paths.ADMIN),
    isEnterprise: currentPath.includes(Paths.ORG),
    isProjects: currentPath.includes(Paths.PROJECTS),
  };
}

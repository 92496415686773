import { put } from "redux-saga/effects";

// import makeRequest from 'rdx/utils/makeRequest';
// import getErrorActions from 'rdx/utils/getErrorActions';
import actions from "rdx/actions";
import { stringify } from "query-string";

function* getProjectGeocode(action) {
  const { address } = action.payload;
  const url = new URL("https://maps.googleapis.com/maps/api/geocode/json");
  const params = {
    key: process.env.REACT_APP_GOOGLE_API_KEY,
    address: `${address.street}, ${address.city}, ${address.state}`,
    components: `country:${address.country || "US"}|postal_code:${address.zip}`,
  };
  url.search = stringify(params);

  try {
    const response = yield fetch(url).then((res) => res.json());
    if (response.status === "OK") {
      const { lat, lng } = response.results[0].geometry.location;
      yield put(actions.setProjectGeocode({ lat, lng }));
    } else {
      yield put(actions.newMessageEvent({ message: "Geocode not found" }));
    }
  } catch (error) {
    yield put(actions.newErrorEvent({ error }));
  }

  return null;
}

export default getProjectGeocode;

import styled from "@emotion/styled";
import { theme } from "styles/themes";
import Button from "@mui/material/Button";
import { PrimaryButtonT } from "./Primary";

export const PrimaryButton = styled(Button, {
  shouldForwardProp: (prop) =>
    !["disabled", "gradient", "reverseGradient", "buttonStyle", "white", "transparent", "gradientOutlined"].includes(
      prop,
    ),
})<PrimaryButtonT>(
  ({
    variant,
    disabled,
    gradient,
    reverseGradient,
    gradientOutlined,
    width,
    canceled,
    white,
    buttonStyle,
    height,
    startColor,
    stopColor,
  }) => ({
    boxShadow: "0px 0.5px 2px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
    height: "32px",
    minWidth: "142px",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "17px",
    textTransform: "capitalize",
    fontFamily: "Barlow",
    overflow: "hidden",
    ...(variant === "text" && {
      minWidth: "auto",
    }),
    ...(variant === "contained" && {
      background: theme.colors["blue-ribbon"],
      border: "none",
      color: theme.colors.white,
    }),
    ...(variant === "outlined" && {
      background: theme.colors.white,
      border: `1px solid ${theme.colors["blue-ribbon"]}`,
      color: theme.colors["blue-ribbon"],
    }),
    ...(gradientOutlined && {
      background: `linear-gradient(white, white) padding-box, linear-gradient(95.7deg, ${
        startColor || theme.colors["picton-blue"]
      } 12.17%, ${stopColor || theme.colors["blue-ribbon"]} 93.24%) border-box`,
      border: "2px solid transparent",
      borderWidth: "2px",

      "& .buttonText": {
        background: `linear-gradient(95.7deg, ${theme.colors["picton-blue"]} 12.17%, ${theme.colors["blue-ribbon"]} 93.24%)`,
        color: "transparent",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
      },
    }),
    ...(gradient && {
      background: `linear-gradient(95.7deg, ${startColor || theme.colors["picton-blue"]} 12.17%, ${
        stopColor || theme.colors["blue-ribbon"]
      } 93.24%)`,
    }),
    ...(disabled && {
      "&.Mui-disabled": {
        background: theme.colors["dark-periwinkle"],
        color: theme.colors.white,
      },
    }),
    ...(reverseGradient && {
      background: `linear-gradient(270.54deg, ${theme.colors["picton-blue"]} 49.88%, ${theme.colors["blue-ribbon"]} 135.73%)`,
    }),
    ...(disabled && {
      cursor: "not-allowed",
      background: theme.colors["botticelli-blue"],
      color: theme.colors.white,
      "&:hover": {
        background: theme.colors["botticelli-blue"],
        boxShadow: "none",
      },
    }),
    ...(buttonStyle === "transparent" && {
      background: "transparent",
      border: `1px solid ${theme.colors.white}`,
      fontSize: "12px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        border: `1px solid ${theme.colors.white}`,
        background: theme.colors.white,
        "& span": {
          background: `-webkit-linear-gradient(270.54deg, ${theme.colors["picton-blue"]} 12.17%, ${theme.colors["blue-ribbon"]} 93.24%)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
      },
    }),
    ...(buttonStyle === "transparent" &&
      disabled && {
        border: "none",
        background: `${theme.colors["graham-grey"]} !important`,
        color: `${theme.colors["botticelli-blue"]} !important`,
      }),
    ...(white && {
      border: `1px solid ${theme.colors.white}`,
      background: `${theme.colors.white} !important`,
      boxShadow: "none",
      fontSize: "12px",
      "& span": {
        background: `-webkit-linear-gradient(352deg, ${theme.colors["picton-blue"]} 0%, ${theme.colors["blue-ribbon"]} 100%)`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
    }),
    ...(buttonStyle === "transparent" && {
      background: "transparent",
      border: `1px solid ${theme.colors.white}`,
      fontSize: "12px",
      boxShadow: "none",

      "&:hover": {
        boxShadow: "none",
        border: `1px solid ${theme.colors.white}`,
        backgroundImage: `linear-gradient(95.7deg, ${theme.colors["picton-blue"]} 12.17%, ${theme.colors["blue-ribbon"]} 93.24%)`,
        "& span": {
          backgroundImage: `linear-gradient(95.7deg, ${theme.colors["picton-blue"]} 12.17%, ${theme.colors["blue-ribbon"]} 93.24%)`,
          backgroundClip: "text",
          color: "transparent",
        },
        "& .buttonText": {
          color: "white",
          zIndex: "20",
          background: "transparent",
        },
      },
    }),
    ...(buttonStyle === "transparent" &&
      disabled && {
        border: "none",
        background: `${theme.colors["graham-grey"]} !important`,
        color: `${theme.colors["botticelli-blue"]} !important`,
      }),
    ...(buttonStyle === "delete" && {
      border: "none",
      background: `${theme.colors["halt-red-orange"]}`,
      color: `${theme.colors.white}`,
      padding: "8px",
      "&:hover": {
        background: theme.colors["fire-red"],
      },
    }),
    ...(height && {
      height,
    }),
    ...(buttonStyle === "completeProfile" && {
      border: "none",
      background: "#295CA0",
      color: `${theme.colors.white}`,
      "&:hover": {
        background: "#124180",
      },
    }),
    ...(buttonStyle === "text" && {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "17px",
      textAlign: "center",
      color: theme.colors["dark-blue"],
      textTransform: "none",
      "&.MuiButtonBase-root:hover": {
        background: "transparent !important",
      },
    }),
    ...(width && {
      minWidth: width,
    }),
    ...(canceled && {
      "&.Mui-disabled": {
        background: theme.colors["halt-red-orange"],
        color: theme.colors.white,
        padding: "8px",
        "&:hover": {
          background: theme.colors["fire-red"],
        },
      },
    }),
  }),
);

export const ButtonContent = styled("span")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

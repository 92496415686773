export default {
  CRM_ACTIVITY_CREATE_SUCCESS: "CRM Activity created successfully.",
  CRM_ACTIVITY_UPDATE_SUCCESS: "CRM Activity details saved.",
  CRM_ACTIVITY_DELETE_SUCCESS: "You have successfully deleted this CRM Activity.",
  CRM_EMAIL_SENT: "Email sent!",
  CRM_REMINDER_SCHEDULED: "Reminder scheduled!",

  ERROR_GETTING_CRM_ACTIVITY: "There was an error getting CRM Activity details.",
  ERROR_CREATING_CRM_ACTIVITY: "There was an error creating CRM Activity.",
  ERROR_UPDATING_CRM_ACTIVITY: "There was an error updating CRM Activity.",
  ERROR_DELETING_CRM_ACTIVITY: "There was an error deleting CRM Activity.",
  ERROR_GETTING_USERS_CRM_ACTIVITIES: "There was an error getting users CRM Activities.",
} as const;

import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase, objArrayKeysToCamelCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getTeamList(action) {
  const params = {
    sort: "newest",
    ...action.payload,
  };

  if (params.rank) {
    params.rank = params.rank.slice(1);
  }
  if (params.level) {
    params.level = params.level.slice(1);
  }

  if (!params.status) params.status = "active";
  if (!params.activity) params.activity = "is_active";
  if (!params.level) params.level = "1";

  const { success, data, error } = yield* makeRequest.get("/users/team", params);

  if (success && data) {
    const out = _.clone(data.body);
    if (out.users) {
      out.users = data.body.users.map((user) => {
        const newUser = _.clone(user);
        newUser.metrics = {};
        objArrayKeysToCamelCase(user.metrics).forEach((obj) => {
          const newKey = Object.keys(obj)[0];
          const newValue = Object.values(obj)[0];
          newUser.metrics[newKey] = newValue;
        });
        return keysToCamelCase(newUser);
      });
      yield put(actions.setTeamList(camelizeKeys(out)));
    } else {
      const newOut = camelizeKeys(out);
      newOut.filters.states = out.filters.states;
      newOut.filters.statuses = out.filters.statuses;
      yield put(actions.setTeamList(newOut));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_TEAM_LIST,
    });
  }
  return null;
}

export default getTeamList;

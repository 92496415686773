import { put, all, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import dayjs from "dayjs";
import * as _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/tickets/selectors";
import messages from "rdx/modules/tickets/messages";
import { keysToCamelCase } from "lib/helpers";
import { projectBlockersApi } from "../../projectBlockers/endpoints";
import { PROJECT_BLOCKER_TAGS } from "../../projectBlockers/tags";

function* postProjectMessageReply(action) {
  const { body, parent_id, tagged_users, tagged_roles } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/messages/${parent_id}/reply`, {
    body,
    tagged_users,
    tagged_roles,
  });

  if (success && data) {
    const replies = { ...(yield select(selectors.getProjectMessageReplies)) };
    const reply = keysToCamelCase(data.body);
    const messageList = yield select(selectors.getProjectMessageList);
    const inboxMessageList = yield select(selectors.getInboxList);
    const message = yield select(selectors.getSelectedMessage);

    if (Array.isArray(replies[parent_id])) {
      replies[parent_id].unshift(reply);
    } else {
      replies[parent_id] = [reply];
    }

    const updatedMessage = _.clone(message);

    updatedMessage.childCount += 1;
    updatedMessage.updatedAt = dayjs().toString();
    if (!updatedMessage.responder) {
      updatedMessage.responder = reply.author;
      updatedMessage.respondedAt = dayjs().toString();
    }

    const updateList = (list) =>
      list
        .map((m) => {
          if (m.id === parent_id) {
            return {
              ...m,
              childCount: m.childCount + 1,
              updatedAt: updatedMessage.updatedAt,
              responder: updatedMessage.responder,
              resolvedAt: updatedMessage.resolvedAt,
              zendeskStatus: updatedMessage.zendeskStatus,
            };
          }
          return m;
        })
        .sort((m) => (m.id === parent_id ? -1 : 1));

    yield all([
      put(actions.setProjectMessageReplies({ replies })),
      put(actions.setSelectedMessageDetails(updatedMessage)),
      put(actions.setProjectMessageList({ list: updateList(messageList) })),
      put(actions.setInboxList({ list: updateList(inboxMessageList) })),
      put(projectBlockersApi.util.invalidateTags([PROJECT_BLOCKER_TAGS.PROJECT_BLOCKERS])),
      put(
        actions.newSuccessEvent({
          message: messages.POST_PROJECT_MESSAGE_REPLY_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERRPR_REPLYING_TO_MESSAGE,
    });
  }

  return null;
}

export default postProjectMessageReply;

import React, { useState, useEffect, useRef, useMemo, CSSProperties } from "react";
import types from "rdx/types";
import _ from "lodash";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import useLoading from "hooks/useLoading";
import useDebounce from "hooks/useDebounce";
import { UsersDisplay } from "./UsersDisplay";
import { UserCitiesDisplay } from "./UserCitiesDisplay";
import styles from "../FormElements.module.less";
import { MagnifyingGlassIcon } from "components/Icons";
import actions from "rdx/actions";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";

type AutoCompleteOptions = {
  value: string;
  key?: string;
  data?: any;
  disabled: boolean;
  label: string | React.ReactNode;
};

type AutoCompleteInputProps = {
  requestUrl: string;
  successActions: [() => void]; // array of redux actions to dispatch on success
  setSelected: (data: any) => void;
  handleReset?: () => void;
  suggestions?: any[];
  additionalPayload?: Record<string, unknown>;
  additionalReqParams?: Record<string, unknown>;
  placeholder?: string;
  partnerSearch?: boolean;
  formatData?: (data: any) => any;
  errorMessage?: string;
  debounceTimeout?: number;
  minCharacters?: number;
  preSearchSection?: {
    label: string;
    options: AutoCompleteOptions[];
  };
  classNames?: {
    container: string;
    autoComplete: string;
    label: string;
    input: string;
  };
  styleOverrides?: {
    input: CSSProperties;
  };
  autoFocus?: boolean;
  dropdownItemRender?: (item: any) => React.ReactNode; // function takes in entry from suggestions, returns a react node to render in dropdown
  usersDisplay?: boolean; // if the autocomplete is displaying a list of users, set this to true to use the default UsersDisplay component
  userCitiesDisplay?: boolean; // if the autocomplete is displaying a list of users, set this to true to use the default UsersDisplay component
  resetParams?: () => void;
  defaultUserName?: string;
  leadOwnerId?: number;
  disabled?: boolean;
  defaultValue?: string;
};

const AutoCompleteInput = ({
  requestUrl,
  successActions,
  setSelected: setSelectedStateInParent,
  handleReset = () => null,
  formatData = (data) => data,
  errorMessage,
  additionalReqParams = {},
  additionalPayload,
  debounceTimeout = 300,
  minCharacters = 3,
  suggestions = [],
  preSearchSection,
  classNames = {
    container: "",
    autoComplete: "",
    label: "",
    input: "",
  },
  styleOverrides = { input: {} },
  autoFocus = false,
  dropdownItemRender,
  defaultUserName,
  usersDisplay = false,
  userCitiesDisplay = false,
  resetParams = () => null,
  placeholder,
  leadOwnerId,
  disabled = false,
  defaultValue,
  partnerSearch = false,
}: AutoCompleteInputProps) => {
  const dispatch = useDispatch();
  const loading = useLoading({
    watchRequests: [types.FETCH_AUTOCOMPLETE_SUGGESTIONS],
  });

  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const debouncedSearch = useDebounce(search, debounceTimeout);
  const preventNextSearch = useRef(false);

  useEffect(() => {
    const lookup = (input) => {
      if (input?.length >= minCharacters && !preventNextSearch.current) {
        dispatch(
          actions.fetchAutoCompleteSuggestions({
            search: input,
            url: requestUrl,
            formatData,
            successActions,
            errorMessage,
            additionalReqParams,
            additionalPayload,
            leadOwnerId,
          }),
        );
      }
    };

    lookup(debouncedSearch);
    preventNextSearch.current = false;
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultUserName && defaultUserName.length > 0) {
      setSearch(defaultUserName);
    } else {
      setSearch("");
    }
  }, [defaultUserName]);

  useEffect(() => {
    return resetData;
  }, []);

  useEffect(() => {
    if (selectedItem) setSelectedStateInParent(selectedItem);
  }, [selectedItem]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = (input) => {
    setSearch(input);
  };

  const handleSelect = (_, option) => {
    preventNextSearch.current = true;
    const { fullName, name, id } = option.data;
    setSelectedItem(option.data);
    if (fullName !== undefined || name !== undefined) {
      setSearch(fullName || name);
    } else {
      setSearch(id);
    }
    handleBlur();
  };

  const reset = (e) => {
    if (e) e.stopPropagation();
    resetData();
    resetParams();
  };

  const resetData = () => {
    (document.activeElement as HTMLElement).blur();
    setSearch("");
    preventNextSearch.current = false;
    handleReset();
  };

  const itemRender = (item) => {
    if (dropdownItemRender) return dropdownItemRender(item);
    if (usersDisplay) return <UsersDisplay user={item} />;
    if (userCitiesDisplay) return <UserCitiesDisplay city={item} />;
    return <div>{item.displayValue}</div>;
  };

  const SectionLabel = ({ label }) => <Typography sx={{ color: "#0070F4", fontWeight: 600 }}>{label}</Typography>;

  const options = useMemo(() => {
    if (preSearchSection && !debouncedSearch && !loading) {
      return [
        {
          label: <SectionLabel label={preSearchSection.label} />,
          options: preSearchSection.options,
        },
      ];
    }

    if (!debouncedSearch || loading) {
      return [
        {
          value: "",
          key: "loading",
          disabled: true,
          label: <LoadingOutlined />,
        },
      ];
    }

    if (debouncedSearch && suggestions.length === 0 && search.length >= minCharacters) {
      return [
        {
          value: "",
          key: "no-matches",
          disabled: true,
          label: "(no matching results)",
        },
      ];
    }

    if (suggestions.length === 0) {
      return [];
    }

    return suggestions.map((o) => ({
      value: o.key.toString(),
      data: o.data,
      label: itemRender(o),
      disabled: o.disabled,
    }));
  }, [suggestions, minCharacters, search, dropdownItemRender, loading, debouncedSearch]);

  return (
    <div className={[styles.autoCompleteContainer, classNames.container].join(" ")}>
      <AutoComplete
        className={[styles.autoComplete, "FormElements-AutoComplete-Input", classNames.autoComplete].join(" ")}
        options={options}
        autoFocus={autoFocus}
        value={defaultValue || search}
        onSearch={handleInputChange}
        onSelect={handleSelect}
        onFocus={handleFocus}
        onBlur={handleBlur}
        open={isFocused && (search?.length >= minCharacters || !!preSearchSection)}
        disabled={disabled}
        dropdownMatchSelectWidth={partnerSearch ? 400 : false}
      >
        <Input
          className={[styles.input, classNames.input].join(" ")}
          style={styleOverrides.input}
          placeholder={placeholder}
          prefix={
            !defaultValue &&
            partnerSearch && (
              <span className={styles.prefix}>
                <MagnifyingGlassIcon />
              </span>
            )
          }
          suffix={
            search ? (
              <CloseOutlined className={styles.inputIcon} onClick={(e) => reset(e)} />
            ) : (
              <div className={styles.inputIcon} />
            )
          }
        />
      </AutoComplete>
    </div>
  );
};

export default AutoCompleteInput;

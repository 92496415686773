import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { actions, selectors } from "rdx";
import { camelizeKeys } from "lib/helpers";
import EnterpriseApplication from "models/EnterpriseApplication/EnterpriseApplication";
import { cvar } from "styles/colorVariables";
import EnterpriseApplicationForm from "../EnterpriseApplicationForm";
import PaymentInfo from "../PaymentInfo";
import SelectSponsor from "./SelectSponsor";
import SelectAccountType from "./SelectAccountType";

const AccountTypesTab = ({ noCurrentUser, navigate }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation(["account_types"]);

  const applicationDetails = useSelector(selectors.selectEnterpriseApplicationDetail);
  const services = useSelector(selectors.selectPlatformServices);
  const currentUser = useSelector(selectors.getCurrentUser);

  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [accountData, setAccountData] = useState([]);
  const [enterpriseAppStatus, setEnterpriseAppStatus] = useState(null);
  const [isSelectSponsorView, setIsSelectSponsorView] = useState(false);
  const [isSetupFeeGracePeriod, setIsSetupFeeGracePeriod] = useState(false);

  const [showEnterpriseApp, setShowEnterpriseApp] = useState(false);
  const [selectedAcctType, setSelectedAcctType] = useState({});
  const [manualKindSelected, setManualKindSelected] = useState(false);

  const userNeverSubscribed = currentUser.subscriptionStatus === "never_subscribed";
  const multiplePotentialSponsors = currentUser.potentialSponsors?.length > 1;
  const isAmbassador = currentUser.roles?.includes("ambassador");
  const mustSelectSponsor = multiplePotentialSponsors && userNeverSubscribed && !isAmbassador;

  useEffectOnce(() => {
    if (!noCurrentUser) {
      EnterpriseApplication.getByUser();
    }
    if (currentUser?.subscriptionStatus === "canceled") {
      const currentDay = dayjs();
      const endOfGracePeriod = dayjs(currentUser.enrollment.endDay).add(6, "month");
      setIsSetupFeeGracePeriod(endOfGracePeriod >= currentDay);
    }
    dispatch(actions.getPlatformServices());
  });

  useEffect(() => {
    if (applicationDetails?.id && !applicationDetails.response?.decision) {
      setEnterpriseAppStatus("pending");
    } else {
      setEnterpriseAppStatus(applicationDetails.response?.decision);
    }
  }, [applicationDetails, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (services) createAccountTypeInfo();
  }, [services, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentUser?.manualKindSelected || enterpriseAppStatus === "approved") {
      const selectedAccount = accountData.find((acct) => {
        if (currentUser.kind === "enterprise_pro" || enterpriseAppStatus === "approved") {
          return acct.slug === "powur_enterprise_pro";
        }
        if (currentUser.kind === "enterprise") {
          return acct.slug === "powur_enterprise";
        }
        return acct.slug === "powur_energy";
      });
      setSelectedAcctType(selectedAccount);
      navigate({ tab: "account-types", modal: "payment" });
      setManualKindSelected(currentUser.manualKindSelected);
    } else {
      const defaultAccount = accountData.find((acct) => {
        return acct.slug === "powur_energy";
      });
      setSelectedAcctType(defaultAccount);
    }
  }, [accountData, currentUser, enterpriseAppStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const planColors = {
    powur_enterprise: {
      color: cvar("coral"),
      secondaryColor: cvar("mellow-apricot"),
    },
    powur_enterprise_pro: {
      color: cvar("eggplant"),
      secondaryColor: cvar("heliotrope-magenta"),
    },
    powur_energy: {
      color: cvar("blue-ribbon"),
      secondaryColor: cvar("picton-blue"),
    },
  };

  const createAccountTypeInfo = () => {
    services.sort((a, b) => (a.monthly > b.monthly ? 1 : -1));
    const activeServices = services.filter((a) => a.slug !== "old_powur_energy");
    const accountTypeData = activeServices.map((service) => {
      return {
        id: service.id,
        key: service.id,
        slug: service.slug,
        fee: isSetupFeeGracePeriod ? null : service.fee,
        monthly: service.monthly,
        ...planColors[service.slug],
        ...camelizeKeys(
          t(`pricing_and_plans.tiers.${service.slug}`, {
            returnObjects: true,
          }),
        ),
      };
    });
    setAccountData(accountTypeData);
  };

  const handleRedirect = (acct) => {
    const sponsorName = params.user ? `/${params.user}` : "";

    const redirectUrl =
      acct.slug === "powur_enterprise_pro"
        ? `${sponsorName}/enterprise/signup-pro`
        : `${sponsorName}/signup?account-type=${acct.id}`;

    history.push(redirectUrl);
  };

  const handleShowPayment = (acctType) => {
    setSelectedAcctType(acctType);
    navigate({ tab: "account-types", modal: "payment" });
  };

  const handleShowSponsorSelect = (acctType) => {
    setSelectedAcctType(acctType);
    setIsSelectSponsorView(true);
  };

  const handleAccountTypeSelect = (acct) => {
    const isEnterpriseSelected = acct.slug === "powur_enterprise_pro";

    if (mustSelectSponsor) {
      handleShowSponsorSelect(acct);
    } else if (noCurrentUser) {
      handleRedirect(acct);
    } else if (isEnterpriseSelected && enterpriseAppStatus !== "approved") {
      setShowEnterpriseApp(true);
    } else {
      handleShowPayment(acct);
    }
  };

  const handleSponsorSelection = () => {
    setIsSelectSponsorView(false);
    const isEnterpriseSelected = selectedAcctType.slug === "powur_enterprise_pro";

    if (isEnterpriseSelected) {
      setShowEnterpriseApp(true);
    } else {
      navigate({ tab: "account-types", modal: "payment" });
    }
  };

  if (showEnterpriseApp) return <EnterpriseApplicationForm setShowEnterpriseApp={setShowEnterpriseApp} />;
  if (isSelectSponsorView) return <SelectSponsor onSponsorSelect={handleSponsorSelection} />;
  if (params.modal === "payment")
    return (
      <PaymentInfo
        navigate={navigate}
        selectedService={selectedAcctType}
        manualKindSelected={manualKindSelected}
        currentUser={currentUser}
        setupFee={isSetupFeeGracePeriod ? null : selectedAcctType?.fee}
      />
    );
  return (
    <SelectAccountType
      accountData={accountData}
      noCurrentUser={noCurrentUser}
      navigate={navigate}
      enterpriseAppStatus={enterpriseAppStatus}
      onAccountTypeSelect={handleAccountTypeSelect}
    />
  );
};

AccountTypesTab.propTypes = {
  noCurrentUser: PropTypes.bool,
  navigate: PropTypes.func,
};

AccountTypesTab.defaultProps = {
  noCurrentUser: false,
};

export default AccountTypesTab;

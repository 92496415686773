import React from "react";
import PropTypes from "prop-types";
// import styles from "./HeaderLogo.module.less";

const PowurLogoBlue = ({ height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 263 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_633_64239)">
        <path d="M8.47656 20.4587V8.47461H20.4606L8.47656 20.4587Z" fill="var(--curious-blue)" />
        <path d="M8.47458 37.4081L0 28.9336L8.47458 20.459V37.4081Z" fill="var(--curious-blue)" />
        <path d="M20.4609 8.47458L28.9355 0L37.4101 8.47458H20.4609Z" fill="var(--curious-blue)" />
        <path d="M37.4101 49.3984L28.9355 57.873L20.4609 49.3984H37.4101Z" fill="var(--curious-blue)" />
        <path d="M28.9297 40.9243V28.9336H40.9204L28.9297 40.9243Z" fill="var(--goldenrod)" />
        <path d="M28.9294 16.9492V28.9333H16.9453L28.9294 16.9492Z" fill="var(--goldenrod)" />
        <path d="M20.4606 49.3989H8.47656V37.4082L20.4606 49.3989Z" fill="var(--curious-blue)" />
        <path d="M49.3969 37.4082V49.3989H37.4062L49.3969 37.4082Z" fill="var(--curious-blue)" />
        <path d="M49.3984 20.459L57.873 28.9336L49.3984 37.4081V20.459Z" fill="var(--curious-blue)" />
        <path d="M37.4062 8.47461H49.3969V20.4587L37.4062 8.47461Z" fill="var(--curious-blue)" />
        <path
          d="M73.7891 79.9998V37.833H88.8439C98.9735 37.833 103.899 42.2996 103.899 51.4854V57.9194C103.899 65.2109 98.9735 68.7602 88.8439 68.7602H79.1663V80.0065H73.7891V79.9998ZM79.1663 63.9413H88.8505C95.2713 63.9413 98.5348 61.9141 98.5348 57.9128V48.6738C98.5348 44.6725 95.2779 42.6452 88.8505 42.6452H79.1663V63.9413Z"
          fill="var(--curious-blue)"
        />
        <path
          d="M123.477 68.7602C113.347 68.7602 108.422 65.2175 108.422 57.9194V48.6738C108.422 41.3757 113.347 37.833 123.477 37.833C133.606 37.833 138.532 41.3757 138.532 48.6738V57.9194C138.532 65.2109 133.606 68.7602 123.477 68.7602ZM123.477 42.6452C117.056 42.6452 113.792 44.6725 113.792 48.6738V57.9194C113.792 61.9207 117.049 63.948 123.477 63.948C129.897 63.948 133.161 61.9207 133.161 57.9194V48.6738C133.154 44.6725 129.897 42.6452 123.477 42.6452Z"
          fill="var(--curious-blue)"
        />
        <path
          d="M176.39 67.9493L166.825 41.8809L157.267 67.9493H151.638L139.594 37.833H144.911L154.456 60.9836L164.007 37.833H169.65L179.195 60.9836L188.746 37.833H194.064L182.02 67.9493H176.39Z"
          fill="var(--curious-blue)"
        />
        <path
          d="M211.719 68.7602C201.589 68.7602 196.664 65.2175 196.664 57.9194V37.833H202.041V57.9194C202.041 61.9207 205.298 63.948 211.726 63.948C218.146 63.948 221.41 61.9207 221.41 57.9194V37.833H226.787V57.9194C226.774 65.2109 221.855 68.7602 211.719 68.7602Z"
          fill="var(--curious-blue)"
        />
        <path
          d="M231.945 67.9493V37.833H247C256.944 37.833 261.869 41.2494 262.055 48.2684H256.671C256.452 44.5396 253.208 42.6452 247 42.6452H237.316V67.9493H231.945Z"
          fill="var(--curious-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_633_64239">
          <rect width="262.06" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PowurLogoBlue.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

PowurLogoBlue.defaultProps = {
  width: "263",
  height: "80",
};

export default PowurLogoBlue;

import { put, all, select } from "redux-saga/effects";
import * as _ from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { actions, selectors } from "rdx";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getProjectPreSalesList(action) {
  const { requestFreshData = true } = action.payload || {};
  const existingList = yield select(selectors.getProjectPreSales);

  if (requestFreshData) {
    yield put(actions.resetProjectsPreSales());
  }

  const params = {
    sort: "updated",
    ...action.payload,
    limit: 20,
  };

  if (!requestFreshData) {
    params.p = existingList.paging.pageNumber + 1 || existingList.paging.page_size + 1;
  }
  const { success, data, error } = yield* makeRequest.get("/projects_pre_sales", params);
  if (success && data) {
    if (data.body.projects) {
      const list = _.cloneDeep(existingList.projects).concat(data.body.projects.map((entry) => camelizeKeys(entry)));
      const paging = camelizeKeys(data.body.paging);
      const projects = requestFreshData ? data.body.projects.map((entry) => camelizeKeys(entry)) : list;
      yield put(actions.setProjectsPreSales({ ...data.body, paging, projects }));
    }
    if (data.body.filters) {
      const filters = { filters: data.body.filters, sorts: data.body.sorts };

      const eventCounts = Object.entries(data.body.event_counts).map((e) => ({
        code: _.camelCase(e[0]),
        eventCount: e[1],
      }));

      yield all([put(actions.setProviderLeadsFilters(filters)), put(actions.setLeadsEventCounts(eventCounts))]);
    }
  } else {
    return getErrorActions({
      error,
      message: messages.GET_PRE_SALES_PROJECTS_ERROR,
    });
  }
  return null;
}

export default getProjectPreSalesList;

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";

import { Layout, Typography, Row, Button } from "antd";
import { isValidElementType } from "react-is";

import useWindowResize from "hooks/useWindowResize";
import useGetCurrentRouteInfo from "hooks/useGetCurrentRouteInfo";
import Router from "models/Router";

import selectors from "rdx/selectors";

import { PowurLogoIcon, PowurLogoMobileIcon, PowurLogoXIcon } from "components/Icons";
import Banners from "components/PlatformLayout/components/Banners";
import InjectUserFlow from "components/Injectors/InjectUserFlow";
import PlatformModals from "../PlatformModals";
import Logout from "./UserLogout";

import styles from "./PlatformPageHeader.module.less";

const { Header } = Layout;
const { Title } = Typography;

const PlatformPageHeader = (props) => {
  const { mainNavCollapsed, toggleMenu, altLogout, altLogo } = props;

  const { platformRoutes: routes } = useGetCurrentRouteInfo();
  const { width } = useWindowResize();
  const history = useHistory();

  const showBackButton = history.location.state?.from === "/admin-reports/activity-log";

  const bannersHeight = useSelector(selectors.selectBannersHeight);

  const icon = () => {
    if (toggleMenu === null) {
      return <></>;
    }

    if (altLogo) return altLogo;
    return (
      <div
        data-test-id="PlatformPageHeader-logo"
        className={mainNavCollapsed ? styles.logoContainer : styles.logoContainerOpen}
        onMouseOver={toggleMenu}
        onFocus={toggleMenu}
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        tabIndex={0}
        role="button"
      >
        {width <= 500 ? (
          <>{mainNavCollapsed ? <PowurLogoMobileIcon /> : <PowurLogoXIcon />}</>
        ) : (
          <PowurLogoIcon className={styles.icon} />
        )}
      </div>
    );
  };

  return (
    <>
      <Banners />
      <Header
        className={styles.header}
        style={{
          top: `${bannersHeight}px`,
        }}
      >
        <Row className={styles.row} type="flex" justify="space-between" align="middle">
          <div className={styles.headerLeft}>
            {icon()}
            {showBackButton && (
              <Button className={styles.backButton} onClick={() => history.goBack()}>
                Back
              </Button>
            )}
            <Switch>
              {routes.map((r) => (
                <Route
                  {...r}
                  render={() => {
                    if (typeof r.title === "string") {
                      return (
                        <Title
                          level={4}
                          className={width > 340 ? styles.title : styles.hide}
                          style={{ fontWeight: 600 }}
                        >
                          {r.title}
                        </Title>
                      );
                    }

                    if (isValidElementType(r.title)) {
                      return <r.title />;
                    }

                    return null;
                  }}
                />
              ))}
            </Switch>
          </div>
          {altLogout || <Logout />}
          <PlatformModals />
        </Row>
        <InjectUserFlow />
      </Header>
    </>
  );
};

PlatformPageHeader.propTypes = {
  routes: PropTypes.arrayOf(Router.authRouteTypes()),
  mainNavCollapsed: PropTypes.bool,
  altLogout: PropTypes.node,
  altLogo: PropTypes.node,
  toggleMenu: PropTypes.func,
};

export default PlatformPageHeader;

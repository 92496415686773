// ALL PLATFORM COLORS SHOULD LIVE HERE
// CAN BE CALLED IN less files via "var(--NAMED_COLOR)"
// EG background-color: var(--botticelli-blue)

const blues = {
  "modal-overlay-blue": "rgba(11, 47, 95, 0.75)",
  "dark-blue": "rgba(41, 92, 160)" /* #295CA0 */,
  "dark-blue-a07": "rgba(41, 92, 160, .07)",
  "font-color-eastern-blue": "rgba(41, 92, 160, 1)" /* "#295CA0" */,
  "font-color-eastern-blue-highlighted": "rgb(80, 117, 165)" /* "#295CA0" */,
  "picton-blue": "rgba(32, 194, 241, 1)" /* , #20C2F1": */,
  "picton-blue-hover": "#009FCD",
  "picton-blue-a20": "rgba(32, 194, 241, 0.2)" /* , #20C2F1": */,
  "picton-blue-a10": "rgba(32, 194, 241, 0.1)" /* , #20C2F1": */,
  "picton-blue-highlighted": "rgb(73, 207, 244)" /*  #20C2F1 */,
  "blue-ribbon": "rgba(0, 112, 244, 1)" /* , #0070F4", */,
  "blue-ribbon-a05": "rgba(0, 112, 244, .05)" /* , #0070F4", */,
  "blue-ribbon-a15": "rgba(0, 112, 244, .15)" /* , #0070F4", */,
  "blue-ribbon-a07": "rgba(0, 112, 244, .07)" /* , #0070F4", */,
  "blue-ribbon-highlighted": "rgb(40, 136, 246)" /* , #0070F4", */,
  "banner-blue": "rgb(40, 136, 246)" /* , #0070F4", */,
  "dark-navy": "rgb(29, 74, 135)" /*  #1D4A87 */,
  "arrow-blue": "#124180",
  "cotton-boll-highlighted": "#C8DBF5",
  tangaroa: "#012343" /*  rgb(1, 35, 67) */,
  "darker-blue": "rgba(31, 50, 80, 1)" /* #1F3250 */,
  "darkest-blue": "rgb(0, 33, 96)" /* #002160 */,
  "darkest-blue-a10": "rgb(0, 33, 96, 0.1)" /* #002160 */,
  "royal-blue": "rgba(0, 112, 244, 1)",
  "background-blue": "#0980a0",
  "institutional-grey-hover": "#93A8C3",
  "institutional-grey": "#657A96",
  "gothic-blue": "rgba(116, 154, 169, 1)" /* #749AA9 */,
  "dark-periwinkle": "rgba(132, 153, 183, 1)" /* #8499B7 */,
  "nepal-blue": "rgba(132, 153, 183, 1)" /* #8499B7 */,
  "slate-turquoise": "rgba(156, 197, 212)" /* #9CC5D4 */,
  "botticelli-blue": "rgba(214, 225, 240, 1)" /* #D6E1F0 */,
  "botticelli-blue-50": "rgba(214, 225, 240, .5)" /* #D6E1F0 */,
  "botticelli-green-a25": "rgba(214, 225, 240, 0.25)" /* #D6E1F0 */,
  "primary-color": "rgba(91, 170, 227, 1)" /* #5BAAE3 */,
  "primary-color-highlighted": "rgb(123, 182, 223)" /* #5BAAE3 */,
  "primary-color-a10": "rgba(91, 170, 227, 0.1)" /* #5BAAE3 */,
  "primary-color-a15": "rgba(91, 170, 227, 0.15)" /* #5BAAE3 */,
  "primary-color-a30": "rgba(91, 170, 227, 0.3)" /* #5BAAE3 */,
  "primary-lite": "rgb(0, 161, 207)" /*  #00A1CF */,
  "primary-lite-highlighted": "rgb(44, 184, 223)" /*  #00A1CF */,
  "light-cyan": "#a4e9fd",
  "queen-blue": "rgba(81, 102, 131, 1)" /* #516683 */,
  "clear-sky-lite": "#f2fdff",
  "table-badge-people": "#dfecf1" /*  rgba(223, 236, 241, 1) */,
  "table-badge-team": "rgba(104, 1, 130, 1)" /* #680182 */,
  "grey-blue": "rgb(77, 119, 136)" /*  #4d7788 */,
  "clear-sky": "rgb(199, 230, 241)" /*  #c7e6f1 */,
  "light-navy": "rgb(17, 132, 173)" /*  #1184AD */,
  "light-baby-blue": "rgb(237, 243, 245)" /*  #F5EDF3 */,
  "prussian-blue": "rgb(0, 33, 96)" /*  #002160 */,
  "slashee-blueberry": "rgb(77, 205, 244)" /* #4dcdf4 */,
  "button-blue": "rgba(33, 166, 242, 1)", // #21A6F2,
  "royal-blue-dark": "rgba(0, 33, 96, 1)" /* #002160 */,
  "madison-blue": "rgba(11, 47, 95, 1)" /* #0B2F5F */,
  "rock-blue": "rgba(156, 175, 201, 1)" /* #9CAFC9 */,
  "pewter-blue": "rgba(141, 177, 191, 1)" /* #8DB1BF */,
  "malibu-blue": "rgba(95, 204, 242, 1)" /* #5FCCF2 */,
  "governor-bay-blue": "rgba(41, 44, 160, 1)" /* #292CA0 */,
  "bright-turquoise": "rgba(33, 194, 242, 1)" /* #21C2F2 */,
  "royal-peacock-blue": "rgba(41, 170, 227, 1)" /* #29AAE3 */,
  "astronaut-blue": "rgba(42, 52, 118, 1)" /* #2A3476 */,
  "adamantine-blue": "rgba(65, 177, 245, 1)" /* #41B1F5 */,
  "lavender-blue": "rgba(191, 219, 252, 1)" /* #BFDBFC */,
  "bahama-blue": "rgba(1, 97, 146, 1)" /* #016192 */,
  "aqua-haze": "rgba(237, 243, 245, 1)" /* #EDF3F5 */,
  frostproof: "rgba(211, 240, 247, 1)" /* #D3F0F7 */,
  "lake-thun": "rgba(75, 188, 222, 1)" /* #4BBCDE */,
  "adriatic-sea": "rgba(1, 97, 130, 1)" /* #016182 */,
  "dodger-blue": "rgba(24, 144, 255, 1)" /* #1890FF */,
  bluejay: "rgba(22, 138, 177, 1)" /* #168AB1 */,
  "cameo-blue": "rgba(110, 148, 163, 1)" /* #6E94A3 */,
  "steel-blue": "rgba(70, 136, 161, 1)" /* #4688A1 */,
  "chambray-blue": "rgba(52, 77, 147, 1)" /* #344D93 */,
  "rockman-blue": "rgba(59, 161, 233, 1)" /* #3BA1E9 */,
  "azure-blue": "rgba(29, 161, 242, 1)" /* #1DA1F2 */,
  "deep-cerulean": "rgba(29, 161, 242, 1)" /* #0077B5 */,
  "jungle-mist": "rgba(182, 205, 217, 1)" /* #B6CDD9 */,
  "chilly-blue": "rgba(137, 176, 197, 1)" /* #89B0C5 */,
  "hawkes-blue": "rgba(228, 240, 252, 1)" /* #E4F0FC */,
  "bubbles-blue": "rgba(227, 248, 255, 1)" /* #E3F8FF */,
  "dreaming-blue": "rgba(140, 193, 214, 1)" /* #8CC1D6 */,
  "cyan-blue-azure": "rgba(75, 129, 201, 1)" /* #4B81C9 */,
  "coastal-breeze-blue": "rgba(225, 245, 252, 1)" /* #E1F5FC */,
  "twilight-blue": "rgba(240, 253, 255, 1)" /* #F0FDFF */,
  "polar-blue": "rgba(231, 243, 250, 1)" /* #E7F3FA */,
  "polar-blue-light": "rgba(231, 243, 250, 0.5)" /* #E7F3FA */,
  "bondi-blue": "rgba(2, 142, 181, 1)" /* #028EB5 */,
  "blue-purple": "rgba(72, 38, 210, 1)" /* #4826D2 */,
  "blue-purple-hover": "#2E10A6",
  "congress-blue": "rgba(7, 70, 154, 1)" /* #07469A */,
  "congress-blue-50": "rgba(7, 70, 154, 0.5)" /* #07469A */,
  "diamond-blue": "rgba(206, 229, 238, 1)" /* #CEE5EE */,
  "blue-silk": "rgba(208, 218, 233, 1)" /* #D0DAE9 */,
  "forever-blue": "rgba(132, 154, 184, 1)" /* #849AB8 */,
  "seagull-blue": "rgba(120, 201, 230, 1)" /* #78C9E6 */,
  "blueberry-blue": "rgba(66, 133, 244, 1)" /* #4285F4 */,
  "curious-blue": "rgba(38, 165, 210, 1)" /* #26A5D2 */,
  "fresh-air-blue": "rgba(163, 233, 253, 1)" /* #A3E9FD */,
  "deep-sky-blue": "rgba(23, 123, 245, 1)" /* #177BF5 */,
  "maya-blue": "rgba(33, 166, 242, 1)" /* #21A6F2 */,
  "waterspout-blue": "rgba(157, 255, 237, 1)" /* #9DFFED */,
  "holiday-blue": "rgba(38, 189, 210, 1)" /* #26BDD2 */,
  "cloudy-blue": "rgba(169, 192, 219, 1)" /* #A9C0DB */,
  "onahau-blue": "rgba(199, 240, 255, 1)" /* #C7F0FF */,
  "downriver-blue": "rgba(13, 45, 82, 1)" /* #0D2D52 */,
  "cotton-boll": "rgba(232, 240, 252, 1)" /* #E8F0FC */,
  "banner-blue-highlighted": "rgba(0, 93, 203, 1)" /* #005DCB */,
  "dark-blue-highlighted": "#124180",
  "mangus-blue-hover": "#004EAB",
  "solitude-blue": "#E9F4FF",
  "blue-bayoux": "#516683",
  "blue-bayoux-a10": "rgba(81, 102, 131, 0.10)" /* #516683 */,
  "blue-bayoux-a22": "rgba(81, 102, 131, 0.22)" /* #516683 */,
  "alice-blue": "#EDF5FE",
  "cobalt-blue": "#0053B5",
  "clear-skies": "#E9F9FE",
  "alert-dialog-blue": "rgba(41, 92, 160, 0.13)",
  "move-it-forward-disabled": "rgba(0, 112, 244, 0.3)",
  "husky-blue": "#E2EDFB",
  "blue-estate": "#3E4784",
};

const yellows = {
  goldenrod: "#f4c751" /*  #f4c751 */,
  corn: "rgba(212, 204, 0, 1)" /*  #D4CC00 */,
  "button-yellow": "#ffc001",
  pumpkin: "rgba(248, 124, 9, 1)" /* #F87C09 */,
  "pumpkin-highlighted": "rgba(249, 150, 58, 1)" /* #f9963a */,
  "status-alert-fill": "#DF6B00",
  "status-alert-fill-a22": "rgba(223, 107, 0, 0.22)" /* #DF6B00 */,
  "pumpkin-dark": "#cf7c00",
  "canary-yellow": "rgba(249, 255, 174, 1)" /* #F9FFAE */,
  "canary-yellow-a50": "rgba(249, 255, 174, 0.5)" /* #F9FFAE */,
  "lemon-chiffon": "rgba(251, 255, 206, 1)" /* #FBFFCE */,
  "mellow-apricot": "rgba(255, 191, 112, 1)" /* #FFBF70 */,
  "sun-splashed": "rgba(250, 216, 149, 1)" /* #FAD895 */,
  "sultan-gold": "rgba(250, 173, 20, 1)" /* #FAAD14 */,
  "indian-pale-ale": "rgba(210, 182, 38, 1)" /* #D2B626 */,
  "sahara-gold": "rgba(170, 147, 19, 1)" /* #AA9313 */,
  "energy-yellow": "rgba(251, 224, 83, 1)" /* #FBE053 */,
  "seed-pod-yellow": "rgba(221, 195, 58, 1)" /* #DDC33A */,
  "selective-yellow": "rgba(251, 188, 5, 1)" /* #FBBC05 */,
  "candy-corn": "rgba(252, 227, 98, 1)" /* #FCE362 */,
  "golden-grass": "rgba(206, 179, 34, 1)" /* #CEB322 */,
  "tangerine-yellow": "rgba(241, 203, 0, 1)" /* #F1CB00 */,
  "light-yellow": "rgb(252, 255, 214)" /* #FCFFD6 */,
  "poetic-yellow": "#FCFFD6" /* #FCFFD6 */,
};

const reds = {
  scarlet: "rgba(222, 95, 82, 1)" /* #DE5F52 */,
  "scarlet-a11": "rgba(222, 95, 82, 0.11)" /* #DE5F52 */,
  "halt-red-orange": "rgb(218, 86, 30)" /*  #DA561E */,
  "safety-orange": "rgb(245, 103, 0)" /* #f56700 */,
  "safety-orange-a20": "rgb(245, 103, 0, 0.2)" /* #f56700 */,
  "table-button-cancel": "rgba(253, 145, 142, 1)" /* #fd918e */,
  "table-button-cancel-highlighted": "rgb(249, 173, 170)" /* #fd918e */,
  "heliotrope-magenta": "rgba(183, 12, 165, 1)" /* #B70CA5 */,
  coral: "rgba(255, 131, 85, 1)" /* #FF8355 */,
  "dark-pink": "rgba(255, 0, 73, 1)" /* FF0049 */,
  "fire-red": "rgba(190, 57, 0, 1)" /* #BE3900 */,
  "web-orange": "rgba(255, 170, 0, 1)" /* #FFAA00 */,
  "guardsman-red": "rgba(204, 0, 0, 1)" /* #CC0000 */,
  "pomegranate-red": "rgba(240, 64, 11, 1)" /* #F0400B */,
  "congo-pink": "rgba(255, 136, 120, 1)" /* #FF8878 */,
  "monarch-red": "rgba(145, 7, 14, 1)" /* #91070E */,
  "pesto-rosso": "rgba(186, 52, 52, 1)" /* #BA3434 */,
  "french-fuchsia": "rgba(245, 66, 149, 1)" /* #F54295 */,
  volcano: "rgba(78, 39, 40, 1)" /* #4E2728 */,
  "ballerina-pink": "rgba(246, 184, 184, 1)" /* #F6B8B8 */,
  "ghost-pepper-red": "rgba(195, 1, 0, 1)" /* #C30100 */,
  cinnabar: "rgba(234, 67, 53, 1)" /* #EA4335 */,
  "torch-red": "rgba(255, 0, 57, 1)" /* #FF0039 */,
  "ferrari-red": "rgba(224, 42, 2, 1)" /* #E02A02 */,
  "tijolo-red": "rgba(174, 83, 0, 1)" /* #AE5300 */,
  "persian-red": "rgba(211, 47, 47, 1)" /* #D32F2F */,
  "commission-clawback": "rgba(218, 86, 30, 1)" /* #DA561E */,
  "commission-debit": "#CA0000",
  raspberry: "rgba(255, 0, 107, 1)" /* #FF006B */,
  "raspberry-highlighted": "#DE005D",
  "brick-red": "rgba(207, 48, 48, 1)" /* #cf3030 */,
  "brick-red-a12": "rgba(207, 48, 48, 0.12)" /* #cf3030 */,
  danger: "rgba(202, 0, 0, 1)" /* #CA0000 */,
  "foam-green": "rgba(237, 253, 248, 1)" /* #EDFDF8 */,
};

const greens = {
  "sweet-mint": "rgba(81, 242, 203)" /* #51F2CB */,
  "caribbean-green": "rgba(12, 201, 168, 1)" /*  #0CC9A8 */,
  "harlequin-green": "rgb(71, 195, 76)" /*  #47C34C */,
  "harlequin-green-highlighted": "rgb(113, 210, 116)" /*  #47C34C */,
  mint: "rgb(133, 228, 163)" /*  #85E4A3 */,
  evergreen: "rgba(78, 190, 104, 1)" /* #4ebe68 */,
  "evergreen-a85": "rgba(78, 190, 104, 0.85)" /* #4ebe68 */,
  lime: "rgba(130, 232, 128, 1)" /* #82E880 */,
  "green-haze": "rgba(1, 158, 111, 1)" /* #019E6F */,
  "green-haze-a10": "rgba(1, 158, 111, 0.1)" /* #019E6F */,
  "green-haze-button": "#01815A",
  "green-haze-highlighted": "#01815A",
  "green-haze-button-highlighted": "#006A4A",
  "key-lime-pie": "rgba(186, 210, 38, 1)" /* #BAD226 */,
  "channel-marker-green": "rgba(0, 214, 176, 1)" /* #00D6B0 */,
  "kelly-green": "rgba(82, 196, 26, 1)" /* #52C41A */,
  "forest-green": "rgba(39, 135, 45, 1)" /* #27872D */,
  "teal-deer": "rgba(159, 224, 175, 1)" /* #9FE0AF */,
  "slime-lime": "rgba(183, 240, 189, 1)" /* #B7F0BD */,
  "chateau-green": "rgba(52, 168, 83, 1)" /* #34A853 */,
  "sea-green": "rgba(0, 245, 201, 1)" /* #00F5C9 */,
  "commission-credit": "#41A81D",
  "everbright-green": "rgba(100, 174, 108, 1)" /* #64ae6c */,
  "badge-green": "rgba(9, 146, 80, 1)",
  "badge-highlight-green": "rgba(237, 252, 242, 1)",
  "foam-green": "rgba(237, 253, 248, 1)" /* #EDFDF8 */,
  "granny-apple": "rgba(210, 244, 233, 1)" /* #D2F4E9 */,
};

const greyscale = {
  "disabled-grey": "rgba(0, 0, 0, 0.26)",
  "dark-charcoal": "rgb(36, 35, 35)" /*  #242323 */,
  "table-grey": "rgb(51, 51, 51)" /*  #333333 */,
  "text-dark": "#555555",
  "slate-grey": "rgb(78, 79, 80)" /*  #4E4F50 */,
  "table-badge-unread": "#666" /*  rgba(102, 102, 102, 1) */,
  "slate-grey-a65": "rgba(78, 79, 80, 0.65)",
  "table-badge-total": "rgba(170, 170, 170, 1)" /* #AAAAAA */,
  "silver-grey": "rgba(204, 204, 204, 1)" /*  #CCC */,
  "old-grey": "#ced1d3",
  "old-grey-highlighted": "#dbdee0",
  silver: "rgba(221, 221, 221, 1)" /* #DDDDDD */,
  white: "rgba(255, 255, 255, 1)" /* #ffffff (css "white") */,
  "white-a65": "rgba(255, 255, 255, 0.65)" /* #ffffff */,
  "table-edit-mode": "rgba(243, 248, 255, 1)" /* #F3F8FF */,
  "table-edit-mode-a25": "rgba(243, 248, 255, 0.25)" /* #F3F8FF */,
  grey: "rgb(241, 245, 248)" /* #F1F5F8 */,
  "disabled-grey-input": "#CCCCCC",
  "graham-grey": "rgba(177, 194, 217, 1)" /* #B1C2D9 */,
  "athens-gray": "rgba(250, 251, 252, 1)" /* #FAFBFC */,
  "light-grey": "rgb(217, 234, 253)" /* #D9EAFD */,
  "light-grey-a65": "rgba(217, 234, 253, 0.65)",
  "light-grey-a25": "rgba(217, 234, 253, 0.25)",
  "lighter-grey": "rgb(239, 240, 241)" /*  #EFF0F1 */,
  "lighter-grey-a65": "rgba(239, 240, 241, 0.65)",
  "lightest-grey": "rgb(248, 248, 248)" /* #f8f8f8", */,
  gainsboro: "rgb(225, 225, 225)" /* #E1E1E1 */,
  cream: "#f5f7f9",
  "alabaster-white": "rgba(249, 249, 249, 1)" /*  #F9F9F9 */,
  "ma-white": "rgb(245, 248, 255)" /* #F5F8FF */,
  "ma-white-a22": "rgba(245, 248, 255, 0.22)" /* #F5F8FF */,
  "gallery-white": "rgba(238, 238, 238, 1)" /*  #EEE */,
  "trolley-grey": "rgb(130,130,130)" /* #828282 */,
  black: "rgba(51, 51, 51, 1)" /* #333333 */,
  "sock-cushion-grey": "#aaa",
  "hammerhead-grey": "#888888",
  "dark-grey": "rgba(112, 112, 112, 1)" /* #707070 */,
  "light-silver": "rgba(217, 217, 217, 1)" /* #D9D9D9 */,
  "bright-gray": "rgba(231, 237, 246, 1)" /* #E7EDF6 */,
  "ghost-white": "rgba(246, 249, 250, 1)" /* #F6F9FA */,
  "hit-gray": "rgba(167, 180, 185, 1)" /* #A7B4B9 */,
  "loblolly-gray": "rgba(179, 189, 199, 1)" /* #B3BDC7 */,
  "mineshaft-gray": "rgba(58, 58, 58, 1)" /* #3A3A3A */,
  "dusty-gray": "rgba(153, 153, 153, 1)" /* #999999 */,
  "tundora-gray": "rgba(75, 75, 75, 1)" /* #4B4B4B */,
  "regent-gray": "rgba(128, 145, 161, 1)" /* #8091A1 */,
  "binrouji-black": "rgba(68, 61, 61, 1)" /* #443D3D */,
  "raisin-black": "rgba(34, 34, 34, 1)" /* #222222 */,
  "bleached-silk": "rgba(242, 242, 242, 1)" /* #F2F2F2 */,
  "springtime-dew": "rgba(254, 255, 239, 1)" /* #FEFFEF */,
  "twinkle-gray": "rgba(171, 200, 211, 1)" /* #ABC8D3 */,
  "catskill-white": "rgba(237, 244, 247, 1)" /* #EDF4F7 */,
  "brilliant-white": "rgba(230, 241, 254, 1)" /* #E6F1FE */,
  "maui-mist": "rgba(239, 243, 245, 1)" /* #EFF3F5 */,
  "american-silver": "rgba(206, 206, 206, 1)" /* #CECECE */,
  "emptiness-white": "rgba(252, 252, 252, 1)" /* #FCFCFC */,
  "lynx-white": "rgba(247, 247, 247, 1)" /* #F7F7F7 */,
  "white-smoke": "rgba(245, 245, 245, 1)" /* #F5F5F5 */,
  "cultured-white": "rgba(246, 247, 249, 1)" /* #F6F7F9 */,
  "lead-black": "rgba(32, 32, 32, 1)" /* #202020 */,
  "outer-space": "rgba(38, 50, 56, 1)" /* #263238 */,
  "anti-flash-white": "rgba(241, 245, 247, 1)" /* #F1F5F7 */,
  "lighthouse-white": "rgba(244, 244, 244, 1)" /* #F4F4F4 */,
  "light-gravel": "rgba(187, 187, 187, 1)" /* #BBBBBB */,
  "dark-veil-black": "rgba(20, 19, 17, 1)" /* #141311 */,
  "errigal-white": "rgba(240, 242, 245, 1)" /* #F0F2F5 */,
  "explosive-gray": "rgba(196, 196, 196, 1)" /* #C4C4C4 */,
  "philippine-silver": "rgba(179, 180, 181, 1)" /* #B3B4B5 */,
  "porcelain-white": "rgba(233, 234, 235, 1)" /* #E9EAEB */,
  "moustache-gray": "rgba(126, 126, 126, 1)" /* #7E7E7E */,
  "spanish-gray": "rgba(152, 152, 152, 1)" /* #989898 */,
  "wizard-white": "rgba(226, 239, 254, 1)" /* #E2EFFE */,
  "sparkling-snow": "rgba(243, 252, 255, 1)" /* #F3FCFF */,
  "gallery-gray": "rgba(240, 240, 240, 1)" /* #F0F0F0 */,
  "distant-horizon": "rgba(240, 246, 251, 1)" /* #F0F6FB */,
  "whale-white": "rgba(242, 243, 244, 1)" /* #F2F3F4 */,
  "unbleached-white": "rgba(253, 250, 244, 1)" /* #FDFAF4 */,
  "nevada-gray": "rgba(94, 103, 108, 1)" /* #5E676C */,
  "fiord-gray": "#405169",
  badges: "#EAECF5",
};

const oranges = {
  "blaze-orange": "rgba(245, 103, 0, 1)" /* #F56700 */,
  "sunset-orange": "rgba(255, 77, 79, 1)" /* #FF4D4F */,
  "mango-orange": "rgba(249, 166, 0, 1)" /* #F9A600 */,
  "tangerine-orange": "rgba(232, 140, 2, 1)" /* #E88C02 */,
  "sunray-orange": "rgba(230, 178, 79, 1)" /* #E6B24F */,
  "equator-orange-a07": "rgba(227, 181, 91, 0.07)" /* #E3B55B12 */,
  "sunnova-orange": "rgba(255, 142, 40, 1)" /* #ff8e28 */,
  "warning-orange": "rgba(255, 229, 205, 1)" /* #FFE5CD */,
};

const purples = {
  "medium-purple": "rgba(160, 82, 222, 1)" /* #A052DE */,
  eggplant: "rgba(72, 38, 210, 1)" /* #4826D2 */,
  "venetian-nights": "rgba(125, 92, 255, 1)" /* #7D5CFF */,
  "dark-lavender": "rgba(59, 89, 152, 1)" /* #3B5998 */,
  "heliotrope-magenta": "rgba(159, 13, 178, 1)" /* #9F0DB2 */,
  "electric-violet": "rgba(130, 83, 254, 1)" /* #8253FE */,
  "trusted-purple": "rgba(99, 12, 208, 1)" /* #630CD0 */,
};

const colors = {
  ...blues,
  ...yellows,
  ...reds,
  ...greens,
  ...greyscale,
  ...oranges,
  ...purples,
};

const configureColorVars = () => {
  const root = document.documentElement;

  Object.keys(colors).forEach((color) => {
    root.style.setProperty(`--${color}`, colors[color as keyof typeof colors]);
  });
};

const cvar = (name: keyof typeof colors) => `var(--${name})`;
type TColors = typeof colors;

export { colors, configureColorVars, cvar, TColors };

import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

type prefferedViewT = {
  viewName: string;
  filters: Record<string, unknown>;
};

type CounterT = {
  total: number;
  show: number;
};

type initStateT = {
  filterSets: unknown[];
  prefferedView: prefferedViewT;
  counter: CounterT;
};

const initialState: initStateT = {
  filterSets: [],
  prefferedView: {
    viewName: "",
    filters: {},
  },
  counter: {
    total: 0,
    show: 0,
  },
};

type actionT = {
  id: string;
  payload: Record<string, unknown>;
  type: string;
};

export default {
  filterSets: createReducer(initialState, {
    [types.SET_FILTER_SETS](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [types.UPDATE_PREFERRED_VIEW_BULK](state, action: actionT) {
      return {
        ...state,
        prefferedView: {
          ...state.prefferedView,
          filters: {
            ...state.prefferedView.filters,
            ...action.payload,
          },
        },
      };
    },
    [types.UPDATE_PREFERRED_VIEW](state, action: actionT) {
      const currentSelectedFilterOptions = (state.prefferedView.filters[action.payload.key as string] ||
        []) as Array<unknown>;
      return {
        ...state,
        prefferedView: {
          view: action.payload.view,
          filters: {
            ...state.prefferedView.filters,
            ...(action.payload.multiSelect
              ? {
                  [action.payload.key as string]: [...currentSelectedFilterOptions, action.payload.value],
                }
              : { [action.payload.key as string]: action.payload.value }),
          },
        },
      };
    },
    [types.DELETE_PREFERRED_VIEW_FILTER](state, action: actionT) {
      const selectedFilter = state.prefferedView.filters[action.payload.key as string];
      const filterOptions = Array.isArray(selectedFilter)
        ? selectedFilter?.filter((option) => option.id !== action.payload.id)
        : [];
      return {
        ...state,
        prefferedView: {
          view: "",
          filters: {
            ...state.prefferedView.filters,
            [action.payload.key as string]: filterOptions?.length > 0 ? filterOptions : null,
          },
        },
      };
    },
    [types.SET_CLEAR_PREFERRED_VIEW](state) {
      return {
        ...state,
        prefferedView: {
          view: "",
          filters: {},
        },
      };
    },
    [types.SET_PREFERRED_VIEW](state, action) {
      return {
        ...state,
        prefferedView: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};

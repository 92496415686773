import { takeEvery, takeLatest } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/enterpriseProjects/types";
import getOrgSellers from "./getOrgSellers";
import getEnterpriseProjects from "./getEnterpriseProjects";
import getEnterpriseOfferLeadGeocode from "./getEnterpriseOfferLeadGeocode";
import submitEnterpriseLeadToSeller from "./submitEnterpriseLeadToSeller";
import getEnterpriseLeadOfferDetails from "./getEnterpriseLeadOfferDetails";
import submitSellerOfferLeadDecision from "./submitSellerOfferLeadDecision";
import rescindLeadOffer from "./rescindLeadOffer";
import startContractsReport from "./startContractsReport";
import getContractsReport from "./getContractsReport";
import getLeadOfferResponseDetails from "./getLeadOfferResponseDetails";
import cancelLeadOffer from "./cancelLeadOffer";
import getEnterpriseProjectLookupOptions from "./getEnterpriseProjectLookupOptions";
import toggleM1Advance from "./toggleM1Advance";
import getLeadGenProjectsPreSales from "./getLeadGenProjectsPreSales";
import getLeadGenProjectsPostSales from "./getLeadGenProjectsPostSales";
// IMPORT_PT (for script, do not remove)

function* watchEnterpriseProjectsSagas() {
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_PROJECTS_LIST_REQUEST, getEnterpriseProjects);
  yield trackRequests(takeEvery, types.GET_ORG_CONSULTANTS_REQUEST, getOrgSellers);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_OFFER_LEAD_GEOCODE, getEnterpriseOfferLeadGeocode);
  yield trackRequests(takeEvery, types.SUBMIT_ENTERPRISE_LEAD_OFFER_TO_CONSULTANT, submitEnterpriseLeadToSeller);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_LEAD_OFFER_DETAILS, getEnterpriseLeadOfferDetails);
  yield trackRequests(takeEvery, types.SUBMIT_CONSULTANT_OFFER_LEAD_DECISION, submitSellerOfferLeadDecision);
  yield trackRequests(takeEvery, types.GET_LEAD_OFFER_RESPONSE_DETAILS, getLeadOfferResponseDetails);
  yield trackRequests(takeEvery, types.RESCIND_LEAD_OFFER_REQUEST, rescindLeadOffer);
  yield trackRequests(takeEvery, types.START_CONTRACTS_REPORT_REQUEST, startContractsReport);
  yield trackRequests(takeEvery, types.GET_CONTRACTS_REPORT_REQUEST, getContractsReport);
  yield trackRequests(takeEvery, types.CANCEL_LEAD_OFFER, cancelLeadOffer);
  yield trackRequests(takeLatest, types.GET_ENTERPRISE_PROJECT_LOOKUP_OPTIONS, getEnterpriseProjectLookupOptions);
  yield trackRequests(takeEvery, types.TOGGLE_M1_ADVANCE, toggleM1Advance);
  yield trackRequests(takeEvery, types.GET_LEAD_GEN_PROJECTS_PRE_SALES, getLeadGenProjectsPreSales);
  yield trackRequests(takeEvery, types.GET_LEAD_GEN_PROJECTS_POST_SALES, getLeadGenProjectsPostSales);
  // INSERTION_PT (for script, do not remove)
}

export default watchEnterpriseProjectsSagas;

import React from "react";
import PropTypes from "prop-types";

import styles from "../CreateAccount.module.less";

const RadioButtonCard = ({ text, graphic, speechBubble }) => {
  return (
    <div className={styles.joinRadioButtonCard}>
      <div className={styles.iconContainer}>{graphic}</div>
      {speechBubble && <div className={styles.speechBubbleArrow} />}
      <div className={styles.radioButtonText}>{text}</div>
    </div>
  );
};

RadioButtonCard.propTypes = {
  text: PropTypes.string,
  graphic: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  speechBubble: PropTypes.bool,
};

export default RadioButtonCard;

import React from "react";
import PropTypes from "prop-types";
import User from "models/User";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import FEATURE_FLAGS from "@flags";

const TeamFilters = (props) => {
  const { filters, navigate, override } = props;
  const menus = [
    {
      key: "rank",
      label: "Rank",
      options: [
        {
          key: "any-rank",
          label: "All Ranks",
          default: true,
          index: 0,
          parentKey: "rank",
        },
      ],
    },
    {
      key: "level",
      label: "Level",
      options: [
        {
          key: "any-level",
          label: "All Levels",
          default: false,
          index: 1,
          parentKey: "level",
        },
        {
          key: "l1",
          label: "Level 1",
          default: true,
          index: 1,
          parentKey: "level",
        },
      ],
    },
    {
      key: "status",
      label: "Subscription Status",
      options: [
        {
          key: "all-statuses",
          label: "All Subscription Statuses",
          default: false,
          index: 2,
          parentKey: "status",
        },
      ],
    },
    {
      key: "activity",
      label: "Qualifying Activity",
      options: [
        {
          key: "all-qualification",
          label: "All Qualification Statuses",
          default: false,
          index: 3,
          parentKey: "activity",
        },
      ],
    },
    {
      key: "location",
      label: "Location",
      options: [
        {
          key: "any-location",
          label: "All states/provinces",
          default: true,
          index: 4,
          parentKey: "location",
        },
      ],
    },
    {
      key: "sort",
      label: "Sort Type",
      options: [
        {
          key: "newest",
          label: "Sort By Newest",
          default: true,
          index: 5,
          parentKey: "sort",
        },
        {
          key: "name",
          label: "Sort By Name",
          default: false,
          index: 5,
          parentKey: "sort",
        },
        {
          key: "activity",
          label: "Sort By Activity",
          default: false,
          index: 5,
          parentKey: "sort",
        },
      ],
    },
    ...(FEATURE_FLAGS.includes("teamActivityFilters")
      ? [
          {
            key: "team_activity",
            label: "Team Activity (Levels 1-7)",
            options: [
              {
                key: "all_team_activity",
                label: "All Team Activity",
                default: true,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "sellers_this_week",
                label: "New Sellers This Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "sellers_last_week",
                label: "New Sellers Last Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "leads_this_week",
                label: "New Leads This Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "leads_last_week",
                label: "New Leads Last Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "proposals_this_week",
                label: "New Proposals This Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "proposals_last_week",
                label: "New Proposals Last Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "contracts_this_week",
                label: "New Contracts This Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "contracts_last_week",
                label: "New Contracts Last Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "installs_this_week",
                label: "New Installs This Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
              {
                key: "installs_last_week",
                label: "New Installs Last Week",
                default: false,
                index: 6,
                parentKey: "team_activity",
              },
            ],
          },
        ]
      : []),
  ];

  for (let i = 1; i <= filters.ranks; i++) {
    menus[0].options.push({
      key: `r${i}`,
      label: `Rank ${i}`,
      default: false,
      index: 0,
      parentKey: "rank",
    });
  }

  for (let i = 1; i <= filters.levels; i++) {
    // skip level 1, already in list as default
    if (i > 1) {
      menus[1].options.push({
        key: `l${i}`,
        label: `Level ${i}`,
        default: false,
        index: 1,
        parentKey: "level",
      });
    }
  }

  if (filters.statuses) {
    menus[2].options.push(
      ...filters.statuses.map((o) => {
        const key = Object.keys(o)[0];
        const label = Object.values(o)[0];
        return {
          key,
          label: key === "active" ? "Subscribed" : label,
          default: key === "active",
          index: 2,
          parentKey: "status",
        };
      }),
    );
  }

  if (filters.activity) {
    menus[3].options.push(
      ...filters.activity.map((o) => {
        const key = Object.keys(o)[0];
        const label = Object.values(o)[0];

        return {
          key,
          label: key === "isActive" ? "Active" : label,
          default: key === "isActive",
          index: 3,
          parentKey: "activity",
        };
      }),
    );
  }

  if (filters.states) {
    menus[4].options.push(
      ...filters.states.map((o) => {
        const key = Object.keys(o)[0];
        const label = Object.values(o)[0];
        return {
          key,
          label,
          default: false,
          index: 4,
          parentKey: "location",
        };
      }),
    );
  }

  return <SidebarFilters menus={menus} navigate={navigate} override={override} />;
};

TeamFilters.propTypes = {
  navigate: PropTypes.func.isRequired,
  override: PropTypes.func.isRequired,
  filters: User.filterTypes(),
};

export default TeamFilters;

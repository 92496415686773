import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* updateProfile(action) {
  const { user, address } = action.payload;

  const body = {
    user: {
      first_name: user.firstName,
      last_name: user.lastName,
      org_name: user.orgName,
      phone: user.phone,
      locale: user.locale,
      spoken_languages: user.spokenLanguages,
      profile_image_url: user.profileImageUrl,
      dob: user.dob,
      gender: user.gender,
      areas_serviced: user.areasServiced,
      marital_status: user.maritalStatus,
      vanity_name: user.vanityName,
      bio: user.bio,
      timezone: user.timezone,
      service_areas: user.serviceAreas,
      proposal_bio: user.proposalBio,
      seller_avatar_url: user.sellerAvatarUrl,
      seller_calendly_url: user.sellerCalendlyUrl,
      homeowner_calendly_url: user.homeownerCalendlyUrl,
      default_advance: user.defaultAdvance,
    },
  };

  if (address) {
    body.address = {
      city: address.city,
      state: address.state,
      street: address.street,
      unit: address.unit,
      zip: address.zip,
    };
  }

  const { success, data, error } = yield* makeRequest.patch("/profile", body);

  if (success && data) {
    yield all([
      put(actions.setProfile(keysToCamelCase(data.body))),
      put(actions.newSuccessEvent({ message: messages.PROFILE_UPDATE_SUCCESS })),
      put(
        actions.setAlertMessageVisible({
          message: messages.PROFILE_UPDATE_SUCCESS,
          severity: "success",
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PROFILE,
    });
  }
  return null;
}

export default updateProfile;

import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import type { CrmActivitiesReduxStateT } from "types/Project/crmActivity";

const initialState: CrmActivitiesReduxStateT = {
  crmActivityDrawer: {
    visible: false,
    // TEMP REMOVE - call activity (previously defaulted to "call")
    activityType: "email",
    leadId: 0,
    activityId: undefined,
  },
  crmActivityDetails: {},
  crmTaskList: {
    activities: [],
    stuckProjects: [],
    count: 0,
  },
};

export default {
  crmActivities: createReducer(initialState, {
    [types.SET_CRM_ACTIVITY_DRAWER_VISIBLE](state, action) {
      return {
        ...state,
        crmActivityDrawer: {
          ...state.crmActivityDrawer,
          ...action.payload,
        },
      };
    },
    [types.SET_CRM_ACTIVITY_LEAD_ID](state, action) {
      return {
        ...state,
        crmActivityDrawer: {
          leadId: action.payload.leadId,
        },
      };
    },
    [types.CLEAR_CRM_ACTIVITY_LEAD_ID](state) {
      return {
        ...state,
        crmActivityDrawer: initialState.crmActivityDrawer,
      };
    },
    [types.SET_CRM_ACTIVITY_ID](state, action) {
      return {
        ...state,
        crmActivityDrawer: {
          activityId: action.payload.activityId,
        },
      };
    },
    [types.SET_CRM_ACTIVITY_DETAILS](state, action) {
      return {
        ...state,
        crmActivityDetails: action.payload,
      };
    },
    [types.CLEAR_CRM_ACTIVITY_DETAILS](state) {
      return {
        ...state,
        crmActivityDetails: {},
      };
    },
    [types.SET_CRM_TASK_LIST](state, action) {
      return {
        ...state,
        crmTaskList: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};

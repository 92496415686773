export default {
  personal: {
    key: "personal",
    idx: 0,
    title: "Apply to Enterprise",
    subtitle: "To get started, please enter your information below.",
    cancel: true,
    advance: true,
    buttonTxt: "next_step",
  },
  business: {
    key: "business",
    idx: 1,
    title: "Apply to Enterprise (2/3)",
    subtitle: "We have a few questions in order to process your application",
    back: true,
    advance: true,
    buttonTxt: "next_step",
  },
  orgOfficers: {
    key: "orgOfficers",
    idx: 2,
    title: "Apply to Enterprise (3/3)",
    subtitle: "We have a few questions in order to process your application",
    back: true,
    submit: true,
    buttonTxt: "Submit",
  },
  confirmation: {
    key: "confirmation",
    idx: 3,
    title: "Thank You!",
    close: true,
    buttonTxt: "Go To Dashboard",
  },
};

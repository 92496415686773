import ApiClient from "../util/ApiClient";

export class ApplicationAgreement {
  static get() {
    const api = new ApiClient({ host: process.env.REACT_APP_POWUR_API_URL });
    return new Promise((resolve, reject) => {
      api.get("/application_agreement", { kind: "consultant" }).then(
        (res) => {
          resolve(new ApplicationAgreement(res.body));
        },
        (res) => reject(res),
      );
    });
  }

  constructor(props) {
    this.props = props;
  }
}

export default ApplicationAgreement;

import Account from "./account";
import AdminCommunication from "./adminCommunication";
import AdminDashboard from "./adminDashboard";
import AdminFinancials from "./adminFinancials";
import AdminReports from "./adminReports";
import AdminProjectsDetails from "./adminProjectsDetails";
import AdminProjectsList from "./adminProjectsList";
import AdminProposals from "./adminProposals";
import AdminRoofingProposals from "./adminRoofingProposals";
import AdminSettings from "./adminSettings";
import AdminUsers from "./adminUsers";
import AdminWorkflows from "./adminWorkflows";
import AmbassadorDashboard from "./ambassador";
import Compensation from "./compensation";
import ActivityCenter from "./activityCenter";
import SellerDashboard from "./dashboard";
import SellerDashboardEventsAndUpdates from "./dashboardEventsAndUpdates";
import Earnings from "./earnings";
import EnterpriseDashboard from "./enterpriseDashboard";
import EnterpriseProjectsList from "./enterpriseProjectsList";
import EnterpriseProjectsDetails from "./enterpriseProjectsDetails";
import EnterpriseMarketing from "./enterpriseMarketing";
import EnterpriseEarnings from "./enterpriseEarnings";
import EnterpriseTeam from "./enterpriseTeam";
import EnterpriseApplicationsReview from "./enterpriseApplicationsReview";
import EnterpriseApplicationsDetails from "./enterpriseApplicationsDetails";
import OrgActivityCenter from "./orgActivityCenter";
import BuildPartnerApplicationsDetails from "./buildPartnerApplicationsDetails";
import GettingStarted from "./gettingStarted";
import IconLibrary from "./iconLibrary";
import Marketing from "./marketing";
import PlatformAlerts from "./platformAlerts";
import Profile from "./profile";
import ProjectsDetails from "./projectsDetails";
import ProjectsList from "./projectsList";
import Reports from "./reports";
import Team from "./team";
import Tickets from "./tickets";
import Modules from "./modules";

/*
  keep routes sorted first by category then by order we would like them to appear in side nav
  important to keep project list views before project detail views as we want router to
  prioritize matching those routes first.
*/
export default [
  /* Category: Personal */
  Profile,
  Account,

  /* Category: Powur */
  Tickets,

  /* Category: Sales */
  GettingStarted,
  SellerDashboard,
  SellerDashboardEventsAndUpdates,
  ProjectsList,
  ProjectsDetails,
  Marketing,
  Earnings,
  Reports,
  Team,
  ActivityCenter,
  AmbassadorDashboard,

  /* Category: Enterprise */
  EnterpriseDashboard,
  EnterpriseProjectsList,
  EnterpriseProjectsDetails,
  EnterpriseMarketing,
  EnterpriseEarnings,
  EnterpriseTeam,
  Modules,
  OrgActivityCenter,

  /* Category: Admin */
  AdminDashboard,
  AdminProjectsList,
  AdminProjectsDetails,
  AdminUsers,
  AdminProposals,
  AdminRoofingProposals,
  AdminFinancials,
  AdminReports,
  Compensation,
  PlatformAlerts,
  AdminWorkflows,
  AdminSettings,
  AdminCommunication,
  EnterpriseApplicationsReview,
  EnterpriseApplicationsDetails,
  BuildPartnerApplicationsDetails,

  /* Misc */
  IconLibrary,
];

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Row, Button } from "antd";
import styles from "../../Join.module.less";

const PowurIntoRow = (props) => {
  const { handleCreateAccount } = props;
  const { t } = useTranslation(["join", "form_labels"]);
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

  const openPresentation = () => {
    const presentationLink =
      "https://docs.google.com/presentation/d/10AkpX4spf8nzEPFUo8yTRmD9VOmZeDBagushYW-C49o/edit#slide=id.g1809e36b7a2_3_2675";
    window.open(presentationLink, "_blank");
  };

  return (
    <Row className={styles.powurIntroRow}>
      <Col
        xxl={{ span: 6, offset: 6 }}
        xl={{ span: 6, offset: 4 }}
        lg={{ span: 6, offset: 2 }}
        md={{ span: 12, offset: 6 }}
        sm={{ span: 16, offset: 4 }}
        xs={{ span: 20, offset: 2 }}
        className={styles.buttonContainer}
      >
        <Row>
          <Col>
            <div className={styles.header}>{t("be_a_team_player")}</div>
            <div className={styles.underline} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.subHeader}>{t("introduction_to_powur")}</div>
          </Col>
        </Row>
        <Button className={styles.primaryButton} type="primary" block onClick={handleCreateAccount}>
          {t("form_labels:join_now")}
        </Button>
        <Button className={styles.linkButton} onClick={openPresentation}>
          {t("download_presentation")}
        </Button>
      </Col>
      <Col
        xxl={{ span: 5, offset: 2 }}
        xl={{ span: 5, offset: 2 }}
        lg={{ span: 5, offset: 1 }}
        className={styles.imgContainer}
        onClick={openPresentation}
      >
        <img src={`${urlPrefix}/assets/img/join/presentation-books-2.png`} alt="powur presentation booklets" />
      </Col>
    </Row>
  );
};

PowurIntoRow.propTypes = {
  handleCreateAccount: PropTypes.func,
};

export default PowurIntoRow;

import React from "react";
import PropTypes from "prop-types";

const ProfileIcon = (props) => {
  const { height, width, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.69156 3.87698C5.68169 1.90987 8.43169 0.695312 11.4688 0.695312C17.5429 0.695312 22.4688 5.55831 22.4688 11.5549C22.4688 14.5532 21.2385 17.2681 19.2459 19.2328C17.2534 21.1975 14.5058 22.4145 11.4688 22.4145C8.43169 22.4145 5.68169 21.1999 3.69156 19.2328C1.70143 17.2657 0.46875 14.5532 0.46875 11.5549C0.46875 8.5566 1.69901 5.8417 3.69156 3.87698ZM11.4712 4.03892C9.36765 4.03654 7.66458 5.72025 7.66458 7.7969C7.667 9.83784 9.3966 11.5477 11.4639 11.5477C13.5674 11.5501 15.2705 9.86642 15.2705 7.78976C15.2705 5.75121 13.5385 4.0413 11.4712 4.03892ZM5.14857 17.7944C5.91086 18.5469 6.80822 19.1637 7.80208 19.6043C8.92138 20.102 10.1613 20.3783 11.4688 20.3783C12.7762 20.3783 14.0161 20.102 15.1354 19.6043C16.1293 19.1614 17.0291 18.5445 17.7889 17.7944C18.1725 17.4157 18.5223 17.0013 18.8334 16.5584C18.2786 14.7318 16.5563 13.391 14.5299 13.391H8.40757C6.38125 13.391 4.65888 14.7318 4.10406 16.5584C4.41524 17.0013 4.76502 17.4133 5.14857 17.7944Z"
        fill={fill}
      />
    </svg>
  );
};

ProfileIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ProfileIcon.defaultProps = {
  width: "23",
  height: "23",
  fill: "var(--royal-peacock-blue)",
};

export default ProfileIcon;

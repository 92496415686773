// React
import React from "react";
import PropTypes from "prop-types";
import actionTypes from "rdx/modules/team/types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useParams } from "react-router-dom";

import { Skeleton } from "antd";

// Models
import User from "models/User";

// Hooks
import useLoading from "hooks/useLoading";
import useNavigation from "hooks/useNavigation";
import useRestrictAccessModal from "hooks/useRestrictAccessModal";

import SessionClient from "util/SessionClient";

// Components
import ResetFiltersButton from "@buttons/ResetFilters";
import OpenDrawerButton from "components/PlatformLayout/components/Sidebar/OpenDrawerButton";
import SidebarSearch from "components/PlatformLayout/components/Sidebar/Search";
import teamTabs from "../../tabs";
import LeadsFilters from "./leadsfilters";
import TeamFilters from "./teamfilters";
import AmbassadorFilters from "./ambassadorfilters";

import styles from "../../MyTeam.module.less";

const Sidebar = (props) => {
  const { teamList, setInviteDrawerVisible, inviteDrawerVisible } = props;

  const { filters: teamFilters } = teamList;
  const { tab } = useParams();
  const tabKeys = teamTabs.map(({ key }) => key);
  const { navigate, override } = useNavigation("team", ["tab"], ["userId", "drawerTab"]);
  const { backOfficeAccessTrue, restrictAccessModal } = useRestrictAccessModal();

  const session = new SessionClient();
  const { isSeatedSeller, isEnterpriseStaffOnly } = session;

  const sessionUserRecruitBarred = isSeatedSeller && !session.user.props.enterpriseSellerInvitesAllowed;

  const loading = useLoading({
    watchRequests: [
      actionTypes.GET_TEAM_LEADS_LIST_REQUEST,
      actionTypes.GET_TEAM_LIST_REQUEST,
      actionTypes.GET_AMBASSADOR_LIST_REQUEST,
      actionTypes.GET_TEAM_UPLINE_LIST_REQUEST,
      actionTypes.GET_TEAM_USER_DETAILS_REQUEST,
      actionTypes.GET_TEAM_USER_RECRUITS_REQUEST,
    ],
  });

  const handleOpenDrawer = () => setInviteDrawerVisible(!inviteDrawerVisible);

  return (
    <div className={styles.sidebar}>
      {(!sessionUserRecruitBarred || isEnterpriseStaffOnly) && (
        <OpenDrawerButton
          handleClick={() => (backOfficeAccessTrue ? handleOpenDrawer() : restrictAccessModal())}
          drawerOpen={inviteDrawerVisible}
          title="Add New"
        />
      )}
      {(tab === tabKeys[3] || tab === tabKeys[0] || tab === tabKeys[1]) && <SidebarSearch navigate={navigate} />}
      {tab === tabKeys[3] && <LeadsFilters navigate={navigate} override={override} />}
      {tab === tabKeys[0] && (
        <Skeleton active paragraph={{ rows: 10, width: "100%" }} title={false} round loading={loading}>
          <TeamFilters filters={teamFilters} navigate={navigate} override={override} />
        </Skeleton>
      )}
      {tab === tabKeys[1] && <AmbassadorFilters filters={teamFilters} navigate={navigate} override={override} />}
      {(tab === tabKeys[0] || tab === tabKeys[1]) && <ResetFiltersButton />}
    </div>
  );
};

Sidebar.propTypes = {
  teamList: PropTypes.shape({
    filters: User.filterTypes(),
    paging: PropTypes.shape({
      item_count: PropTypes.number,
      page_size: PropTypes.number,
      page_number: PropTypes.number,
    }),
    users: PropTypes.arrayOf(User.types()),
  }),
  inviteDrawerVisible: PropTypes.bool,
  setInviteDrawerVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    teamList: "getTeamList",
    requests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
    inviteDrawerVisible: "getInviteDrawerVisible",
  }),
  getRdxActionMapper(["setInviteDrawerVisible"]),
)(Sidebar);

import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseProjects/messages";
import { camelizeKeys } from "lib/helpers";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import { LeadGenProjectsPreSalesResponse } from "../schemas/LeadGenProjects";

function* getLeadGenProjectsPreSales(action) {
  const params = {
    ...action.payload,
    sort: "updated",
    limit: 20,
  };

  const { data, error } = yield* makeRequest.get("/projects_pre_sales/lead_generator", params);

  const result = SchemaValidator.validate<typeof LeadGenProjectsPreSalesResponse>({
    schema: LeadGenProjectsPreSalesResponse,
    schemaName: "LeadGenProjectsPreSalesResponse",
    dto: camelizeKeys(data),
  });

  if (result.success && result.data) {
    yield put(actions.setLeadGenPreSalesProjects(camelizeKeys(result.data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_LEAD_GENERATOR_PROJECTS,
    });
  }

  return null;
}

export default getLeadGenProjectsPreSales;

// import { get } from "lodash";

export default {
  selectCharges: (state) => state.enrollment.charges,
  selectUpgradePreview: (state) => state.enrollment.upgradePreview,
  selectEnrollments: (state) => state.enrollment.enrollments,
  selectServices: (state) => state.enrollment.services,
  selectEnrollmentsMonthlyTotal: (state) => state.enrollment.monthlyTotal,
  selectEnrollmentsCredit: (state) => state.enrollment.credit,
  selectCardInfo: (state) => state.enrollment.cardInfo,
  selectSelectedEnrollment: (state) => state.enrollment.selectedEnrollment,
  selectDowngradeTo: (state) => state.enrollment.downgradeTo,
};

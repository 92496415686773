import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import styles from "./BadgeAvatar.module.less";

type BasicAvatarT = {
  thumb?: string;
  size?: number;
  avatarStyle?: Record<string, unknown>;
  avatarIcon?: string | React.ReactNode;
  children?: React.ReactNode;
};

const BasicAvatar = ({
  thumb = "",
  size = 48,
  avatarStyle = {},
  avatarIcon = <UserOutlined />,
  children = null,
}: BasicAvatarT) => {
  return (
    <div className={styles.badgeAvatarContainer}>
      <Avatar size={size} shape="circle" className={styles.teamBadge} src={thumb} icon={avatarIcon} style={avatarStyle}>
        {children}
      </Avatar>
    </div>
  );
};

export default BasicAvatar;

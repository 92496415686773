import React, { useState } from "react";
import { useEffectOnce } from "react-use";

import { Typography, Form, Checkbox } from "antd";
import { openLinkInNewTab } from "lib/props";
import ApplicationAgreement from "models/ApplicationAgreement";
import FormErrorMessage from "components/FormElements/FormErrorMessage";

import styles from "./PaymentInfo.module.less";

const { Text } = Typography;

const TermsOfService = ({ theme = "dark", handleTos, onSubmit }) => {
  const [tos, setTos] = useState({
    agreement: new ApplicationAgreement({}),
  });

  useEffectOnce(() => {
    ApplicationAgreement.get().then((agreement) => setTos({ ...tos, agreement }));
  });

  const handleCheckBox = () => handleTos();

  return (
    <Form.Item
      name="tos"
      validateTrigger={[onSubmit]}
      valuePropName="checked"
      rules={[
        {
          required: true,
          message: (
            <FormErrorMessage
              message="To continue, you must agree to Terms and Conditions."
              style={{ width: "100%", marginTop: "10px" }}
            />
          ),
        },
      ]}
    >
      <div className={styles.termsCheckbox}>
        <Checkbox
          style={{
            lineHeight: 1.5,
          }}
          onChange={handleCheckBox}
        />
        <Text className={theme === "dark" ? styles.termsAndConditionsDark : styles.termsAndConditionsLight}>
          Terms and Conditions: I agree to the{" "}
          <a {...openLinkInNewTab(tos.agreement?.props?.document_path)}>
            {" "}
            <span className={styles.bold}>Powur Seller Policies and Procedures</span>
          </a>
        </Text>
      </div>
    </Form.Item>
  );
};

export default TermsOfService;

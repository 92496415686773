import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* updateProjectManagementUsers(action) {
  const { projectId, newUserId, userParamKey } = action.payload;
  const KEYS = {
    Seller: "agent_id",
    Tier3SalesPartner: "mentor_id",
    Ambassador: "referrer_id",
    Installer: "installer_id",
    Manager: "coordinator_id",
    LeadGenerator: "referrer_id",
  };

  if (!KEYS[userParamKey]) {
    throw new Error(`Specified parameter ${userParamKey} is not a valid project parameter that can be updated.`);
  }

  const { success, data, error } = yield* makeRequest.patch(`/projects/${projectId}`, {
    [KEYS[userParamKey]]: newUserId,
  });
  if (success && data) {
    const out = camelizeKeys(_.clone(data.body));
    yield all([
      put(actions.setAdminProjectDetails(out)),
      put(actions.setAdminProjectsList({ project: out })),
      put(actions.newSuccessEvent({ message: messages.PROJECT_UPDATE_SUCCESS })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PROJECT_CONSULTANT,
    });
  }
  return null;
}

export default updateProjectManagementUsers;

import React from "react";
import { Box, Container, Fade, ModalUnstyledOwnProps } from "@mui/material";
import CloseButton from "components/Buttons/CloseButton";
import { WarningIcon } from "components/Icons";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from "./styles";

type AlertModalT = {
  icon?: JSX.Element;
  defaultIcon?: boolean;
  children: React.ReactNode;
  title?: React.ReactNode | EmotionJSX.Element;
  visible: boolean;
  footer?: React.ReactNode;
  hideHeader?: boolean;
  onClose?: () => void;
} & Omit<ModalUnstyledOwnProps, "open" | "children">;

export const AlertModal = ({
  icon,
  children,
  title,
  visible,
  onClose = () => null,
  footer,
  hideHeader = false,
  defaultIcon = false,
  ...rest
}: AlertModalT) => {
  return (
    <ModalContainer closeAfterTransition {...rest} open={visible}>
      <Fade in={visible}>
        <ModalBody>
          {!hideHeader && <ModalHeader disableTypography title={title} />}
          <Container>
            {(defaultIcon || icon) && (
              <Box sx={{ mb: 3, mt: 10 }}>
                {defaultIcon && (
                  <WarningIcon width={80} height={80} startFill="var(--dark-blue)" stopFill="var(--dark-blue)" />
                )}
                {icon}
              </Box>
            )}

            {children}
          </Container>
          <ModalFooter>{footer || <CloseButton buttonTypeStyle="gradient" action={onClose} />}</ModalFooter>
        </ModalBody>
      </Fade>
    </ModalContainer>
  );
};

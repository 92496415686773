import React, { useEffect, useMemo, useState } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LicenseInfo } from "@mui/x-license-pro";
import AirbrakeErrorBoundary from "components/ErrorBoundary";
import HomeFallback from "components/ErrorBoundary/components/Home";
import LoadingScreen from "components/LoadingElements/LoadingScreen";
import { parse } from "query-string";
import { theme } from "styles/themes/index";
import { useEffectOnce } from "react-use";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ApiClient from "./util/ApiClient";
import initI18n from "./i18n";
import SessionClient from "./util/SessionClient";
import SettingsClient from "./util/SettingsClient";
import SessionUser from "./models/SessionUser";
import RootRouter from "./containers/router";
import { store, browserHistory } from "./rdx/configureStore";
import { configureColorVars } from "./styles/colorVariables";

import "./styles/global.css";

const Start = () => {
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [i18nInitialized, setI18nInitialized] = useState(false);

  const [locale, setLocale] = useState("enUS");
  const themeWithLocale = useMemo(() => createTheme(theme, locales[locale]), [locale]);
  const session = new SessionClient();
  const userLocale = session?.user?.props?.locale;
  const api = new ApiClient();

  useEffect(() => {
    const systemLocales = {
      en: "enUS",
      es: "esES",
    };
    setLocale(systemLocales[userLocale] || "enUS");
  }, [userLocale]);

  LicenseInfo.setLicenseKey(
    "87d1537bc0cfe21a33de8f1a72269403Tz04MjA1MCxFPTE3MzY2MzM5NjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
  );

  useEffectOnce(() => {
    (async () => {
      /* Conditionally import ResizeObserver polyfill to prevent consultant dashboard
       * crashing on Safari versions 11-14. In the future, if we need to add more
       * conditional polyfills like this, may look into custom polyfill bundling
       * via polyfill.io to keep this init hook clean.
       */
      if ("ResizeObserver" in window === false) {
        const module = await import("@juggle/resize-observer");
        window.ResizeObserver = module.ResizeObserver;
      }
    })();

    configureColorVars();

    api.get("/settings", {}).then((res) => {
      new SettingsClient().setSettings(res);
      setSettingsLoaded(true);
    });

    if (session.adminJwt) {
      new SessionUser({}).reload().then(() => {
        session.clearAdmin();
        setSessionLoaded(true);
      });
    } else if (session.user) {
      session.user.reload().finally(() => {
        if (session.user?.props?.locale) {
          localStorage.setItem("i18nextLng", session.user.props.locale);
        }
        setSessionLoaded(true);
      });
    } else {
      setSessionLoaded(true);
    }

    setInterval(() => {
      if (session.user) {
        session.user.reload();
      }
    }, 600000); // refresh jwt every 10 minutes for notification and inbox counts updates.

    if (session.validSession && api.isSso()) {
      api.ssoLogin();
    }

    initI18n().then(() => {
      setI18nInitialized(true);
    });
  });

  const ssoForm = () => (
    <form action={ApiClient.defaults.host + ApiClient.defaults.urls.sso} method="POST" id="sso_form">
      <input type="hidden" name="Authorization" value={session.jwt} id="sso_jwt" />
      <input type="hidden" name="RelayState" value={parse(window.location.search).ReturnUrl} id="sso_relay_state" />
      <input type="hidden" name="route" value={parse(window.location.search).sso} id="sso_route" />
    </form>
  );

  if (!settingsLoaded || !sessionLoaded || !i18nInitialized) {
    return (
      <>
        <LoadingScreen />
        {ssoForm()}
      </>
    );
  }

  const description = "Powur: Accelerating the adoption of sustainable energy worldwide.";

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Provider store={store}>
        {ssoForm()}
        <Router history={browserHistory}>
          <AirbrakeErrorBoundary fallbackComponent={<HomeFallback />}>
            <Helmet>
              <meta name="description" content={description} />
              <meta name="og:description" content={description} />
            </Helmet>
            <RootRouter />
          </AirbrakeErrorBoundary>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default Start;

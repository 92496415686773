import React from "react";
import PropTypes from "prop-types";
import { CloseCircleFilled } from "@ant-design/icons";
import { Alert } from "antd";

const FormErrorMessage = (props) => {
  const style = {
    width: "100%",
    fontWeight: 400,
    fontStyle: "italic",
    backgroundColor: "var(--monarch-red)",
    ...props.style,
  };
  return (
    <Alert
      style={style}
      message={<span style={{ color: "white" }}>{props.message}</span>}
      showIcon={true}
      type="error"
      icon={<CloseCircleFilled />}
    />
  );
};

FormErrorMessage.propTypes = {
  message: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

export default FormErrorMessage;

export default {
  GET_REMINDER_NOTIFICATIONS: "[platform] GET reminder notifications",

  SET_REMINDER_NOTIFICATIONS: "[platform] SET reminder notifications",

  TOGGLE_REMINDER_NOTIFICATION_VIEWED_REQUEST: "[platform] Toggle reminder notification viewed request",

  TOGGLE_REMINDER_NOTIFICATION_VIEWED: "[platform] Toggle reminder notification viewed",

  GET_REMINDER_NOTIFICATIONS_COUNT: "[platform] GET reminder notifications count",

  SET_REMINDER_NOTIFICATIONS_COUNT: "[platform] SET reminder notifications count",

  SET_LEAD_TO_PROPOSAL_DRAWER_VISIBLE: "[platform] SET Add Project Lead Drawer Visible",

  SET_TEAM_LEAD_DRAWER_VISIBLE: "[platform] SET Team Lead Drawer Visible",

  SET_PROFILE_MENTORSHIP_TAB_VISIBLE: "[platform] SET Profile Mentorship Tab Visible",

  SET_LEAD_TO_PROPOSAL_DRAWER_OPEN: "[platform] SET Lead to Proposal Drawer Open",

  SET_LEAD_TO_PROPOSAL_DRAWER_LEAD_ID: "[platform] SET Lead to Proposal Drawer Lead Id",

  SET_LEAD_TO_PROPOSAL_DRAWER_PROJECT_ID: "[platform] SET Lead to Proposal Drawer Project Id",

  SET_LEAD_TO_PROPOSAL_DRAWER_PROPOSAL_TYPE: "[platform] SET Lead to Proposal Drawer Proposal Type",

  CLEAR_LEAD_TO_PROPOSAL_DRAWER_IDS: "[platform] Clear Lead to Proposal Drawer Ids",

  SET_PROFILE_ORGANIZATION_TAB_VISIBLE: "[platform] Set Organization Tab Visible",

  GET_PLATFORM_SERVICES: "[platform] GET Platform Services",

  SET_PLATFORM_SERVICES: "[platform] SET Platform Services",

  SET_ACTIVATE_ACCOUNT_MODAL_VISIBLE: "[platform] SET Activate Account Modal Visible",

  SET_INBOX_NOTIFICATIONS: "[platform] SET Inbox Notifications",

  GET_INBOX_NOTIFICATIONS: "[platform] GET Inbox Notifications",

  SET_BACKGROUND_LINES_URL: "[platform] SET Background Lines URL",
  SET_OVERFLOW: "[platform] SET overflow",

  SET_INVITE_DRAWER_VISIBLE: "[platform] SET Invite Drawer Visible",

  SET_HOMEOWNER_NOTES_DRAWER_VISIBLE: "[platform] SET Homeowner Notes Drawer Visible",
  SET_HOMEOWNER_NOTES_LEAD_ID: "[platform] SET Homeowner Notes Lead Id",
  CLEAR_HOMEOWNER_NOTES_LEAD_ID: "[platform] Clear Homeowner Notes Drawer Id",
  SET_BANNERS_HEIGHT: "[platform] SET Banners Height",

  SET_INTEGRITY_TRAINING_MODAL_VISIBLE: "[platform] SET Integrity Training Modal Visible",

  SET_PROPOSAL_REQUEST_DRAWER_VISIBLE: "[platform] SET Proposal Request Drawer Visible",
  SET_PROPOSAL_REQUEST_DRAWER_ID: "[platform] SET Proposal Request Drawer Id",
  CLEAR_PROPOSAL_REQUEST_DRAWER_ID: "[platform] Clear Proposal Request Drawer Id",

  SET_ALERT_MESSAGE_VISIBLE: "[platform] SET Alert Message",
  CLEAR_ALERT_MESSAGE: "[platform] Clear Alert Message",

  SET_PROJECT_MINI_HEADER_ACTIVE: "[platform] SET Project Scrolling Mini Header Active",
  SET_PROJECT_CLOSED_BANNER_ACTIVE: "[platform] SET Project Closed Banner Active",
  // INSERTION_PT (for script, do not remove)
};

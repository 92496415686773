import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import styles from "../../Join.module.less";

const ContentRow = (props) => {
  const { header, subHeader, paragraph, imageLink } = props;

  const image = (
    <div className={styles.imageContainer}>
      <div className={styles.image} style={{ backgroundImage: `url(${imageLink})` }} />
    </div>
  );

  const text = (
    <div className={styles.textContainerOuter}>
      <div className={styles.angledAccentBottom} />
      <div className={styles.textContainer}>
        <Row className={styles.textRow}>
          <Col>
            <div className={styles.contentHeader}>{header}</div>
            <div className={styles.underline} />
          </Col>
        </Row>
        <Row className={styles.textRow}>
          <Col>
            <div className={styles.contentSubHeader}>{subHeader}</div>
          </Col>
        </Row>
        <Row className={styles.textRow}>
          <Col>
            <p className={styles.bodyText}>{paragraph}</p>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <Row className={styles.section}>
      <>
        {image}
        {text}
      </>
    </Row>
  );
};

ContentRow.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  paragraph: PropTypes.string,
  imageLink: PropTypes.string,
};

export default ContentRow;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import languages from "./languages";

import enAmbassador from "./locales/en/ambassador.json";
import enCareers from "./locales/en/careers.json";
import enDashboardVideos from "./locales/en/dashboard_videos.json";
import enLicenses from "./locales/en/licenses.json";
import enFAQ from "./locales/en/faq.json";
import enFooter from "./locales/en/footer.json";
import enFormLabels from "./locales/en/form_labels.json";
import enHome from "./locales/en/home.json";
import enJoin from "./locales/en/join.json";
import enAccountTypes from "./locales/en/account_types.json";
import enLogin from "./locales/en/login.json";
import enTeam from "./locales/en/team.json";
import enTranslation from "./locales/en/translation.json";
import enWhysolar from "./locales/en/whysolar.json";
import enEnterpriseJoin from "./locales/en/enterprise_join.json";
import enEnterpriseSellerJoin from "./locales/en/enterprise_seller_join.json";
import enEnterpriseJoinFormLabels from "./locales/en/ent_join_form_labels.json";

import esAmbassador from "./locales/es/ambassador.json";
import esCareers from "./locales/es/careers.json";
import esDashboardVideos from "./locales/es/dashboard_videos.json";
import esLicenses from "./locales/es/licenses.json";
import esFAQ from "./locales/es/faq.json";
import esFooter from "./locales/es/footer.json";
import esFormLabels from "./locales/es/form_labels.json";
import esHome from "./locales/es/home.json";
import esJoin from "./locales/es/join.json";
import esAccountTypes from "./locales/es/account_types.json";
import esLogin from "./locales/es/login.json";
import esTeam from "./locales/es/team.json";
import esTranslation from "./locales/es/translation.json";
import esWhysolar from "./locales/es/whysolar.json";
import esEnterpriseJoin from "./locales/es/enterprise_join.json";
import esEnterpriseSellerJoin from "./locales/es/enterprise_seller_join.json";
import esEnterpriseJoinFormLabels from "./locales/es/ent_join_form_labels.json";

const lngs = Object.keys(languages);

const resources = {
  en: {
    ambassador: enAmbassador,
    careers: enCareers,
    dashboard_videos: enDashboardVideos,
    licenses: enLicenses,
    faq: enFAQ,
    footer: enFooter,
    form_labels: enFormLabels,
    home: enHome,
    join: enJoin,
    account_types: enAccountTypes,
    login: enLogin,
    team: enTeam,
    whysolar: enWhysolar,
    translation: enTranslation,
    enterprise_join: enEnterpriseJoin,
    ent_join_form_labels: enEnterpriseJoinFormLabels,
    enterprise_seller_join: enEnterpriseSellerJoin,
  },
  es: {
    ambassador: esAmbassador,
    careers: esCareers,
    dashboard_videos: esDashboardVideos,
    licenses: esLicenses,
    faq: esFAQ,
    footer: esFooter,
    form_labels: esFormLabels,
    home: esHome,
    join: esJoin,
    account_types: esAccountTypes,
    login: esLogin,
    team: esTeam,
    whysolar: esWhysolar,
    translation: esTranslation,
    enterprise_join: esEnterpriseJoin,
    enterprise_seller_join: esEnterpriseSellerJoin,
    ent_join_form_labels: esEnterpriseJoinFormLabels,
  },
};

const options = {
  // https://www.i18next.com/overview/configuration-options
  resources,
  fallbackLng: lngs[0],
  // debug: process.env.NODE_ENV === "development",
  supportedLngs: lngs,
  interpolation: {
    escapeValue: false,
  },
  react: {
    // https://react.i18next.com/latest/i18next-instance
    useSuspense: false,
  },
  detection: {
    order: ["querystring", "localStorage", "navigator", "cookie", "sessionStorage", "htmlTag", "path"],
  },
};

export default (lng) => {
  return new Promise((resolve, reject) => {
    if (i18n.isInitialized) {
      resolve(i18n);
    } else {
      i18n.use(Backend).use(initReactI18next).use(LanguageDetector);

      if (lng && lngs.includes(lng)) {
        options.lng = lng;
      }

      i18n
        .init(options)
        .then(() => resolve(i18n))
        .catch(reject);
    }
  });
};

export { default as languages } from "./languages";

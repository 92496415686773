import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import useModalControls from "hooks/useModalControls";
import VideoModal from "components/Modals/VideoModal";
import styles from "../../FAQ.module.less";

const VideoBanner = () => {
  const { t } = useTranslation(["faq", "form_labels"]);
  const { visible, open, close } = useModalControls();

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.inner}>
        <div className={styles.videoContainer} />
        <div className={styles.textContainer}>
          <div className={styles.inner}>
            <span className={styles.bannerText}>{t("banner.powur_solar_faq")}</span>
            <span className={styles.bannerSubtext}>{t("banner.learn_the_answers")}</span>
            <Button className={styles.bannerButton} onClick={open} type="primary">
              {t("form_labels:start_watching")}
            </Button>
          </div>
        </div>
        <VideoModal
          visible={visible}
          open={open}
          close={close}
          videoSourceUrl="https://powur-public.s3-us-west-1.amazonaws.com/assets/videos/MASTERCLASS_0_WELCOME_V2.mp4"
        />
      </div>
    </div>
  );
};

// visible, close, videoSourceUrl, modalProps

export default VideoBanner;

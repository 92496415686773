import React, { useRef, useEffect } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";
import { browserHistory } from "rdx/configureStore";
import SettingsClient from "util/SettingsClient";
import ReactGA from "react-ga";
import WindowDimensionProvider from "containers/router/WindowDimensionProvider";

import routes from "containers/router/routes";

import loadable from "@loadable/component";
import { SettingsContext } from "context/SettingsContext";

const AmbassadorRouter = loadable(async () => {
  const r = await import("./routers/Ambassador");
  return r;
});

const GeneralRouter = loadable(async () => {
  const r = await import("./routers/General");
  return r;
});

const PlatformRouter = loadable(async () => {
  const r = await import("./routers/Platform");
  return r;
});

const PersonalRouter = loadable(async () => {
  const r = await import("./routers/Personal");
  return r;
});

const { generalRoutes, ambassadorRoutes, personalRoutes, platformRoutes, home } = routes;

const getMatchingPaths = (r) => {
  return r.map(({ path }) => path);
};

const RootRouter = () => {
  const settings = new SettingsClient();
  const location = useLocation();
  const gaRef = useRef();

  useEffect(() => {
    const debug =
      process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS_LOGGING === "true" && process.env.REACT_APP_ENV !== "development";
    if (settings.data.google_analytics_code && !gaRef.current) {
      ReactGA.initialize(settings.data.google_analytics_code, {
        debug,
      });
      gaRef.current = ReactGA.ga;
    }
  }, [settings.data.google_analytics_code]);

  useEffect(() => {
    if (gaRef.current) {
      ReactGA.pageview(location.pathname + location.search + location.hash);
    }
  }, [location.pathname, location.search, location.hash]);

  return (
    <Router history={browserHistory}>
      <WindowDimensionProvider>
        <SettingsContext.Provider value={settings.data}>
          <Switch>
            <Route exact path={getMatchingPaths(generalRoutes)} component={GeneralRouter} />
            <Route exact path={getMatchingPaths(personalRoutes)} component={PersonalRouter} />
            <Route exact path={getMatchingPaths(ambassadorRoutes)} component={AmbassadorRouter} />
            <Route exact path={getMatchingPaths(platformRoutes)} component={PlatformRouter} />
            <Route {...home} />
          </Switch>
        </SettingsContext.Provider>
      </WindowDimensionProvider>
    </Router>
  );
};

export default RootRouter;

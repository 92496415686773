import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase, keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* selectMentor(action) {
  const { id, mentorId, mentorAlgorithmUsed } = action.payload;

  const params = keysToSnakeCase({ mentorId, mentorAlgorithmUsed });

  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${id}`, params);

  if (success && data) {
    yield all([
      put(actions.setProjectDetails({ providerLead: keysToCamelCase(data.body) })),
      put(actions.newSuccessEvent({ message: messages.SELECT_MENTOR_SUCCESS })),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_SELECTING_MENTOR });
  }
  return null;
}

export default selectMentor;

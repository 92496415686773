import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { SquareIcon } from "components/Icons";
import { PrimaryButton } from "components/mui-styled-components";
import { AccountDetailsT, EnterpriseAppStatusT } from "../types";

import * as S from "./styles";

type AccountTypeCardPropsT = {
  accountDetails: AccountDetailsT;
  handleClick: (acct: AccountDetailsT) => void;
  viewAll: boolean;
  setViewAll: (bool: boolean) => void;
  enterpriseAppStatus: EnterpriseAppStatusT;
  noCurrentUser: boolean;
};

type EnterpriseButtonTextT =
  | "pricing_and_plans.button_text_loading"
  | "pricing_and_plans.button_text_pending"
  | "pricing_and_plans.button_text_rejected"
  | "pricing_and_plans.button_text_join"
  | "pricing_and_plans.tiers.powur_enterprise.button_text_external"
  | "pricing_and_plans.tiers.powur_enterprise_pro.button_text";

const AccountTypeCard = ({
  accountDetails,
  handleClick,
  viewAll,
  setViewAll,
  enterpriseAppStatus,
  noCurrentUser,
}: AccountTypeCardPropsT) => {
  const { t } = useTranslation(["account_types"]);
  const location = useLocation();
  const [enterpriseButtonText, setEnterpriseButtonText] = useState<EnterpriseButtonTextT>(
    "pricing_and_plans.button_text_loading",
  );

  const viewingStaticPage = location.pathname === "/enterprise" || location.pathname === "/join";

  const createButtonText = (): string => {
    if (accountDetails.slug === "powur_enterprise_pro") {
      return t(enterpriseButtonText);
    }
    if (viewingStaticPage) {
      return t("pricing_and_plans.button_text_join_external");
    }
    if (noCurrentUser) {
      return t("pricing_and_plans.button_text_join");
    }
    return accountDetails.buttonText;
  };

  const disableButtonTextOptions = [
    t("pricing_and_plans.button_text_pending"),
    t("pricing_and_plans.button_text_rejected"),
  ];

  const isButtonDisabled = disableButtonTextOptions.includes(createButtonText());

  useEffect(() => {
    if (enterpriseAppStatus === "pending") {
      setEnterpriseButtonText("pricing_and_plans.button_text_pending");
    } else if (enterpriseAppStatus === "rejected") {
      setEnterpriseButtonText("pricing_and_plans.button_text_rejected");
    } else if (enterpriseAppStatus === "approved") {
      setEnterpriseButtonText("pricing_and_plans.button_text_join");
    } else if (viewingStaticPage) {
      setEnterpriseButtonText("pricing_and_plans.tiers.powur_enterprise.button_text_external");
    } else {
      setEnterpriseButtonText("pricing_and_plans.tiers.powur_enterprise_pro.button_text");
    }
  }, [enterpriseAppStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const bulletPoint = (item: string): React.ReactNode => (
    <S.BulletPointContainer key={item}>
      <div>
        <SquareIcon fill={accountDetails.color} />
      </div>
      <S.BulletPointText>{item}</S.BulletPointText>
    </S.BulletPointContainer>
  );

  const handleButtonClick = () => {
    handleClick(accountDetails);
  };

  return (
    <S.AcctTypeCardContainer key={accountDetails.key}>
      <S.HeaderContainer color={accountDetails.color}>{accountDetails.type}</S.HeaderContainer>
      <S.AcctTypeCard color={accountDetails.color}>
        <S.PlanDescription>
          <div>
            <S.PlanSubheader>{accountDetails.description}</S.PlanSubheader>
            <S.FlexContainer>
              <S.Divider color={accountDetails.color} />
            </S.FlexContainer>
            <S.BenefitsListContainer>
              {accountDetails.list.slice(0, 9).map((item) => {
                return bulletPoint(item);
              })}
              <Collapse in={viewAll} timeout="auto" unmountOnExit>
                {accountDetails.list.slice(9).map((item) => {
                  return bulletPoint(item);
                })}
              </Collapse>
            </S.BenefitsListContainer>
          </div>
          {accountDetails.list.length > 9 && (
            <S.ClickableViewMoreText color={viewAll ? "var(--nepal-blue)" : accountDetails.color}>
              <span
                tabIndex={0}
                role="button"
                onClick={() => setViewAll(!viewAll)}
                onKeyDown={() => setViewAll(!viewAll)}
              >
                {viewAll ? (t("pricing_and_plans.view_less") as string) : (t("pricing_and_plans.view_more") as string)}
              </span>
            </S.ClickableViewMoreText>
          )}
        </S.PlanDescription>
        <S.CardFooter>
          <S.PriceContainer>
            <div>
              <S.PriceText fontSize="44px">${accountDetails.monthly}</S.PriceText>
              <S.PriceText fontSize="18px">/{t("pricing_and_plans.month") as string}</S.PriceText>
            </div>
            <div>
              <S.PriceText fontSize="14px">
                {!!accountDetails.fee && `+$${accountDetails.fee} ${t("pricing_and_plans.setup_fee")}`}
              </S.PriceText>
            </div>
          </S.PriceContainer>
          <S.ButtonContainer>
            <PrimaryButton
              gradient
              stopColor={accountDetails.color}
              startColor={accountDetails.secondaryColor}
              onClick={handleButtonClick}
              style={{ width: "230px", height: "45px", textTransform: "capitalize" }}
              disabled={isButtonDisabled}
            >
              {createButtonText()}
            </PrimaryButton>
          </S.ButtonContainer>
        </S.CardFooter>
      </S.AcctTypeCard>
    </S.AcctTypeCardContainer>
  );
};

export default AccountTypeCard;

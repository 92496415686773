export default {
  USERS_READ: "USERS_READ",
  USERS_UPDATE: "USERS_UPDATE",
  USER_IMPERSONATE: "USER_IMPERSONATE",
  PLATFORM_CONFIGURATION: "PLATFORM_CONFIGURATION",
  CAREERS_UPDATES: "CAREERS_UPDATES",
  STATE_LICENSES_UPDATES: "STATE_LICENSES_UPDATES",
  CAN_SUBMIT_PROPOSAL: "CAN_SUBMIT_PROPOSAL",
  CAN_CREATE_PROJECT: "CAN_CREATE_PROJECT",
  PROJECT_MANAGEMENT: "PROJECT_MANAGEMENT",
  POWUR_AMBASSADOR: "POWUR_AMBASSADOR",
  AMBASSADOR_CREATE: "AMBASSADOR_CREATE",
  PROPOSAL_MANAGEMENT: "PROPOSAL_MANAGEMENT",
  ENTERPRISE_ADMIN: "ENTERPRISE_ADMIN",
  ENTERPRISE_ADMIN_PRO: "ENTERPRISE_ADMIN_PRO",
  ENTERPRISE_SUPPORT: "ENTERPRISE_SUPPORT",
  PORTAL_ACCESS: "PORTAL_ACCESS",
  OPERATIONS_MANAGEMENT: "OPERATIONS_MANAGEMENT",
  PLATFORM_COMMUNICATIONS: "PLATFORM_COMMUNICATIONS",
  PREVIEW_USER: "PREVIEW_USER",
  SERVICE_AREA_UPDATES: "SERVICE_AREA_UPDATES",
  FINANCE_MANAGEMENT: "FINANCE_MANAGEMENT",
  FINANCE_MANAGEMENT_READ_ONLY: "FINANCE_MANAGEMENT_READ_ONLY",
  PROJECT_USER_UPDATES: "PROJECT_USER_UPDATES",
  CREATE_OFFERS: "CREATE_OFFERS",
  ADMIN_DASHBOARD_VIEW: "ADMIN_DASHBOARD_VIEW",
  TICKETS_VIEW: "TICKETS_VIEW",
  PROJECTS_VIEW: "PROJECTS_VIEW",
  MARKETING_VIEW: "MARKETING_VIEW",
  EARNINGS_VIEW: "EARNINGS_VIEW",
  REPORTS_VIEW: "REPORTS_VIEW",
  TEAM_VIEW: "TEAM_VIEW",
  SALES_DASHBOARD_VIEW: "SALES_DASHBOARD_VIEW",
  ENTERPRISE_LEAD_GENERATOR: "ENTERPRISE_LEAD_GENERATOR",
  ENTERPRISES_SUPER_ADMIN: "ENTERPRISES_SUPER_ADMIN",
  VIEW_FINANCIALS: "VIEW_FINANCIALS",
  SITE_SURVEYOR: "SITE_SURVEYOR",
  LOGGED_ACTIONS: "LOGGED_ACTIONS",
  HAS_VISION_ACCESS: "HAS_VISION_ACCESS",
  VISION_ADMIN: "VISION_ADMIN",
  ROOF_SUBMIT: "ROOF_SUBMIT",
  ROOF_DESIGN_TEAM: "ROOF_DESIGN_TEAM",
  ROOF_DESIGN_ADMIN: "ROOF_DESIGN_ADMIN",
  CAN_REQUEST_VISION_SELF_DESIGN: "CAN_REQUEST_VISION_SELF_DESIGN",
  DEVELOPER: "DEVELOPER",
  ENTERPRISE_EARNINGS_VIEW: "ENTERPRISE_EARNINGS_VIEW",
  SUNNOVA_CERTIFIED: "SUNNOVA_CERTIFIED",
  SUNNOVA_DESIGN_TEAM: "SUNNOVA_DESIGN_TEAM",
  NEVADA_SELLER: "NEVADA_SELLER",
  RSM_AUTHORITY: "RSM_AUTHORITY",
  VISION_SERIVCE_BUREAU_ADMIN: "VISION_SERIVCE_BUREAU_ADMIN",
  COBRANDING_ACCESS: "COBRANDING_ACCESS",
  ENTERPRISE_CUSTOM_FIELDS_ACCESS: "ENTERPRISE_CUSTOM_FIELDS_ACCESS",
} as const;

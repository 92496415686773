import React from "react";

import type { PaperProps } from "@mui/material";
import type { SerializedStyles } from "@emotion/react";

import SubHeaderFilled from "components/PlatformLayout/components/SubHeaderFilled";
import Spinner from "components/LoadingElements/Spinner";

import * as S from "./styles";

type SubHeaderFilledPropsT = {
  title?: string;
  subTitle?: string;
  sideText?: React.ReactNode;
  containerStyles?: Record<string, string | null> | null;
  titleStyles?: Record<string, string | null> | null;
  sideTextStyles?: Record<string, string | null> | null;
  titleContainerStyles?: Record<string, string | null> | null;
};

type BasicCardPropsT = PaperProps & {
  headerProps?: SubHeaderFilledPropsT;
  refElement?: React.ReactNode; // for auto scrolling location
  cardStyleOverrides?: SerializedStyles | SerializedStyles[] | undefined;
  subSectionContent?: React.ReactNode;
  subSectionStyles?: SerializedStyles | SerializedStyles[] | undefined;
  loading?: boolean;
};

const BasicCard = ({
  children,
  headerProps,
  cardStyleOverrides = undefined,
  loading,
  refElement,
  subSectionContent,
  subSectionStyles,
  ...rest
}: BasicCardPropsT) => {
  return (
    <>
      {refElement}
      {headerProps?.title && <SubHeaderFilled {...headerProps} />}
      {subSectionContent && <S.SubSection css={subSectionStyles}>{subSectionContent}</S.SubSection>}
      <S.StyledPaper css={cardStyleOverrides} header={Boolean(headerProps)} {...rest}>
        {loading && <Spinner loading noMask />}
        {children}
      </S.StyledPaper>
    </>
  );
};

export type { BasicCardPropsT };

export default BasicCard;

import React, { useEffect, useState, useRef, useMemo } from "react";

import { useEffectOnce, useLocation } from "react-use";
import { Autocomplete, TextField, Box } from "@mui/material";

import useDebounce from "hooks/useDebounce";

import Spinner from "components/LoadingElements/Spinner";
import { MagnifyingGlassIcon } from "components/Icons";

import { isArray } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import useLoading from "hooks/useLoading";
import selectors from "rdx/selectors";
import types from "rdx/types";
import actions from "rdx/actions";

import { theme } from "styles/themes";

import { setSeparator } from "lib/helpers/setSeparator";
import { LookupRow } from "./LookupRow";
import { isGeneric, isLocation, isUser } from "./guards";
import { Avatar } from "./Avatar";
import OutsideTags from "./OutsideTags";
import type { CustomStylesT, LookUpUnionT } from "./types";
import { lookupEndpoint } from "./enpoints";

import * as S from "./styles";

type AutoCompletePropsT<T> = {
  optionsSelector: string;
  loadingTrigger: keyof typeof types;
  lookUpType?: "user" | "leadOwner" | "leadSourceName" | "tier3" | "team" | "installer" | "utility" | "location";
  setAutoCompleteOptionsAction: string;
  getAutoCompleteOptionsAction: string;
  defaultValue?: T | T[] | undefined | null;
  handleInputChange?: (id: number | string | null) => void;
  handleNewInputChange?: (selectedOptions: T[] | null) => void; // temp solution
  handleDeleteSelectedOption?: (id: number | string) => void;
  customStyles?: CustomStylesT;
  disabled?: boolean;
  disableAvatar?: boolean;
  selectedOutside?: boolean;
  multiple?: boolean;
  width?: string;
};

export const AutoComplete = <T extends Record<string, unknown>>({
  loadingTrigger,
  lookUpType = "user",
  setAutoCompleteOptionsAction,
  getAutoCompleteOptionsAction,
  handleInputChange,
  handleNewInputChange,
  handleDeleteSelectedOption,
  customStyles,
  defaultValue,
  disabled,
  selectedOutside = false,
  multiple = false,
  disableAvatar,
  optionsSelector,
  width,
}: AutoCompletePropsT<T>) => {
  const { search } = useLocation();
  const [localOptions, setLocalOptions] = useState<T[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [lookupLabel, setLookupLabel] = useState("Look Up...");
  const [selectedOption, setSelectedOption] = useState<T | undefined | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<T[] | undefined | null>([]);
  const [inputFocused, setInputFocused] = useState(false);
  const debouncedSearch: string | number = useDebounce(searchInput, 500);

  const firstRender = useRef(true);
  const options = useSelector<unknown, T[]>(selectors[optionsSelector]);

  const autocompleteLoading = useLoading({
    watchRequests: [types[loadingTrigger]],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (search === "") setSelectedOptions([]);
  }, [search]);

  useEffect(() => {
    if (isArray(defaultValue)) {
      setSelectedOptions(defaultValue || []);
    } else {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (inputFocused && options?.length > 0) {
      const ids = [
        ...(selectedOptions ? selectedOptions.map((el) => el.id) : []),
        ...(selectedOption ? [selectedOption.id] : []),
      ];
      setLocalOptions(options.filter((option) => !ids.includes(option.id)));
    }
  }, [options, inputFocused, selectedOptions, selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      setLookupLabel("");
    }
  }, [selectedOption]);

  useEffect(() => {
    const out = debouncedSearch !== "" ? debouncedSearch.toString() : null;
    const approvedLength = debouncedSearch.toString().length > 2 || out === null;
    const readyToSearch = !firstRender.current && approvedLength;

    if (getAutoCompleteOptionsAction && readyToSearch) {
      dispatch(actions[getAutoCompleteOptionsAction](lookupEndpoint[lookUpType](out)));
    }
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffectOnce(() => {
    firstRender.current = false;
  });

  const handleClose = () => {
    if (setAutoCompleteOptionsAction) {
      dispatch(actions[setAutoCompleteOptionsAction]([]));
    }
  };

  const onInputChange = (value: T | T[] | null, type: string) => {
    setLocalOptions([]);
    if (!isArray(value)) {
      const val = value?.id as number | undefined;
      const out = val || null;

      if (handleInputChange) handleInputChange(out as number);

      setSelectedOption(value);
      setLookupLabel("");
    } else {
      setSelectedOptions(value || []);
      setLookupLabel("Look Up...");
      if (handleInputChange)
        handleInputChange(value.map((el) => el.id).join(setSeparator(type, "location", "-")) as string);
      if (handleNewInputChange) handleNewInputChange(value);
    }

    setSearchInput("");
  };

  const handleRemoveFromOptions = (id: number | string) => {
    if (selectedOptions) {
      onInputChange(
        selectedOptions.filter((el) => el.id !== id),
        lookUpType,
      );
    }
  };

  const getOptionLabel = (option: Record<string, unknown>): string => {
    if (option && isUser(option)) return option.fullName;
    if (option && isLocation(option)) return `${option.city}, ${option.state}`;
    if (option && isGeneric(option)) return option.name;
    return "None";
  };

  const noOptionsText = useMemo(() => {
    const invalidSearch = !inputFocused || searchInput.length < 3;

    const searchMatchesSelected =
      selectedOption &&
      isUser(selectedOption) &&
      selectedOption?.fullName.toLowerCase().includes(searchInput.toLowerCase());

    if (invalidSearch || searchMatchesSelected) return " ";

    return "No Results";
  }, [inputFocused, selectedOption, searchInput]);

  const popperOpen = inputFocused && searchInput.length > 2;

  return (
    <Box width={width && width}>
      <S.Container>
        <S.SideBox disabled={disabled} sx={customStyles?.prefix}>
          {selectedOption && isUser(selectedOption) ? ( // if isUser returns true than type for selectedOption will be narrowed down to UserLookupT
            lookUpType && <Avatar type={lookUpType} avatarContent={selectedOption.avatarUrls.thumb} />
          ) : (
            <MagnifyingGlassIcon
              fill={customStyles?.prefix ? theme.colors["blue-ribbon"] : theme.colors["dark-periwinkle"]}
            />
          )}
        </S.SideBox>
        <Autocomplete
          disabled={disabled}
          fullWidth={!!width}
          size="small"
          blurOnSelect
          open={popperOpen}
          defaultValue={defaultValue}
          multiple={multiple}
          getOptionLabel={getOptionLabel}
          options={localOptions}
          {...(selectedOutside ? { inputValue: searchInput } : {})}
          filterOptions={(x) => x}
          value={multiple ? selectedOptions || [] : selectedOption}
          filterSelectedOptions
          disableClearable={selectedOutside}
          loading={autocompleteLoading}
          onChange={(event, value, reason) => {
            let finalValue = value;
            if (reason === "selectOption" && isArray(value)) {
              finalValue = value.filter((val, indx, arr) => indx === arr.findIndex((el) => el.id === val.id));
              onInputChange(finalValue as T | T[] | null, lookUpType);
            }

            if (!selectedOutside && !multiple) onInputChange(finalValue as T | T[] | null, lookUpType);
          }}
          openOnFocus={false}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          onClose={handleClose}
          loadingText={
            <div style={{ position: "relative", minHeight: "60px" }}>
              <Spinner loading noMask size="middle" />
            </div>
          }
          noOptionsText={noOptionsText}
          PopperComponent={popperOpen ? S.StyledPopper : undefined}
          popupIcon={false}
          sx={{ ...S.autocompleteStyles, ...(customStyles?.general ? customStyles.general : {}) }}
          {...(selectedOutside ? { renderTags: () => null } : {})}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                onChange={(e) => setSearchInput(e.target.value)}
                label={lookupLabel}
                sx={
                  customStyles?.input || {
                    "& .MuiAutocomplete-input": {
                      color: theme.colors["dark-blue"],
                    },
                  }
                }
              />
            );
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 }, display: "flex", alignItems: "center", width: "100%" }}
              {...props}
            >
              <LookupRow disableAvatar={disableAvatar} type={lookUpType} option={option as LookUpUnionT} />
            </Box>
          )}
        />
      </S.Container>
      {selectedOutside && selectedOptions && selectedOptions.length > 0 && (
        <OutsideTags
          customStyles={customStyles?.selectedTags}
          disableAvatar={disableAvatar}
          handleRemove={handleDeleteSelectedOption || handleRemoveFromOptions}
          type={lookUpType}
          selectedOptions={selectedOptions}
        />
      )}
    </Box>
  );
};

export default AutoComplete;

import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";
import { filterStyledProps } from "lib/helpers/filterStyledProps";

type RowPropsT = {
  gap: number;
};

type ContainerPropsT = {
  hasBackground: boolean;
};

export const AcctTypesCardsContainer = styled("div", { shouldForwardProp: filterStyledProps(["hasBackground"]) })(
  ({ hasBackground }: ContainerPropsT) =>
    () => ({
      background: theme.colors.white,
      padding: hasBackground ? "25px" : "50px",
      borderRadius: "10px",
      ...(hasBackground && {
        margin: "50px",
        width: "fit-content",
        backgroundImage: "url('/img/background-lines-top-right-3.png')",
        backgroundSize: "350px",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
      }),
    }),
);

export const AcctTypesContent = styled("div")(() => () => ({
  display: "flex",
  flexDirection: "column",
}));

export const AcctTypesHeader = styled("div")(() => () => ({
  width: "70%",
  minwidth: "315px",
  marginBottom: "40px",
  marginTop: "20px",
  paddingLeft: "8px",
}));

export const AcctTypesTitle = styled("div")(() => () => ({
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "44px",
  color: theme.colors["blue-ribbon"],
}));

export const AcctTypesHeaderDetails = styled("div")(() => () => ({
  fontWeight: 500,
  color: theme.colors["dark-grey"],
}));

export const AcctTypeCardsRow = styled("div", { shouldForwardProp: filterStyledProps(["gap"]) })(
  ({ gap = 8 }: RowPropsT) =>
    () => ({
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap,
    }),
);

export const AcctTypeCardsCol = styled("div")(() => () => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 0 auto",
}));

import { styled } from "@mui/material/styles";

export const SelectSponsorCard = styled("div")(() => ({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.colors.white,
  borderRadius: "12px",
  boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
  width: "352px",
}));

export const SponsorNameSection = styled("div")(() => ({
  padding: "22px",
  display: "flex",
  alignItems: "center",
}));

export const SponsorContactSection = styled("div")(() => ({
  padding: "22px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export const ButtonSection = styled("div")(() => ({
  padding: "16px 24px 18px",
  display: "flex",
  justifyContent: "center",
}));

export const Avatar = styled("div")(() => ({
  marginRight: "16px",
}));

export const HeaderContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "100%",
}));

export const EnterpriseInvite = styled("div")(({ theme }) => ({
  color: theme.colors.eggplant,
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "Poppins",
}));

export const SponsorName = styled("div")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "17px",
  color: theme.colors["darkest-blue"],
}));

export const SponsorAddress = styled("div")(({ theme }) => ({
  color: theme.colors["blue-bayoux"],
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "17px",
}));

export const LargeLabel = styled("div")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "21px",
  fontWeight: 600,
  color: theme.colors["darkest-blue"],
}));

export const SmallLabel = styled("div")(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "11px",
  marginBottom: "8px",
  fontWeight: 600,
  color: theme.colors["nepal-blue"],
  textTransform: "uppercase",
}));

export const ContactText = styled("div")(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  color: theme.colors["blue-ribbon"],
  height: "24px",
}));

export const Divider = styled("div")(({ theme }) => ({
  width: "100%",
  height: 1,
  background: theme.colors["botticelli-blue"],
}));

export function isNotNull<T>(data: T): data is T {
  return data !== null;
}

export function isUndefined<T>(data: T | undefined): data is undefined {
  return data === undefined;
}

export function isNull<T>(data: T | null): data is null {
  return data === null;
}

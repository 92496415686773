import React from "react";

import { css } from "@emotion/react";

import { isUser, isLocation, isGeneric, isTeam } from "./guards";
import { UserLookupT, GenericLookupT, LocationLookUpT } from "./types";

import * as S from "./styles";
import { Avatar } from "./Avatar";

type UserLookupRowT = {
  option: UserLookupT | GenericLookupT | LocationLookUpT;
  type: "user" | "leadOwner" | "leadSourceName" | "tier3" | "location" | "utility" | "team" | "installer";
  disableAvatar?: boolean;
};

export const LookupRow = ({ option, type, disableAvatar }: UserLookupRowT) => {
  const handleAvatarContent = () => {
    if (isUser(option)) return option.avatarUrls.thumb;
    if (isTeam(option)) return option.shortName;
    return null;
  };

  return (
    <S.LookUpRowWrapper>
      {!disableAvatar && (
        <S.LookUpRowContainer>
          <Avatar avatarContent={handleAvatarContent()} type={type} />
        </S.LookUpRowContainer>
      )}

      <span
        css={css`
          white-space: nowrap;
        `}
      >
        {isUser(option) && option.fullName}
        {isLocation(option) && option.id}
        {isGeneric(option) && option.name}
      </span>
    </S.LookUpRowWrapper>
  );
};

export type { UserLookupT };

import z from "zod";

export const CrmActivityTypeSchema = z.union([
  z.literal("call"),
  z.literal("email"),
  z.literal("meeting"),
  z.literal("reminder"),
  z.literal("blocker"),
]);

export const ActivityLogDataTypeSchema = z.union([
  z.literal("activity"),
  z.literal("milestone"),
  z.literal("stuck"),
  z.literal("buildable_as_sold"),
  z.literal("blocker"),
  z.literal("status"),
]);

export const ProjectActivityCrmSchema = z.object({
  id: z.number(),
  activityDate: z.string(),
  canDelete: z.boolean(),
  completedAt: z.nullable(z.string()),
  completeType: z.nullable(z.string()),
  dueDate: z.string(),
  leadId: z.number(),
  milestone: z.string().optional(),
  name: CrmActivityTypeSchema,
  notes: z.string().optional(),
  reason: z.string().optional(),
  statusDisplay: z.string().optional(),
  statusName: z.string().optional(),
  title: z.string(),
  type: z.literal("activity"),
});

export const ProjectActivityBlockerSchema = z.object({
  id: z.number(),
  activityDate: z.string(),
  description: z.string(),
  title: z.string(),
  status: z.union([z.literal("complete"), z.literal("deferred")]),
  type: z.literal("blocker"),
  coBlockerActivity: z.boolean(),
});

export const ProjectActivityStatusSchema = z.object({
  activityDate: z.string(),
  status: z.union([z.literal("active"), z.literal("closed")]),
  type: z.literal("status"),
});

export const ProjectActivityBasSchema = z.object({
  activityDate: z.string(),
  type: z.literal("buildable_as_sold"),
});

export const ProjectActivityStuckSchema = z.object({
  activityDate: z.string(),
  milestone: z.string().nullable(),
  reason: z.string(),
  statusDisplay: z.string(),
  statusName: z.string(),
  type: z.literal("stuck"),
});

export const MilestoneNameSchema = z.union([
  z.literal("new"),
  z.literal("qualified"),
  z.literal("proposal requested"),
  z.literal("proposal generated"),
  z.literal("proposal presented"),
  z.literal("sale complete"),
  z.literal("project intake"),
  z.literal("site survey scheduled"),
  z.literal("design complete"),
  z.literal("permit approved"),
  z.literal("install scheduled"),
  z.literal("final inspection scheduled"),
  z.literal("pto application submitted"),
  z.literal("permission to operate"),
  z.literal("powur on!"),
]);

export const ProjectActivityMilestoneSchema = z.object({
  activityDate: z.string(),
  name: MilestoneNameSchema,
  type: z.literal("milestone"),
});

export const ActivityLogDataSchema = z.union([
  ProjectActivityMilestoneSchema,
  ProjectActivityStuckSchema,
  ProjectActivityCrmSchema,
  ProjectActivityBlockerSchema,
  ProjectActivityBasSchema,
]);

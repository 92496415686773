import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/enrollment/messages";

import actions from "rdx/actions";

function* upgradeEnrollment(action) {
  const { payload } = action;

  const params = {
    ...payload.params,
  };
  const { success, data, error } = yield* makeRequest.post(`/services/${payload.service}/enrollment/upgrade`, params);
  if (success && data) {
    yield all([
      put(actions.setEnrollments(data.body.enrollment)),
      put(
        actions.newSuccessEvent({
          message: messages.ENROLLMENT_UPGRADE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPGRADING_ENROLLMENT,
    });
  }
  return null;
}

export default upgradeEnrollment;

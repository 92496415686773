import React from "react";
import PropTypes from "prop-types";

const upDownIcon = (props) => {
  const { accentColor, mainColor, height, width, containerStyles } = props;

  return (
    <div style={{ paddingTop: "1px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.59456 15.4382C4.79413 15.7147 5.20587 15.7147 5.40544 15.4382L8.75812 10.7926C8.99677 10.4619 8.76048 10 8.35267 10H1.64733C1.23952 10 1.00324 10.4619 1.24188 10.7926L4.59456 15.4382Z"
          fill={mainColor}
        />
        <rect x="4.5" y="1" width="1" height="11" rx="0.5" fill={mainColor} />
        <path
          d="M12.4054 1.3118C12.2059 1.03527 11.7941 1.03527 11.5946 1.3118L8.24188 5.9574C8.00324 6.28808 8.23952 6.75 8.64733 6.75L15.3527 6.75C15.7605 6.75 15.9968 6.28808 15.7581 5.9574L12.4054 1.3118Z"
          fill={accentColor}
        />
        <rect x="12.5" y="15.75" width="1" height="11" rx="0.5" transform="rotate(180 12.5 15.75)" fill={accentColor} />
      </svg>
    </div>
  );
};

upDownIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  accentColor: PropTypes.string,
};

upDownIcon.defaultProps = {
  accentColor: "var(--botticelli-blue)",
  mainColor: "var(--dark-blue)",
  height: "16",
  width: "17",
};

export default upDownIcon;

import type { LeadGenPreSalesProject, Homeowner } from "rdx/modules/enterpriseProjects/types";
import { LeadT } from "../../../types/Lead";

type FormatLeadGenLead = Omit<Partial<LeadGenPreSalesProject>, "homeowner"> & { homeowner: Partial<Homeowner> };

export default function formatLeadGenLead(leadData: LeadT): FormatLeadGenLead {
  const avatarData = {
    large: null,
    preview: null,
    thumb: null,
    retina: null,
  };
  return {
    id: leadData.id.toString(),
    projectStageMilestoneName: null,
    projectStageName: null,
    projectStageScore: 100,
    projectStageId: null,
    projectStageKind: null,
    tpUpdatedAt: null,
    coalescedUpdatedAt: leadData.updatedAt,
    leadQualificationPercent: leadData.qualificationPercent,
    homeowner: {
      firstName: leadData.firstName,
      lastName: leadData.lastName,
      email: leadData.email,
      phone: leadData.phone,
      address: leadData.address,
    },
    projectUsers: [
      {
        ...leadData.owner,
        role: "Lead Owner",
        avatar: avatarData,
      },
      { ...leadData.referrer, role: "lead_generator", avatar: avatarData },
    ],
    activities: [],
  };
}

import * as _ from "lodash";
import { keysToCamelCase, keysToSnakeCase } from "lib/helpers";
import PropTypes from "prop-types";
import actions from "rdx/modules/users/actions";
import StorageClient from "util/StorageClient";
import { store } from "rdx/configureStore";
import { Base } from "./Base";
import { Enrollment } from "./Enrollment";

const {
  impersonateUser,
  addRoleToUser,
  removeRoleFromUser,
  changeUserSponsor,
  changeUserManager,
  changeUserCertificationDate,
  updateUser,
  setUserAsComped,
  activateUserEnrollment,
  readdUserToLms,
  cancelSubscriptionByAdmin,
  renewSubscriptionByAdmin,
  destroySubscriptionByAdmin,
  getLitmosLoginLink,
  banUser,
  unbanUser,
  certifyUser,
} = actions;

const storage = new StorageClient();
const { defaultService } = Enrollment;
export default class User extends Base {
  static genders = { male: 0, female: 1, non_binary: 2 };
  static maritalStatuses = { married: 0, single: 1 };

  static fromForm(values) {
    return new User(_.mapKeys(values, (v, k) => _.snakeCase(k)));
  }

  constructor(props, resKey = "user") {
    if (!props || _.isEmpty(props)) super(defaults, resKey);
    else super(keysToCamelCase(props), resKey);
  }

  fullName() {
    return `${this.props.firstName} ${this.props.lastName}`;
  }

  patch() {
    return this.api.patch("/profile", this.preparedForRequest()).then((res) => {
      this.props = res.body;
      return res;
    });
  }

  get() {
    return this.api.get("/jwt", {}, { no401redirect: true }).then(this.resToProps);
  }

  setupEwallet() {
    return this.api.post("/profile/ewallet").then(this.resToProps);
  }

  preparedForRequest = () => {
    const propsCopy = keysToSnakeCase(this.props);
    const { address } = propsCopy;
    delete propsCopy.address;
    delete propsCopy.enrollment;
    propsCopy.gender = User.genders[propsCopy.gender];
    propsCopy.marital_status = User.maritalStatuses[propsCopy.marital_status];
    return { user: propsCopy, address };
  };

  impersonate() {
    storage.setStorage({ tickets: {} }, "inboxFilters");
    storage.setStorage({ projects: {} }, "projectFilters");
    store.dispatch(impersonateUser({ userId: this.props.id }));
  }

  updateUser({ qualifiedUntil, sellerForceRank, sellerForceRankExpiration }) {
    store.dispatch(
      updateUser({
        userId: this.props.id,
        qualifiedUntil,
        sellerForceRank,
        sellerForceRankExpiration,
      }),
    );
  }

  addRole(roleId) {
    store.dispatch(addRoleToUser({ userId: this.props.id, roleId }));
  }

  removeRole(roleId) {
    store.dispatch(removeRoleFromUser({ userId: this.props.id, roleId }));
  }

  changeSponsor(sponsorId) {
    store.dispatch(changeUserSponsor({ userId: this.props.id, sponsorId }));
  }

  changeManager(managerId) {
    store.dispatch(changeUserManager({ userId: this.props.id, managerId }));
  }

  changeCertificationDate(certifiedAt, service = defaultService) {
    const params = this.enrollmentParams(service);
    params.certifiedAt = certifiedAt;

    store.dispatch(changeUserCertificationDate(params));
  }

  compUser(service = defaultService) {
    store.dispatch(setUserAsComped(this.enrollmentParams(service)));
  }

  activateEnrollment(service = defaultService) {
    store.dispatch(activateUserEnrollment(this.enrollmentParams(service)));
  }

  certifyUser(seatId) {
    store.dispatch(certifyUser({ userId: this.props.id, seatId }));
  }

  readdToLms(service = defaultService) {
    store.dispatch(readdUserToLms(this.enrollmentParams(service)));
  }

  getLitmosLoginLink(path) {
    store.dispatch(getLitmosLoginLink({ userId: this.props.id, path, locale: this.props.locale }));
  }

  cancelByAdmin(service = defaultService) {
    store.dispatch(cancelSubscriptionByAdmin(this.enrollmentParams(service)));
  }

  renewByAdmin(service = defaultService) {
    store.dispatch(renewSubscriptionByAdmin(this.enrollmentParams(service)));
  }

  destroyByAdmin(service = defaultService) {
    store.dispatch(destroySubscriptionByAdmin(this.enrollmentParams(service)));
  }

  enrollmentParams(service) {
    return { userId: this.props.id, serviceSlug: service };
  }

  banUser() {
    store.dispatch(banUser({ userId: this.props.id }));
  }

  unbanUser() {
    store.dispatch(unbanUser({ userId: this.props.id }));
  }

  requiredIntegrityModules() {
    return this.props.integrityModules || [];
  }

  /* prop types */

  static avatarTypes = {
    large: PropTypes.string,
    preview: PropTypes.string,
    retina: PropTypes.string,
    thumb: PropTypes.string,
  };

  static userTypes = {
    address: PropTypes.shape({
      street: PropTypes.string,
      unit: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      latitude: PropTypes.number,
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      longitude: PropTypes.number,
      status: PropTypes.string,
      googleMapsUrl: PropTypes.string,
    }),
    areasServiced: PropTypes.arrayOf(PropTypes.string),
    avatar: PropTypes.shape(this.avatarTypes),
    createdAt: PropTypes.string,
    dob: PropTypes.string,
    downlineLevel: PropTypes.number,
    email: PropTypes.string,
    emailHash: PropTypes.string,
    enrollment: PropTypes.shape({
      certifiedAt: PropTypes.string,
      endDay: PropTypes.string,
      id: PropTypes.number,
      paymentStatus: PropTypes.string,
      startDay: PropTypes.string,
      status: PropTypes.string,
    }),
    ewalletUsername: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    integrityModules: PropTypes.arrayOf(PropTypes.string),
    ipayoutAddress: PropTypes.string,
    lastActivityAt: PropTypes.string,
    lastName: PropTypes.string,
    level: PropTypes.number,
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        expires: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        id: PropTypes.number,
        identifier: PropTypes.string,
        name: PropTypes.string,
        requiresExpiration: PropTypes.bool,
      }),
    ),
    locale: PropTypes.string,
    manualKindSelected: PropTypes.bool,
    metrics: PropTypes.oneOfType([
      PropTypes.shape({
        contractCount: PropTypes.number,
        downlevelCount: PropTypes.number,
        installCount: PropTypes.number,
        leadCount: PropTypes.number,
        mentorContractCount: PropTypes.number,
        mentorInstallCount: PropTypes.number,
        mentorProjectCount: PropTypes.number,
        mentorQualifyCount: PropTypes.number,
        projectCount: PropTypes.number,
        qualifyCount: PropTypes.number,
        recruitCount: PropTypes.number,
        referrerContractCount: PropTypes.number,
        referrerInstallCount: PropTypes.number,
        referrerProjectCount: PropTypes.number,
        referrerQualifyCount: PropTypes.number,
        teamCount: PropTypes.number,
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          eventCount: PropTypes.number,
        }),
      ),
    ]),
    phone: PropTypes.string,
    powurPairData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    rank: PropTypes.number,
    relatedLevel: PropTypes.number,
    roles: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          description: PropTypes.string,
          parentId: PropTypes.number,
          member: PropTypes.bool,
        }),
      ),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    sponsor: PropTypes.shape({
      avatar: PropTypes.shape({
        large: PropTypes.string,
        preview: PropTypes.string,
        retina: PropTypes.string,
        thumb: PropTypes.string,
      }),
      email: PropTypes.string,
      firstName: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.number,
      lastName: PropTypes.string,
      locale: PropTypes.string,
      phone: PropTypes.string,
      subscriptionStatus: PropTypes.string,
      vanityName: PropTypes.string,
    }),
    sponsors: PropTypes.arrayOf(PropTypes.number),
    subscriptionStatus: PropTypes.string,
    upline: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.arrayOf(
        PropTypes.shape({
          avatar: PropTypes.shape({
            large: PropTypes.string,
            preview: PropTypes.string,
            retina: PropTypes.string,
            thumb: PropTypes.string,
          }),
          email: PropTypes.string,
          firstName: PropTypes.string,
          fullName: PropTypes.string,
          id: PropTypes.number,
          lastName: PropTypes.string,
          phone: PropTypes.string,
          subscriptionStatus: PropTypes.string,
          uplineLevel: PropTypes.number,
          vanityName: PropTypes.string,
        }),
      ),
    ]),
    uplineLevel: PropTypes.number,
    terminatedAt: PropTypes.string,
    vanityName: PropTypes.string,
  };

  static types() {
    return PropTypes.shape(this.userTypes);
  }

  static enterpriseSettings = {
    autoOfferAcceptance: PropTypes.bool,
    sellerInvitesAllowed: PropTypes.bool,
    recognitionEmails: PropTypes.bool,
    compensationEmails: PropTypes.bool,
    eventEmails: PropTypes.bool,
    promotionEmails: PropTypes.bool,
    newsletterEmails: PropTypes.bool,
    platformEmails: PropTypes.bool,
    trainingEmails: PropTypes.bool,
    accountAndTeamEmails: PropTypes.bool,
    leadGeneratorAmount: PropTypes.number,
    minimumMargin: PropTypes.number,
    minimumMargins: PropTypes.shape({
      ambassador: PropTypes.number,
      leadGenerator: PropTypes.number,
      noAdditiona: PropTypes.number,
    }),
    offerPercentage: PropTypes.string,
    payForProposals: PropTypes.bool,
  };

  static enterpriseTypes() {
    return PropTypes.shape({
      ...this.userTypes,
      seated: PropTypes.bool,
      salesPro: PropTypes.bool,
      currentStaffServicesRoleId: PropTypes.number,
      seatAssignments: PropTypes.arrayOf(
        PropTypes.shape({
          role: PropTypes.string,
          status: PropTypes.string,
          start: PropTypes.string,
          end: PropTypes.string,
          org: PropTypes.string,
        }),
      ),
      staffServices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string,
          description: PropTypes.string,
          fee: PropTypes.number,
          currency: PropTypes.string,
          enabled: PropTypes.bool,
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
          startAt: PropTypes.string,
          endAt: PropTypes.string,
          monthly: PropTypes.number,
          requiresEducation: PropTypes.bool,
          parentId: PropTypes.number,
          seatOfId: PropTypes.number,
          upgradeId: PropTypes.number,
          assignable: PropTypes.bool,
          unique: PropTypes.bool,
          groupData: PropTypes.shape({
            enterpriseCategory: PropTypes.string,
          }),
        }),
      ),
      enterpriseSettings: PropTypes.shape(this.enterpriseSettings),
    });
  }

  static seatTypes() {
    return PropTypes.shape({
      assignments: PropTypes.arrayOf(PropTypes.shape({ serviceId: PropTypes.number })),
      enterpriseSettings: PropTypes.shape(this.enterpriseSettings),
      mergedSettings: PropTypes.shape(this.enterpriseSettings),
      user: PropTypes.shape({
        avatar: PropTypes.shape(this.avatarTypes),
        email: PropTypes.string,
        firstName: PropTypes.string,
        fullName: PropTypes.string,
        id: PropTypes.number,
        lastName: PropTypes.string,
        locale: PropTypes.string,
        phone: PropTypes.string,
        terminatedAt: PropTypes.string,
        vanityName: PropTypes.string,
      }),
    });
  }

  static filterTypes() {
    return PropTypes.shape({
      levels: PropTypes.number,
      metrics: PropTypes.arrayOf(PropTypes.string),
      ranks: PropTypes.number,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          id: PropTypes.number,
          parentId: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      // serviceAreas and states objects consist of a single key-value pair where the key is the state value and the value is the display string for the state e.g. { AK,USA: "Alaska" }
      serviceAreas: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      states: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      statuses: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
      certifications: PropTypes.arrayOf(PropTypes.string),
    });
  }
}

export const defaults = {
  address: {
    street: undefined,
    unit: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    country: undefined,
    lat: undefined,
    latitude: undefined,
    long: undefined,
    longitutde: undefined,
    status: undefined,
    googleMapsUrl: undefined,
  },
  avatar: {
    large: undefined,
    preview: undefined,
    retina: undefined,
    thumb: undefined,
  },
  certified: undefined,
  displayRoles: [],
  downlineLevel: undefined,
  email: undefined,
  enrollment: {
    certifiedAt: undefined,
    endDay: undefined,
    id: undefined,
    paymentStatus: undefined,
    startDay: undefined,
    status: undefined,
  },
  ewalletUsername: undefined,
  firstName: undefined || "",
  fullName: undefined,
  id: 0,
  integrityModules: [],
  ipayoutAddress: undefined,
  lastName: undefined || "",
  level: undefined,
  locale: undefined,
  metrics: [],
  phone: undefined,
  rank: undefined,
  relatedLevel: undefined,
  roles: [],
  subscriptionStatus: undefined,
  upline: false,
  uplineLevel: undefined,
  vanityName: undefined,
};

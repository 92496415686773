import { styled } from "@mui/material/styles";
import { filterStyledProps } from "lib/helpers/filterStyledProps";

type TextPropsT = {
  fontSize: string;
};

export const AcctTypeCardContainer = styled("div")(() => () => ({
  display: "flex",
  flexDirection: "column",
  margin: "15px 8px 20px",
  alignItems: "center",
  height: "100%",
}));

export const HeaderContainer = styled("div")(({ color }) => () => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Poppins",
  fontSize: "30px",
  fontWeight: 500,
  lineHeight: "48px",
  marginBottom: "15px",
  color,
}));

export const AcctTypeCard = styled("div")(({ color }) => () => ({
  width: "315px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  border: `2px solid ${color}`,
  borderRadius: "10px",
  padding: "20px",
  marginBottom: "20px",
}));

export const PlanDescription = styled("div")(() => () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: 1,
}));

export const PlanSubheader = styled("div")(({ theme }) => () => ({
  lineHeight: "20px",
  margin: "0px 4px",
  height: "80px",
  color: theme.colors["body-font"],
}));

export const Divider = styled("div")(({ color }) => ({
  background: color,
  height: "2px",
  width: "160px",
  margin: "24px 0px",
}));

export const FlexContainer = styled("div")(() => () => ({
  display: "flex",
  justifyContent: "center",
}));

export const BenefitsListContainer = styled("div")(({ theme }) => () => ({
  color: theme.colors["body-font"],
  paddingInlineStart: "15px",
}));

export const BulletPointContainer = styled("div")(() => () => ({
  display: "flex",
  marginBottom: "15px",
}));

export const BulletPointText = styled("div")(() => () => ({
  marginLeft: "12px",
}));

export const ClickableViewMoreText = styled("div")(({ color }) => () => ({
  textAlign: "center",
  fontWeight: "600",
  cursor: "pointer",
  marginTop: "-20px",
  marginBottom: "10px",
  color,
}));

export const CardFooter = styled("div")(() => () => ({
  marginBottom: "15px",
}));

export const PriceContainer = styled("div")(({ theme }) => () => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontFamily: "Poppins",
  fontWeight: 600,
  height: "90px",
  color: theme.colors["darkest-blue"],
}));

export const PriceText = styled("span", { shouldForwardProp: filterStyledProps(["fontSize"]) })(
  ({ fontSize }: TextPropsT) =>
    () => ({
      fontSize,
    }),
);

export const ButtonContainer = styled("span")(() => () => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
}));

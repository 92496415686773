import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/customFields/types";

import getCustomFields from "./getCustomFields";
import createCustomField from "./createCustomField";
import updateCustomField from "./updateCustomField";
import deleteCustomField from "./deleteCustomField";

// IMPORT_PT (for script, do not remove)

function* watchCustomFieldsSagas() {
  yield trackRequests(takeEvery, types.GET_CUSTOM_FIELDS, getCustomFields);

  yield trackRequests(takeEvery, types.CREATE_CUSTOM_FIELD, createCustomField);

  yield trackRequests(takeEvery, types.UPDATE_CUSTOM_FIELD, updateCustomField);

  yield trackRequests(takeEvery, types.DELETE_CUSTOM_FIELD, deleteCustomField);

  // INSERTION_PT (for script, do not remove)
}

export default watchCustomFieldsSagas;

export default {
  selectEnterprisePersonnel: (state) => state.enterprisePersonnel.usersList,
  selectEnterprisePersonnelFilters: (state) => state.enterprisePersonnel.filters,
  selectAssignableServices: (state) => state.enterprisePersonnel.services,
  selectEnterpriseUserDetails: (state) => state.enterprisePersonnel.userDetails,
  selectPendingSeat: (state) => state.enterprisePersonnel.pendingSeat,
  selectPendingSeatOfferData: (state) => state.enterprisePersonnel.pendingSeatOfferData,
  selectEnterpriseSeatDetails: (state) => state.enterprisePersonnel.seatDetails,
  selectEnterpriseSeatChangePreview: (state) => state.enterprisePersonnel.enterpriseSeatChangePreview,
};

const roleTypes = {
  ambassador: "Ambassador",
  enterprise_owner: "Enterprise Owner",
  lead_closer: "Lead Closer",
  lead_generator: "Lead Generator",
  lead_owner: "Lead Owner",
  upline: "Level 1-?",
  mentored_consultant_upline: "Level 1-? - Pair Owner",
  mentor_upline: "Level 1-? - Pair Closer",
  transfer_upline: "Level 1-? - Lead Transfer",
  mentor: "Pair Closer",
  mentored_consultant: "Pair Owner",
  seated_seller: "Seated Seller",
  consultant: "Seller",
};

export default roleTypes;

import React from "react";
import PropTypes from "prop-types";

const LogoutIcon = (props) => {
  const { height, width, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.44787 9.98768C0.833639 9.98768 0.402174 9.49336 0.500088 8.89028C0.500088 8.89028 0.428448 9.24427 0.501808 8.88171C1.52709 3.81465 6.00557 0 11.375 0C17.5019 0 22.4688 4.96684 22.4687 11.0937C22.4687 17.2207 17.5019 22.1875 11.375 22.1875C6.01154 22.1875 1.53705 18.3813 0.505241 13.3227C0.430204 12.9548 0.499935 13.2962 0.499935 13.2962C0.409248 12.6909 0.834131 12.2001 1.4479 12.2001L11.9348 12.2001C12.3437 12.2001 12.4392 12.4345 12.1484 12.7234L10.1311 14.7322C9.08536 15.7753 10.6544 17.3395 11.7002 16.2967L16.2249 11.8496C16.6624 11.4212 16.6616 10.7288 16.2197 10.2998L11.7002 5.89072C10.6544 4.84792 9.08536 6.41249 10.1311 7.4553L12.1484 9.46431C12.4393 9.75336 12.342 9.98768 11.9348 9.98768L1.44787 9.98768Z"
        fill={fill}
      />
    </svg>
  );
};

LogoutIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LogoutIcon.defaultProps = {
  fill: "var(--royal-peacock-blue)",
  width: "23",
  height: "23",
};

export default LogoutIcon;

import actions from "rdx/modules/enrollment/actions";
import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import ApiClient from "../util/ApiClient";

const {
  getUpgradePreview,
  upgradeEnrollment,
  downgradeEnrollment,
  renewEnrollment,
  cancelEnrollment,
  subscribeEnrollment,
} = actions;
export class Enrollment {
  constructor(props) {
    this.props = props;
  }

  static sources = {
    card: "credit_card",
  };

  static defaultService = "powur_energy";

  static displayNames = {
    powur_energy: "Powur Energy",
    powur_live: "Powur Live",
    powur_enterprise: "Powur Enterprise",
  };

  static post(tokenResponse = {}, tos = null, service = this.defaultService) {
    const { id: token, type: source } = tokenResponse;

    store.dispatch(
      subscribeEnrollment({
        service,
        token,
        tos,
        sourceType: Enrollment.sources[source],
      }),
    );
  }

  static upgradePreview(service = this.defaultService, to_service) {
    const params = {
      preview: true,
      to_service,
    };
    store.dispatch(getUpgradePreview({ service, params }));
  }

  static upgrade(service = this.defaultService, params) {
    store.dispatch(upgradeEnrollment({ service, params }));
  }

  update(tokenResponse, tos, service = Enrollment.defaultService) {
    const { id: token, type: source } = tokenResponse;
    const params = {
      source_type: Enrollment.sources[source],
      token,
      tos,
    };

    const api = new ApiClient();

    return new Promise((resolve, reject) => {
      api.patch(`/services/${service}/enrollment`, params).then(
        (res) => resolve(new Enrollment(res.body.enrollment)),
        (res) => reject(res),
      );
    });
  }

  cancel(service = Enrollment.defaultService) {
    store.dispatch(
      cancelEnrollment({
        service,
      }),
    );
  }

  renew(service = Enrollment.defaultService) {
    store.dispatch(
      renewEnrollment({
        service,
      }),
    );
  }

  downgrade(toService = "powur_energy") {
    store.dispatch(
      downgradeEnrollment({
        service: this.props.slug,
        params: {
          to_service: toService,
        },
      }),
    );
  }

  static enrollmentTypes = {};

  static types() {
    return PropTypes.shape(this.enrollmentTypes);
  }
}

export default Enrollment;

import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useLocation } from "react-router";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import actionTypes from "rdx/modules/users/types";
import useNavigation from "hooks/useNavigation";
import useWindowResize from "hooks/useWindowResize";
import User from "models/User";
import Router from "models/Router";
import DownloadCSV from "../DownloadCSVReport";
// import DownloadCSVContest from "../DownloadCSVContestReport";
import UsersListSidebar from "./userssidebar";
import AddPermissionModal from "../PermissionsTab/addPermission";
import styles from "../../Users.module.less";

const Sidebar = (props) => {
  const { route, isCollapsed, setIsCollapsed, setIsHidden, filters, activeRequests, addNewPermission } = props;

  const { tab } = useParams();
  const { search } = useLocation();

  const { isTabletWidth } = useWindowResize();

  const [isVisible, setIsVisible] = useState(!isCollapsed);

  const { basePath, requiredParams, optionalParams } = route;

  const { navigate, override } = useNavigation(basePath, requiredParams, optionalParams);

  const tabKeys = useMemo(() => route.tabs.map((t) => t.key), [route.tabs]);

  const loading = useMemo(() => {
    const listRequest = activeRequests.find((activeReq) => actionTypes.GET_USERS_LIST_REQUEST === activeReq.type);
    if (listRequest?.payload?.filters) {
      return true;
    }
    return false;
  }, [activeRequests]);

  useEffect(() => {
    if (tab === tabKeys[0] || tab === tabKeys[3]) {
      setIsCollapsed(false);
      setIsHidden(false);
    } else {
      setIsCollapsed(true);
      setIsHidden(true);
    }
  }, [tab, tabKeys, setIsCollapsed, setIsHidden]);

  useEffect(() => {
    if (isCollapsed) {
      setIsVisible(false);
    } else {
      setTimeout(() => setIsVisible(true), 150);
    }
  }, [isCollapsed]);

  return (
    <div style={{ display: isVisible ? "block" : "none" }} className={styles.sidebar}>
      {(() => {
        switch (tab) {
          case tabKeys[0]:
            return (
              <>
                <UsersListSidebar
                  navigate={navigate}
                  override={override}
                  filters={filters}
                  loading={loading}
                  collapsedView={isTabletWidth}
                />
                <DownloadCSV search={search} />
                {/* <DownloadCSVContest search={search} /> */}
                <div style={{ height: "24px" }} />
              </>
            );
          case tabKeys[3]:
            return <AddPermissionModal addNewPermission={addNewPermission} />;
          default:
            return <></>;
        }
      })()}
    </div>
  );
};

Sidebar.propTypes = {
  route: Router.authRouteTypes(),
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  // isHidden: PropTypes.bool,
  setIsHidden: PropTypes.func,
  filters: User.filterTypes(),
  activeRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      payload: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }),
  ),
  addNewPermission: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    filters: "getUsersFilters",
    activeRequests: "getActiveRequests",
  }),
  getRdxActionMapper(["addNewPermission"]),
)(Sidebar);

import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/enrollment/messages";

import actions from "rdx/actions";

function* subscribeEnrollment(action) {
  const { payload } = action;

  const params = {
    source_type: payload.sourceType,
    ...(payload.token ? { token: payload.token } : {}),
    ...(payload.tos ? { tos: payload.tos } : {}),
  };

  const { success, data, error } = yield* makeRequest.post(`/services/${payload.service}/enrollment`, { ...params });

  if (success && data) {
    yield all([
      put(actions.setEnrollments(data.body.enrollment)),
      put(
        actions.newSuccessEvent({
          message: messages.ENROLLMENT_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ENROLLMENT_ERROR,
    });
  }
  return null;
}

export default subscribeEnrollment;

import React from "react";

import { Layout } from "antd";

import Spinner from "components/LoadingElements/Spinner";
import { HeaderLogoBlue } from "components/Icons";

import styles from "./styles.module.less";

const LoadingScreen = () => (
  <Layout className={styles.layout}>
    <Layout.Content className={styles.content}>
      <div className={styles.container} data-test-id="LoadingScreen">
        <div className={styles.logo}>
          <HeaderLogoBlue width="152" className={styles.logo} />
        </div>
        <Spinner loading />
      </div>
    </Layout.Content>
  </Layout>
);

export default LoadingScreen;

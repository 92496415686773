import types from "rdx/modules/enterpriseProjects/types";
import createAction from "rdx/utils/createAction";

export default {
  // Details
  getEnterpriseProjectDetails: (payload) => createAction(types.GET_ENTERPRISE_PROJECT_DETAILS_REQUEST, payload),

  setEnterpriseProjectDetails: (payload) => createAction(types.SET_ENTERPRISE_PROJECT_DETAILS, payload),

  resetEnterpriseProjectDetails: (payload) => createAction(types.RESET_ENTERPRISE_PROJECT_DETAILS, payload),

  // List
  getEnterpriseProjectsList: (payload) => createAction(types.GET_ENTERPRISE_PROJECTS_LIST_REQUEST, payload),

  setEnterpriseProjectsList: (payload) => createAction(types.SET_ENTERPRISE_PROJECTS_LIST, payload),

  resetEnterpriseProjectsList: (payload) => createAction(types.RESET_ENTERPRISE_PROJECTS_LIST, payload),

  // Filters
  setEnterpriseProjectsFilters: (payload) => createAction(types.SET_ENTERPRISE_PROJECTS_FILTERS, payload),

  setEnterpriseProjectsListAutoComplete: (payload) =>
    createAction(types.SET_ENTERPRISE_PROJECTS_AUTOCOMPLETE_SUGGESTIONS, payload),

  // Seller Search
  getOrgSellers: (payload) => createAction(types.GET_ORG_CONSULTANTS_REQUEST, payload),

  setOrgSellers: (payload) => createAction(types.SET_ORG_CONSULTANTS, payload),

  resetOrgSellers: (payload) => createAction(types.RESET_ORG_CONSULTANTS, payload),

  // Drawers
  setOfferLeadDrawerVisible: (payload) => createAction(types.SET_OFFER_LEAD_DRAWER_VISIBLE, payload),

  setOfferLeadConfirmation: (payload) => createAction(types.SET_OFFER_LEAD_CONFIRMATION, payload),

  resetOfferLeadConfirmation: (payload) => createAction(types.RESET_OFFER_LEAD_CONFIRMATION, payload),

  submitEnterpriseLeadToSeller: (payload) => createAction(types.SUBMIT_ENTERPRISE_LEAD_OFFER_TO_CONSULTANT, payload),

  // Offers
  getEnterpriseOfferLeadGeocode: (payload) => createAction(types.GET_ENTERPRISE_OFFER_LEAD_GEOCODE, payload),

  setEnterpriseOfferLeadGeocode: (payload) => createAction(types.SET_ENTERPRISE_OFFER_LEAD_GEOCODE, payload),

  getEnterpriseLeadOfferDetails: (payload) => createAction(types.GET_ENTERPRISE_LEAD_OFFER_DETAILS, payload),

  setEnterpriseLeadOfferDetails: (payload) => createAction(types.SET_ENTERPRISE_LEAD_OFFER_DETAILS, payload),

  resetEnterpriseLeadOfferDetails: (payload) => createAction(types.RESET_ENTERPRISE_LEAD_OFFER_DETAILS, payload),

  submitSellerOfferLeadDecision: (payload) => createAction(types.SUBMIT_CONSULTANT_OFFER_LEAD_DECISION, payload),

  rescindLeadOffer: (payload) => createAction(types.RESCIND_LEAD_OFFER_REQUEST, payload),

  getLeadOfferResponseDetails: (payload) => createAction(types.GET_LEAD_OFFER_RESPONSE_DETAILS, payload),

  setLeadOfferResponseDetails: (payload) => createAction(types.SET_LEAD_OFFER_RESPONSE_DETAILS, payload),

  resetLeadOfferResponseDetails: (payload) => createAction(types.RESET_LEAD_OFFER_RESPONSE_DETAILS, payload),

  // CSV reports
  startContractsReport: (payload) => createAction(types.START_CONTRACTS_REPORT_REQUEST, payload),

  getContractsReport: (payload) => createAction(types.GET_CONTRACTS_REPORT_REQUEST, payload),

  setContractsReport: (payload) => createAction(types.SET_CONTRACTS_REPORT, payload),

  cancelLeadOffer: (payload) => createAction(types.CANCEL_LEAD_OFFER, payload),

  getEnterpriseProjectLookupOptions: (payload) => createAction(types.GET_ENTERPRISE_PROJECT_LOOKUP_OPTIONS, payload),

  setEnterpriseProjectLookupOptions: (payload) => createAction(types.SET_ENTERPRISE_PROJECT_LOOKUP_OPTIONS, payload),

  toggleM1Advance: (payload) => createAction(types.TOGGLE_M1_ADVANCE, payload),

  setLeadGenPreSalesProjects: (payload) => createAction(types.SET_LEAD_GEN_PROJECTS_PRE_SALES, payload),
  getLeadGenProjectsPreSales: (payload) => createAction(types.GET_LEAD_GEN_PROJECTS_PRE_SALES, payload),
  getLeadGenProjectsPostSales: (payload) => createAction(types.GET_LEAD_GEN_PROJECTS_POST_SALES, payload),
  setLeadGenProjectsPostSales: (payload) => createAction(types.SET_LEAD_GEN_PROJECTS_POST_SALES, payload),
  // INSERTION_PT (for script, do not remove)
};

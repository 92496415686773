import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Row, Button } from "antd";
import useModalControls from "hooks/useModalControls";
import useWindowResize from "hooks/useWindowResize";
import VideoModal from "components/Modals/VideoModal";

import styles from "../../Join.module.less";

const HeroImage = (props) => {
  const { handleCreateAccount } = props;
  const { t } = useTranslation(["join", "form_labels"]);
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

  const { visible, open, close } = useModalControls();
  const videoSource = `${urlPrefix}/assets/videos/Join-ConsultantMontage.mp4`;

  const handleOpen = () => {
    open();
  };

  const { width } = useWindowResize();
  const mobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  return (
    <Row className={styles.heroImage}>
      {mobile ? (
        <div className={styles.background} />
      ) : (
        <div className={styles.videoContainer}>
          <video
            className={styles.video}
            playsInline
            autoPlay
            muted
            loop
            poster={`${urlPrefix}/assets/img/join/powur-consultant_carousel_screenshot.jpg`}
          >
            <source src={`${urlPrefix}/assets/videos/Powur-Consultant_Join_carousel_loop_v1.mp4`} type="video/mp4" />
          </video>
        </div>
      )}
      <div className={styles.heroOverlay}>
        <span className={styles.heroText}>{t("banner.take_your_powur_back")}</span>
        <span className={styles.heroSubText}>{t("banner.best_solar_professionals")}</span>
        <Button
          className={styles.joinButton}
          type="primary"
          block
          onClick={handleCreateAccount}
          data-test-id="joinButton"
        >
          {t("form_labels:join_now")}
        </Button>
        <Button className={styles.watchButton} type="primary" block onClick={handleOpen}>
          {t("form_labels:watch_video")}
        </Button>
      </div>
      <VideoModal
        visible={visible}
        close={close}
        title={t("banner.take_your_powur_back")}
        videoSourceUrl={videoSource}
      />
    </Row>
  );
};

HeroImage.propTypes = {
  handleCreateAccount: PropTypes.func,
};

export default HeroImage;

import z from "zod";

const ServiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  fee: z.number(),
  currency: z.string(),
  enabled: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  startAt: z.string().nullable(),
  endAt: z.string().nullable(),
  monthly: z.number(),
  requiresEducation: z.boolean(),
  parentId: z.number().nullable(),
  unique: z.boolean(),
  groupData: z.string(),
  tags: z.array(z.string()),
  replaceServiceId: z.number().nullable(),
  assignableServiceIds: z.array(z.number()),
});

export const EnrollmentSchema = z.object({
  id: z.number(),
  startDay: z.string(),
  endDay: z.string().nullable(),
  startedAt: z.string(),
  paymentStatus: z.string(),
  service: ServiceSchema,
  downgradeDay: z.string().nullable(),
  inactive: z.boolean().nullable(),
  activeUntil: z.string(),
  currentPeriodStart: z.string().nullish(),
  // enterpriseMembers: z
  //   .array(
  //     UserSchema.merge(
  //       z.object({
  //         service: ServiceSchema,
  //         endAt: z.string().nullable(),
  //       }),
  //     ),
  //   )
  //   .nullish(),
  // enterpriseLiveMembers: z
  //   .array(
  //     UserSchema.merge(
  //       z.object({
  //         service: ServiceSchema,
  //         endAt: z.string().nullable(),
  //       }),
  //     ),
  //   )
  //   .nullish(),
  comped: z.boolean(),
});

import { put } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase, camelizeKeys } from "lib/helpers";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import FEATURE_FLAGS from "util/featureFlags";
import messages from "../messages";
import { ProjectProviderLeadResponse } from "../schemas/projects/post-sales/index";

function* getProviderLeadDetails(action) {
  const { providerLeadId, enterprise, filters } = action.payload;
  const { success, data, error } = yield* makeRequest.get(
    `/provider_leads/${providerLeadId}`,
    Object.assign(enterprise ? { enterprise: true, ...filters } : { ...filters }),
  );

  if (FEATURE_FLAGS.includes("providerLeadSchema")) {
    SchemaValidator.validate({
      schema: ProjectProviderLeadResponse,
      schemaName: "ProjectProviderLeadResponse",
      dto: camelizeKeys(_.clone(data.body)),
    });
  }

  if (success && data) {
    const providerLead = camelizeKeys(_.clone(data.body));
    yield put(
      actions.setProjectDetails({
        providerLead: {
          ...providerLead,

          preferredInstallers: camelizeKeys(_.clone(providerLead.preferredInstallers)),
          projectAtGlance: camelizeKeys({
            ...providerLead.projectAtGlance,
            projectNotifications: camelizeKeys(providerLead.projectAtGlance.projectNotifications),
          }),
        },
        lead: keysToCamelCase(data.body.lead),
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_DETAILS,
    });
  }
  return null;
}

export default getProviderLeadDetails;

import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const paging = {
  page_size: 50,
  page_number: 1,
  item_count: 0,
};

const initialState = {
  leadsList: {
    leads: [],
    paging: {
      pageSize: 50,
      pageNumber: 1,
      itemCount: 0,
    },
    states: [],
    metrics: {},
  },
  teamList: {
    paging,
    users: [],
    filters: {},
  },
  ambassadorTeamList: {
    paging,
    users: [],
    filters: {},
  },
  uplineList: {
    paging,
    users: [],
    filters: {},
  },
  userDetails: {
    user: {},
    recruits: {
      paging,
      users: [],
    },
  },
  editInviteId: undefined,
  inviteDetails: {},
};

export default {
  team: createReducer(initialState, {
    [types.SET_TEAM_LEADS_LIST](state, action) {
      return {
        ...state,
        leadsList: action.payload,
      };
    },
    [types.SET_TEAM_LIST](state, action) {
      return {
        ...state,
        teamList: {
          ...state.teamList,
          ...action.payload,
        },
      };
    },
    [types.SET_TEAM_UPLINE_LIST](state, action) {
      return {
        ...state,
        uplineList: {
          ...state.uplineList,
          ...action.payload,
        },
      };
    },
    [types.SET_AMBASSADOR_LIST](state, action) {
      return {
        ...state,
        ambassadorTeamList: action.payload,
      };
    },
    [types.SET_TEAM_USER_DETAILS](state, action) {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    },
    [types.RESET_TEAM_USER_DETAILS](state) {
      return {
        ...state,
        userDetails: initialState.userDetails,
      };
    },
    [types.SET_CONSULTANTS_AUTOCOMPLETE_SUGGESTIONS](state, action) {
      const { key, suggestions } = action.payload;
      if (key) {
        return {
          ...state,
          consultantsListAutoCompleteSuggestions: {
            ...state.consultantsListAutoCompleteSuggestions,
            [key]: suggestions || [],
          },
        };
      }
      return state;
    },

    [types.SET_EDIT_INVITE_ID](state, action) {
      return {
        ...state,
        editInviteId: action.payload.id,
      };
    },

    [types.SET_INVITE_DETAILS](state, action) {
      return {
        ...state,
        inviteDetails: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};

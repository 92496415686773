import React from "react";
import { useTranslation } from "react-i18next";
import UserCard from "./userCard";
import JBCard from "./JBCard";
import styles from "../../Team.module.less";

const Leadership = () => {
  const { t } = useTranslation("team");

  const userData = [
    {
      name: "Mike Lindmark",
      title: t("body.cfo"),
    },
    {
      name: "Ethan Miller",
      title: t("body.coo"),
    },
    {
      name: "Cami Boehme",
      title: t("body.cmo"),
    },
    {
      name: "Dean Rosenberg",
      title: t("body.cto"),
    },
    {
      name: "Bobby Smith",
      title: t("body.fp"),
    },
    {
      name: "Nick Phillips",
      title: t("body.pco"),
    },
    {
      name: "Kevin Smoker",
      title: t("body.vpco"),
    },
    {
      name: "Chris Bunch",
      title: t("body.vpde"),
    },
    {
      name: "Adam Smith",
      title: t("body.vpb"),
    },
    {
      name: "Brady Anderson",
      title: t("body.sdips"),
    },
    {
      name: "Shannon Joesting",
      title: t("body.dipm"),
    },
    {
      name: "Noah Baieve",
      title: t("body.dit"),
    },
    {
      name: "Daniel DiFiore",
      title: t("body.dcs"),
    },
    {
      name: "Paul Kitchen",
      title: t("body.dsd"),
    },
    {
      name: "Raichail McDonald",
      title: t("body.deasp"),
    },
    {
      name: "Maria DeGuzman",
      title: t("body.dohr"),
    },
    {
      name: "Daniel Howson",
      title: t("body.dokt"),
    },
    {
      name: "Kristin Dennis",
      title: t("body.sdpo"),
    },
    {
      name: "Justin Brooks",
      title: t("body.dop"),
    },
    {
      name: "Beth Robinson",
      title: t("body.docs"),
    },
  ];
  return (
    <div className={styles.leadershipContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.leadBlurbContainer}>
          <p className={styles.leadBlurb}>{t("body.lead_blurb")}</p>
        </div>
        <JBCard />
        <div className={styles.userCardContainer}>
          {userData.map((u) => {
            return <UserCard key={u.name} {...u} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Leadership;

import { css } from "@emotion/react";

export const LearnMoreCardCss = {
  learnMoreCard: css({
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    boxShadow: "0px 4px 12px 0px #00000008",
    border: "none !important",
  }),
  title: css({
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: "600",
    color: "var(--darkest-blue)",
  }),
  text: css({
    fontFamily: "Barlow",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "400",
    color: "var(--table-grey)",
  }),

  learnStageContainer: css({
    marginTop: "15px",
  }),
  subtitle: css({
    fontFamily: "Barlow",
    textTransform: "uppercase",
    color: "var(--hammerhead-grey)",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "17px",
  }),
  next: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    cursor: "pointer",
  }),

  stage: css({
    display: "flex",
    justifyContent: "flex-start",
  }),
  svg: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  stageText: css({
    color: "var(--blue-ribbon)",
    marginLeft: "5px",
    fontWeight: "700",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
  }),
};

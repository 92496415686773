const commonTabs = [
  {
    key: "learn-more",
    label: "Learn More",
  },
  {
    key: "account-types",
    label: "Account Types",
    conditionalRenderSelector: "getCurrentUserEnrollmentStatus",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];

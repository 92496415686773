import z from "zod";
import ZodSchemaUtil from "util/schema-helpers/ZodSchemaUtil";
import { EnrollmentSchema } from "../../enrollment/schemas";

export const AddressSchema = z.object({
  street: z.string().nullable(),
  unit: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  country: z.string().nullable(),
  status: ZodSchemaUtil.makeStringLiteralUnion(["unverified", "verified", "lookup_error", "locked"]),
  lat: z.union([z.number(), z.string()]).nullable(),
  long: z.union([z.number(), z.string()]).nullable(),
  googleMapsUrl: z.string().nullable(),
  geocodePrecision: z.string().nullable(),
  geocodeLat: z.union([z.number(), z.string()]).nullable(),
  geocodeLong: z.union([z.number(), z.string()]).nullable(),
});

export const AvatarSchema = z.object({
  thumb: z.string().nullable(),
  preview: z.string().nullable(),
  large: z.string().nullable(),
  retina: z.string().nullable(),
});

export const UserLiteSchema = z.object({
  id: z.number(),
  fullName: z.string(),
  email: z.string(),
  phone: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  vanityName: z.string(),
  locale: z.string(),
  spokenLanguages: z.array(z.string()),
  terminatedAt: z.string().nullable(),
  bio: z.string().nullable(),
  confReg: z.string().nullable(),
  proposalBio: z.string().nullable(),
  attendedPowurOn: z.boolean(),
  avatar: AvatarSchema,
  sellerAvatar: AvatarSchema,
  seatId: z.number().nullable(),
  enterpriseId: z.number().nullable(),
  enterpriseSellerPercentage: z.number().nullish(),
  enterpriseSellerInvitesAllowed: z.boolean().nullish(),
  enterpriseName: z.string().nullish(),
  contractCount: z.number().nullish(),
  proposalCount: z.number().nullish(),
  conversionRate: z.string().nullish(),
  cancellationRate: z.string().nullish(),
  isOrg: z.boolean().nullish(),
});

const UserItemSchema = z.object({
  metrics: z.array(z.record(z.number())),
  mentorRatings: z
    .object({
      contractCount: z.number(),
      installCount: z.number(),
      proposalCount: z.number(),
      totalCount: z.number(),
      contractAvg: z.string(),
      installAvg: z.string(),
      proposalAvg: z.string(),
      meanOfAvgs: z.string(),
    })
    .nullish(),

  rank: z.number(),
  level: z.number(),
  maritalStatus: ZodSchemaUtil.makeStringLiteralUnion(["married", "single"]).nullable(),
  defaultAdvance: z.boolean(),
  defaultAdvanceSet: z.boolean(),
  gender: ZodSchemaUtil.makeStringLiteralUnion(["male", "female", "non_binary", "prefer_not_to_say"]).nullable(),
  dob: z.string().nullable(),
  areasServiced: z.array(z.string()),
  sponsors: z.array(z.number()),
  timezone: z.string().nullable(),
  qualifiedUntil: z.string().nullable(),
  lastActivityAt: z.string().nullable(),
  subscriptionStatus: ZodSchemaUtil.makeStringLiteralUnion(["active", "past_due", "canceled", "canceling"]),
  authFormat: ZodSchemaUtil.makeStringLiteralUnion(["any_auth", "password", "facebook", "google"]),
  orgName: z.string().nullable(),
  marginVisibility: z.boolean(),
  commissionVisibility: z.boolean(),
  sellerCalendlyUrl: z.string().nullable(),
  homeownerCalendlyUrl: z.string().nullable(),
  enterprise: z
    .object({
      external: z.boolean(),
      is_owner: z.boolean(),
      stop_recognition_emails_ent: z.boolean(),
      owner: z.object({ id: z.number() }),
    })
    .nullish(),
  displayRoles: z.array(z.array(z.string())),
  roles: z.array(z.string()),
  sponsor: UserLiteSchema.nullish(),
  ewalletUsername: z.string().nullish(),
  ipayoutAdress: z.string().nullish(),
  manager: UserLiteSchema.nullish(),
  relatedLevel: z.number(),
  certified: z.boolean(),
  integrityModules: z.array(z.string()).nullable(),
  projectRequirement: z.number(),
  projectRequirementMet: z.boolean(),
  tierThreeProjectRequirementMet: z.boolean(),
  tier2OrEnterpriseCertified: z.boolean(),
  certifications: z.array(z.string()),
  enrollment: EnrollmentSchema.nullish(),
  enrollments: z.array(EnrollmentSchema),
  emailChangeRequest: z
    .object({
      new_email: z.string(),
      expires_at: z.string(),
    })
    .nullish(),
  address: AddressSchema.nullish(),
});

export const UserSchema = UserLiteSchema.merge(UserItemSchema).merge(
  z.object({
    stripeOauthId: z.number().nullable(),
    metaData: z.object({}),
    ambassadorEarnings: z.number().nullish(),
    dashboardPhase: z.number(),
    experience: ZodSchemaUtil.makeStringLiteralUnion(["no_experience", "sales", "solar"]).nullable(),
    accountType: ZodSchemaUtil.makeStringLiteralUnion(["individual", "team"]).nullable(),
    kind: ZodSchemaUtil.makeStringLiteralUnion([
      "consultant",
      "ambassador",
      "installer",
      "enterprise",
      "enterprise_pro",
    ]).nullable(),
    manualKindSelected: z.boolean(),
    inboxCount: z.number(),
    myProjectsCount: z.number(),
    tooltipViewedOn: z.record(z.string()).nullable(),
    createdAt: z.string(),
    emailHash: z.string(),
    enterpriseType: z.string().nullable(),
    seatAssignments: z.array(z.unknown()),
    acceptedLatestTos: z.boolean(),
  }),
);

export const CoordinatorSchema = UserLiteSchema.pick({
  id: true,
  fullName: true,
  email: true,
  phone: true,
  avatar: true,
}).merge(z.object({ sellerCalendlyUrl: z.string() }));

export const AmbassadorSchema = UserLiteSchema.pick({
  id: true,
  fullName: true,
  email: true,
  phone: true,
  avatar: true,
});

export const AgentSchema = CoordinatorSchema.omit({ sellerCalendlyUrl: true }).merge(
  z.object({ siteSurveyor: z.boolean() }),
);

export const MentorSchema = AgentSchema.merge(
  z.object({
    metrics: z.array(z.record(z.number())),
    mentorRatings: z.record(z.union([z.string(), z.number()])),
    locale: z.string(),
    spokenLanguages: z.array(z.string()),
    sellerCalendlyUrl: z.string().nullable(),
    homeownerCalendlyUrl: z.string().nullable(),
    address: z.object({
      city: z.string(),
      state: z.string(),
    }),
  }),
).omit({ siteSurveyor: true });

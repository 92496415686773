import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  enrollments: [],
  selectedEnrollment: null,
  downgradeTo: null,
  services: [],
  upgradePreview: {},
  monthlyTotal: 0,
  credit: 0,
  cardInfo: {},
  charges: {
    list: [],
    paging: {
      pageSize: 50,
      pageNumber: 1,
      itemCount: 0,
    },
  },
};

export default {
  enrollment: createReducer(initialState, {
    [types.SET_UPGRADE_PREVIEW](state, action) {
      return {
        ...state,
        upgradePreview: action.payload,
      };
    },
    [types.SET_ENROLLMENTS](state, action) {
      return {
        ...state,
        enrollments: action.payload,
      };
    },
    [types.SET_SERVICES](state, action) {
      return {
        ...state,
        services: action.payload,
      };
    },
    [types.SET_MONTHLY_TOTAL](state, action) {
      return {
        ...state,
        monthlyTotal: action.payload,
      };
    },
    [types.SET_CREDIT](state, action) {
      return {
        ...state,
        credit: action.payload,
      };
    },
    [types.SET_CARD_INFO](state, action) {
      return {
        ...state,
        cardInfo: action.payload,
      };
    },
    [types.SET_SELECTED_ENROLLMENT](state, action) {
      return {
        ...state,
        selectedEnrollment: action.payload,
      };
    },
    [types.SET_DOWNGRADE_TO](state, action) {
      return {
        ...state,
        downgradeTo: action.payload,
      };
    },
    [types.SET_CHARGES](state, action) {
      return { ...state, charges: action.payload };
    },

    // INSERTION_PT (for script, do not remove)
  }),
};

// 01/01/2021
import userTimezone from "./userTimezone";

export default function formatDateTwoDigit(dateString) {
  const timeZone = userTimezone();

  return new Date(dateString).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    timeZone,
  });
}

export default {
  GET_BUILD_PARTNER_APPLICATIONS_SUCCESS: "Tier 2 Build Partner Applications Retrieved Successfully",

  GET_BUILD_PARTNER_APPLICATION_DETAIL_SUCCESS: "Build Partner Application Details Retrieved Successfully",

  APPROVE_BUILD_PARTNER_APPLICATION_SUCCESS: "Build Partner Application Approved",

  REJECT_BUILD_PARTNER_APPLICATION_SUCCESS: "Build Partner Application Rejected",

  MAKE_PENDING_BUILD_PARTNER_APPLICATION_SUCCESS: "Build Partner Application Made Pending",

  CREATE_BUILD_PARTNER_APPLICATIONS_SUCCESS: "Build Partner Application Created",

  UPDATE_BUILD_PARTNER_APPLICATIONS_SUCCESS: "Build Partner Application Updated",

  CREATE_TIER_ONE_BUILD_PARTNER_USER_SUCCESS: "Build Partner Tier One User Registration Success",

  ERROR_GETTING_BUILD_PARTNER_APPLICATIONS: "There was an error getting Build Partner Applications",

  ERROR_GETTING_BUILD_PARTNER_APPLICATION_DETAIL: "There was an error getting this Build Partner Application's Details",

  ERROR_APPROVING_BUILD_PARTNER_APPLICATION: "There was an error approving this Build Partner Application",

  ERROR_REJECTING_BUILD_PARTNER_APPLICATION: "There was an error rejecting this Build Partner Application",

  ERROR_MAKING_PENDING_BUILD_PARTNER_APPLICATION: "There was an error making this Build Partner Application pending",

  ERROR_CREATING_BUILD_PARTNER_APPLICATIONS: "There was an error creating this Build Partner Application",

  ERROR_UPDATING_BUILD_PARTNER_APPLICATIONS: "There was an error updating this Build Partner Application",

  ERROR_CREATING_TIER_ONE_USER_REGISTRATION: "There was an error creating tier one user registration",
};

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import actions from "rdx/actions";
import { useTranslation } from "react-i18next";
import { Form, Radio, Space, Input } from "antd";
import Registration from "models/Registration";
import { PersonSilhouetteIcon, GroupSilhouetteIcon } from "components/Icons";
import GradientButton from "components/Buttons/GradientButton";
import SelectInput from "components/FormElements/SelectInput";
import RadioButtonCard from "../RadioButtonCard";

import styles from "./AccountType.module.less";

const AccountType = ({ handleContinue, regInfo, isAmbassador }) => {
  const { t } = useTranslation(["join", "form_labels"]);
  const formValues = useRef({});
  const [form] = Form.useForm();
  const [joinTypeTab, setJoinTypeTab] = useState(true);
  const [accountTypeIndividual, setAccountTypeIndividual] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setAccountTypeIndividual(formValues.current.accountType === "individual");
  }, [formValues.current.accountType]);

  useEffect(() => {
    if (accountTypeIndividual) {
      formValues.current.orgName = null;
      formValues.current.orgSize = null;
    } else {
      formValues.current.experience = null;
    }
  }, [accountTypeIndividual]);

  const onContinue = () => {
    formValues.current = { ...formValues.current, ...form.getFieldsValue() };
    if (!joinTypeTab) {
      form
        .validateFields([accountTypeIndividual ? "experience" : "orgName"])
        .then(() => {
          if (!isAmbassador) {
            dispatch(actions.updateRegistration({ ...formValues.current, slug: regInfo.slug }));
          }
          handleContinue(formValues.current);
        })
        .catch((errors) => console.warn(errors));
    } else {
      setJoinTypeTab(!joinTypeTab);
    }
  };

  const orgSizeOptions = [
    { label: "1 to 4", value: "1 to 4" },
    { label: "5 to 19", value: "5 to 19" },
    { label: "20 to 99", value: "20 to 99" },
    { label: "100+", value: "100+" },
  ];

  return (
    <div className={styles.accountTypeContainer}>
      <Form onFinish={onContinue} form={form}>
        {joinTypeTab ? (
          <div className={styles.joinTypeContainer}>
            <div className={styles.joinTypelabel}>{t("join_form.join_type")}</div>
            <Form.Item name="accountType" className={styles.joinTypeButtons} initialValue={regInfo?.accountType}>
              <Radio.Group
                onChange={(v) => {
                  formValues.current.accountType = v.target.value;
                }}
              >
                <Radio.Button value="individual" onClick={onContinue} data-test-id="account-type-individual">
                  <RadioButtonCard
                    text={t("join_form.individual")}
                    graphic={<PersonSilhouetteIcon width="38" height="50" />}
                  />
                </Radio.Button>
                <Radio.Button value="team" onClick={onContinue} data-test-id="account-type-team">
                  <RadioButtonCard
                    text={t("join_form.organization")}
                    graphic={<GroupSilhouetteIcon width="71" height="47" />}
                  />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        ) : (
          <>
            {accountTypeIndividual ? (
              <div className={styles.experienceLevelContainer} data-test-id="experience-type-form">
                <div className={styles.label}>{t("join_form.experience_level")}</div>
                <div className={styles.experienceForm}>
                  <Form.Item
                    name="experience"
                    initialValue={regInfo?.experience}
                    className={styles.experienceRadioGroup}
                    rules={[
                      {
                        required: true,
                        message: t("join_form.experience_required"),
                      },
                    ]}
                  >
                    <Radio.Group size="large">
                      <Space direction="vertical">
                        <Radio value="solar">{t("join_form.solar_experience")}</Radio>
                        <Radio value="sales">{t("join_form.sales_experience")}</Radio>
                        <Radio value="no_experience" className={styles.experienceLevelRadioText}>
                          <span>{t("join_form.no_experience")}</span>
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldsValue }) => {
                      const { experience } = getFieldsValue();
                      return (
                        <GradientButton
                          type="primary"
                          htmlType="submit"
                          className={styles.continueButton}
                          disabled={!experience}
                        >
                          {t("join_form.continue")}
                        </GradientButton>
                      );
                    }}
                  </Form.Item>
                  <div className={styles.goBackLinkContainer}>
                    <span
                      className={styles.goBackLink}
                      tabIndex={0}
                      role="button"
                      onClick={() => setJoinTypeTab(!joinTypeTab)}
                      onKeyDown={() => setJoinTypeTab(!joinTypeTab)}
                    >
                      {t("join_form.go_back")}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.orgDetailsContainer} data-test-id="org-details-form">
                <div className={styles.label}>{t("join_form.your_org_name")}</div>
                <div className={styles.orgDetailsForm}>
                  <Form.Item
                    name="orgName"
                    initialValue={regInfo?.orgName}
                    validateTrigger={["onSubmit"]}
                    rules={[
                      {
                        required: true,
                        message: t("join_form.org_required"),
                      },
                    ]}
                  >
                    <Input placeholder={t("join_form.org_name")} />
                  </Form.Item>
                  <div className={styles.label}>{t("join_form.employee_count")}</div>
                  <div className={styles.employeeCountSelect}>
                    <SelectInput
                      itemName="orgSize"
                      choices={orgSizeOptions}
                      initialValue={regInfo?.orgSize || "1 to 4"}
                    />
                  </div>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldsValue }) => {
                      const { orgName } = getFieldsValue();
                      return (
                        <GradientButton
                          type="primary"
                          htmlType="submit"
                          className={styles.continueButton}
                          disabled={!orgName}
                        >
                          {t("join_form.continue")}
                        </GradientButton>
                      );
                    }}
                  </Form.Item>
                  <div className={styles.goBackLinkContainer}>
                    <span
                      className={styles.goBackLink}
                      tabIndex={0}
                      role="button"
                      onClick={() => setJoinTypeTab(!joinTypeTab)}
                      onKeyDown={() => setJoinTypeTab(!joinTypeTab)}
                    >
                      {t("join_form.go_back")}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

AccountType.propTypes = {
  handleContinue: PropTypes.func,
  regInfo: Registration.types(),
  isAmbassador: PropTypes.bool,
};

export default AccountType;

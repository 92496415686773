import React from "react";
import PropTypes from "prop-types";

const DownCaretThicker = (props) => {
  const { fill, height, width, stroke } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.33203"
        y="0.919922"
        width="6.98973"
        height="1.45619"
        rx="0.25"
        transform="rotate(45 2.33203 0.919922)"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <rect
        x="11.2012"
        y="1.9502"
        width="6.98973"
        height="1.45619"
        rx="0.25"
        transform="rotate(135 11.2012 1.9502)"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.5"
      />
    </svg>
  );
};

DownCaretThicker.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

DownCaretThicker.defaultProps = {
  fill: "white",
  stroke: "white",
  width: "12",
  height: "8",
};

export default DownCaretThicker;

import { states } from "lib/misc/states";
import { formatPhoneNumber } from "lib/helpers";
import dayjs from "dayjs";
import { ModuleT } from "containers/Admin/users/components/UserDetailDrawer/types";

export const leadSourceOptions = [
  { label: "Ambassador", value: "Ambassador", key: "1" },
  {
    label: "Friend Referral",
    value: "Friend Referral",
    key: "2",
  },
  {
    label: "Online Marketing",
    value: "Online Marketing",
    key: "3",
  },
  {
    label: "Word of Mouth",
    value: "Word of Mouth",
    key: "4",
  },
  {
    label: "Door Knocking",
    value: "Door Knocking",
    key: "5",
  },
  { label: "Facebook", value: "Facebook", key: "6" },
  { label: "YouTube", value: "YouTube", key: "7" },
  { label: "Other", value: "Other", key: "8" },
];
export const leadSourceValues = leadSourceOptions.map((source) => source.label);

export const countryCodes = [
  { value: "+1", label: "+1" },
  { value: "+52", label: "+52" },
];

export const languageOptions = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
];

export const categoryOptions = [
  { label: "Residential", value: "residential" },
  { label: "Commercial", value: "commercial" },
];

export const roofAgeOptions = [
  { value: "0 - 5", label: "0-5 Years" },
  { value: "6 - 10", label: "6-10 Years" },
  { value: "11 - 15", label: "11-15 Years" },
  { value: "16 - 20", label: "16-20 Years" },
  { value: "21+", label: "21+ Years" },
];

export const roofTypeOptions = [
  {
    value: "standard_asphalt",
    label: "Standard Asphalt",
  },
  { value: "flat_roof", label: "Flat Roof" },
  { value: "concrete_tile", label: "Concrete Tile" },
  { value: "clay_tile", label: "Clay Tile" },
  { value: "steel_shingle", label: "Steel Shingle" },
  { value: "metal", label: "Metal" },
  { value: "other", label: "Other" },
];

export const servicePanelOptions = [
  { value: "amp_100", label: "100 Amp" },
  { value: "amp_125", label: "125 Amp" },
  { value: "amp_150", label: "150 Amp" },
  { value: "amp_200", label: "200 Amp" },
  { value: "amp_400", label: "400 Amp" },
];

export const mountingOptions = [
  { value: "roof", label: "Roof" },
  { value: "ground", label: "Ground" },
  { value: "both", label: "Both" },
];

export const financingOptions = [
  { value: "cash", label: "Cash" },
  { value: "loan", label: "Loan" },
  { value: "lease", label: "Lease" },
];

export const workTypeOptions = [
  { value: "solar", label: "Solar" },
  { value: "solar_roof", label: "Solar + Roof" },
];

const addBusinessDays = (daysToAdd: number) => {
  const saturday = 6;
  const sunday = 7;
  const weekend = [dayjs().day(saturday).format("MM-DD-YYYY"), dayjs().day(sunday).format("MM-DD-YYYY")];

  let daysAdded = 0;
  let newDate = dayjs();
  while (daysAdded < daysToAdd) {
    newDate = newDate.add(1, "day");
    if (!weekend.includes(newDate.format("MM-DD-YYYY"))) {
      daysAdded++;
    }
  }
  return newDate.format("YYYY-MM-DD");
};

export const reminderValue = {
  none: () => "",
  atDueTime: (time: string) => dayjs(time).format("YYYY-MM-DD HH:mm:ss"),
  halfHourBefore: (time: string) => dayjs(time).subtract(30, "minutes").format("YYYY-MM-DD HH:mm:ss"),
  hourBefore: (time: string) => dayjs(time).subtract(1, "hour").format("YYYY-MM-DD HH:mm:ss"),
  dayBefore: (time: string) => dayjs(time).subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"),
  weekBefore: (time: string) => dayjs(time).subtract(1, "week").format("YYYY-MM-DD HH:mm:ss"),
};

export const reminderOptions = [
  { value: "none", label: "No Reminder" },
  { value: "atDueTime", label: "At activity due time" },
  { value: "halfHourBefore", label: "30 minutes before" },
  { value: "hourBefore", label: "1 hour before" },
  { value: "dayBefore", label: "1 day before" },
  { value: "weekBefore", label: "1 week before" },
];

export const dueDateOptions = [
  { value: dayjs().endOf("day").format("YYYY-MM-DD"), label: "Today" },
  { value: dayjs().add(1, "days").format("YYYY-MM-DD"), label: "Tomorrow" },
  { value: addBusinessDays(2), label: `In 2 business days (${dayjs(addBusinessDays(2)).format("ddd M/D")})` },
  { value: addBusinessDays(3), label: `In 3 business days (${dayjs(addBusinessDays(3)).format("ddd M/D")})` },
  {
    value: dayjs().add(1, "week").format("YYYY-MM-DD"),
    label: `In 1 week (${dayjs().add(1, "week").format("MMMM D")})`,
  },
  {
    value: dayjs().add(2, "week").format("YYYY-MM-DD"),
    label: `In 2 weeks (${dayjs().add(2, "week").format("MMMM D")})`,
  },
  {
    value: dayjs().add(1, "month").format("YYYY-MM-DD"),
    label: `In 1 month (${dayjs().add(1, "month").format("MMMM D")})`,
  },
  {
    value: dayjs().add(3, "month").format("YYYY-MM-DD"),
    label: `In 3 months (${dayjs().add(3, "month").format("MMMM D")})`,
  },
  {
    value: dayjs().add(6, "month").format("YYYY-MM-DD"),
    label: `In 6 months (${dayjs().add(6, "month").format("MMMM D")})`,
  },
];

export const stateOptions = () => {
  const stateList = states.map((state) => ({ value: state.short, label: state.long }));
  return stateList.sort((a, b) => a.label.localeCompare(b.label));
};

export const meetingTypeOptions = (phoneNumber: string, inPerson = false) => {
  return [
    ...(inPerson ? [{ value: "in_person", label: "In Person" }] : []),
    ...(phoneNumber ? [{ value: "phone", label: formatPhoneNumber(phoneNumber) }] : []),
    { value: "zoom", label: "Zoom Meeting" },
    { value: "google", label: "Google Meeting" },
    { value: "microsoft", label: "Microsoft Meeting" },
    { value: "other", label: "Other" },
  ];
};
export const sellerIntegrityModules: ModuleT[] = [
  {
    key: "behaving",
    displayName: "Behaving with Integrity",
    description:
      "In Module 1, not only will you learn how this certification works, but you’ll learn who the Seller Integrity team is, and foundational principles which are the building blocks behind Powur-ing with Integrity.",
  },
  {
    key: "presenting",
    displayName: "Presenting with Integrity",
    description:
      "In Module 2 you’ll learn how to present the solar value proposition with the utmost of integrity. The importance of painting a clear and accurate future picture of solar for each and every homeowner, from the actual Powur proposal and down to recognizing and addressing any inaccurate preconceived ideas or misunderstandings about solar that may have come from external sources.",
  },
  {
    key: "selling",
    displayName: "Selling with Integrity",
    description:
      "In Module 3, in addition to diving further into accurately designing and presenting the solar system, the areas we’ll focus on here will be communicating clearly and without avoidance the crucial aspects of the solar proposition such as the ITC, loan payments, post solar utility bills, working with Elders, and Tier 3 Partner expectations.",
  },
  {
    key: "home_improvement_contracts",
    displayName: "Administering Contracts with Integrity",
    description:
      "In Module 4 you’ll learn just how critical it is to project funding to be sure the entire contract process is in perfect order, from digital HIC & lending signatures to supporting documentation. If any of this is out of integrity, funding can be revoked and your ability to sell on the Powur platform, removed.",
  },
  {
    key: "policies_and_procedures",
    displayName: "Following Powur's “Policies & Procedures” with Integrity",
    description:
      "In Module 5 you’ll learn how to stay in full Integrity when it comes to our legal compliance guidelines and expectations for representing Powur in a professional and honest way. From protecting the company image, to recruiting, to marketing and advertising, we’ll show you how to familiarize yourself with Powur’s best practices as outlined in the Powur Policies & Procedures. All of which are required to maintain an active and good-standing seller status on the Powur platform.",
  },
  {
    key: "essential_expectations",
    displayName: "Powur's Governance Policy & Procedures",
    description:
      "In Module 6 we dive into who can and cannot be on the platform, what the investigation process looks like, and the true repercussions of certain violations.",
  },
];

export default [...leadSourceOptions];

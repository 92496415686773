import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { CrmActivityFormDataT } from "types/Project/crmActivity";
import { keysToSnakeCase, camelizeKeys, formatCrmActivityForLog, formatPreSalesCrmActivity } from "lib/helpers";
import messages from "../messages";

type CrmActivityParamsT = {
  payload: CrmActivityFormDataT;
};

const successMessage = (activity) => {
  switch (activity.kind) {
    case "reminder":
      return messages.CRM_REMINDER_SCHEDULED;
    case "email":
      return messages.CRM_EMAIL_SENT;
    default:
      return messages.CRM_ACTIVITY_CREATE_SUCCESS;
  }
};

function* createNewCrmActivity(action: CrmActivityParamsT) {
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.post("/sales_enablement_activities", params);

  if (success && data) {
    const activity = camelizeKeys(data.body);
    const projectDetail = yield select(selectors.getProjectDetails);

    const projectList = yield select(selectors.selectProviderLeadsList);
    const idxOfListProject = projectList.providerLeads.findIndex(
      (project) => project.homeowner.id === action.payload.leadId,
    );

    const kanbanData = yield select(selectors.selectProviderLeadsKanban);
    const idxOfKanbanProject = kanbanData.providerLeads.findIndex(
      (project) => project.homeowner.id === action.payload.leadId,
    );

    const preSalesList = yield select(selectors.getProjectPreSales);
    const idxOfPreSalesProject = preSalesList.projects.findIndex((project) => project.leadId === action.payload.leadId);
    const preSalesActivity = formatPreSalesCrmActivity(activity);
    const updatedPreSales = [...preSalesList.projects];

    if (updatedPreSales[idxOfPreSalesProject] && activity.kind !== "email") {
      updatedPreSales[idxOfPreSalesProject].activities.push(preSalesActivity);
    }

    const logActivity = formatCrmActivityForLog(activity);

    yield all([
      put(
        actions.newSuccessEvent({
          message: successMessage(activity),
          payload: { ...data.body },
        }),
      ),
      ...(idxOfKanbanProject >= 0
        ? [put(actions.addCrmActivityToKanban({ projectIdx: idxOfKanbanProject, activity }))]
        : []),
      ...(idxOfListProject >= 0 ? [put(actions.addCrmActivityToList({ projectIdx: idxOfListProject, activity }))] : []),
      ...(idxOfPreSalesProject >= 0
        ? [
            put(
              actions.setProjectsPreSales({
                projects: updatedPreSales,
                paging: preSalesList.paging,
              }),
            ),
          ]
        : []),
      ...(action.payload.leadId === projectDetail?.lead?.id
        ? [put(actions.addCrmActivityToDetail(logActivity)), put(actions.addProjectCrmActivity(activity))]
        : []),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_CRM_ACTIVITY,
    });
  }

  return null;
}

export default createNewCrmActivity;

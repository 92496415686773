import types from "rdx/modules/enterpriseOrg/types";
import createAction from "rdx/utils/createAction";

export default {
  getEnterpriseOrg: (payload) => createAction(types.GET_ENTERPRISE_ORG_REQUEST, payload),

  getEnterpriseOrgSeat: (payload) => createAction(types.GET_ENTERPRISE_ORG_SEAT_REQUEST, payload),

  setEnterpriseOrg: (payload) => createAction(types.SET_ENTERPRISE_ORG, payload),

  setEnterpriseOrgSeat: (payload) => createAction(types.SET_ENTERPRISE_ORG_SEAT, payload),

  resetEnterpriseOrgSeat: (payload) => createAction(types.RESET_ENTERPRISE_ORG_SEAT, payload),

  updateEnterpriseOrg: (payload) => createAction(types.UPDATE_ENTERPRISE_ORG_REQUEST, payload),

  getEnterpriseOrgSeats: (payload) => createAction(types.GET_ENTERPRISE_ORG_SEATS, payload),

  setEnterpriseOrgSeats: (payload) => createAction(types.SET_ENTERPRISE_ORG_SEATS, payload),

  getEnterprisesList: (payload) => createAction(types.GET_ENTERPRISES_LIST, payload),

  setEnterprisesList: (payload) => createAction(types.SET_ENTERPRISES_LIST, payload),

  getVolumeTiersForEnterprise: (payload) => createAction(types.GET_VOLUME_TIERS_FOR_ENTERPRISE, payload),

  setSelectedVolumeTiers: (payload) => createAction(types.SET_SELECTED_VOLUME_TIERS, payload),

  getEnterpriseCobranding: (payload) => createAction(types.GET_ENTERPRISE_COBRANDING, payload),

  setEnterpriseCobranding: (payload) => createAction(types.SET_ENTERPRISE_COBRANDING, payload),

  postEnterpriseAssets: (payload) => createAction(types.POST_ENTERPRISE_ASSETS, payload),

  setEnterpriseAssets: (payload) => createAction(types.SET_ENTERPRISE_ASSETS, payload),

  deleteEnterpriseAsset: (payload) => createAction(types.DELETE_ENTERPRISE_ASSET, payload),

  getEnterpriseCustomFields: (payload) => createAction(types.GET_ENTERPRISE_CUSTOM_FIELDS, payload),

  setEnterpriseCustomFields: (payload) => createAction(types.SET_ENTERPRISE_CUSTOM_FIELDS, payload),
  // INSERTION_PT (for script, do not remove)
};

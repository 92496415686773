import { CrmActivitiesReduxStateT, CrmActivityT, CrmActivityTypeT, CrmTaskListT } from "types/Project/crmActivity";

type RootStateT = {
  crmActivities: CrmActivitiesReduxStateT;
};

export default {
  selectCrmActivityDrawerVisible: (state: RootStateT): boolean => state.crmActivities.crmActivityDrawer.visible,
  selectCrmActivityType: (state: RootStateT): CrmActivityTypeT => state.crmActivities.crmActivityDrawer.activityType,
  selectCrmActivityLeadId: (state: RootStateT): number => state.crmActivities.crmActivityDrawer.leadId,
  selectCrmActivityDetails: (state: RootStateT): CrmActivityT | Record<string, unknown> =>
    state.crmActivities.crmActivityDetails,
  selectCrmActivityId: (state: RootStateT): number | undefined => state.crmActivities.crmActivityDrawer.activityId,
  selectCrmTaskList: (state: RootStateT): CrmTaskListT => state.crmActivities.crmTaskList,
};

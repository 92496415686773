import React, { CSSProperties } from "react";
import styles from "./SubHeaderFilled.module.less";

type SubHeaderFilledPropsT = {
  title: string | React.ReactNode;
  sectionID?: string;
  subTitle?: string | React.ReactNode;
  sideText?: string | React.ReactNode;
  color?: string;
  containerStyles?: CSSProperties;
  titleStyles?: CSSProperties;
  subTitleStyles?: CSSProperties;
  sideTextStyles?: CSSProperties;
  isGradientBackground?: boolean;
  titleContainerStyles?: CSSProperties;
};

const SubHeaderFilled = ({
  title,
  subTitle,
  sideText,
  sectionID,
  isGradientBackground = false,
  color = isGradientBackground ? "var(--white)" : "var(--darkest-blue)",
  containerStyles = {},
  titleStyles = {},
  subTitleStyles = {},
  sideTextStyles = {},
  titleContainerStyles = {},
}: SubHeaderFilledPropsT) => {
  return (
    <div
      id={sectionID}
      className={isGradientBackground ? styles.subHeaderFilledGradientContainer : styles.subHeaderFilledContainer}
      style={{ ...containerStyles }}
    >
      <div className={styles.subHeaderContainer} style={{ ...titleContainerStyles }}>
        <div style={{ ...titleStyles, color }} className={styles.title}>
          {title}
        </div>
        <div style={{ ...sideTextStyles }} className={styles.sideText}>
          {sideText}
        </div>
      </div>
      {subTitle && (
        <div style={{ ...subTitleStyles, color }} className={styles.subTitle}>
          {subTitle}
        </div>
      )}
    </div>
  );
};

export default SubHeaderFilled;

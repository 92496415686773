import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/leads/messages";
import { keysToSnakeCase } from "lib/helpers";
import { ConfirmSiteLocationPayloadT } from "rdx/modules/leads/stateTypes";

type ConfirmSiteLocationPropsT = {
  payload: ConfirmSiteLocationPayloadT;
};

function* confirmSiteLocation(action: ConfirmSiteLocationPropsT) {
  const { siteId } = action.payload;
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.patch(`/sites/${siteId}/confirm_location`, params);

  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.CONFIRM_SITE_LOCATION_SUCCESS,
        }),
      ),
      put(actions.setAddressConfirmed()),
      put(actions.setProjectLeadDetails({ lead: { isAddressConfirmed: true } })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CONFIRMING_SITE_LOCATION,
    });
  }

  return null;
}

export default confirmSiteLocation;

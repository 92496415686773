import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "@rdxmodules/enterprisePersonnel/messages";
import { camelizeKeys } from "@helpers";

function* getEnterprisePersonnel(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/enterprise/users", params);

  if (success && data) {
    if (data.body.users) {
      yield put(actions.setEnterprisePersonnelList(camelizeKeys(data.body)));
    }
    if (data.body.filters) {
      yield put(actions.setEnterprisePersonnelFilters(data.body.filters));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_USERS_LIST,
    });
  }
  return null;
}

export default getEnterprisePersonnel;

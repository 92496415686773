import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/enrollment/messages";

import actions from "rdx/actions";

function* downgradeEnrollment(action) {
  const { payload } = action;
  const { success, data, error } = yield* makeRequest.post(`/services/${payload.service}/enrollment/downgrade`, {
    ...payload.params,
  });
  if (success && data) {
    yield all([
      put(actions.setEnrollments(data.body.enrollment)),
      put(actions.setEnterpriseOrgSeats({ seats: [] })),
      put(actions.setEnterpriseOrgSeat({ settings: {} })),
      put(actions.setEnterpriseOrg({ settings: {} })),
      put(
        actions.newSuccessEvent({
          message: messages.ENROLLMENT_DOWNGRADE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DOWNGRADING_ENROLLMENT,
    });
  }
  return null;
}

export default downgradeEnrollment;

export default {
  // successes
  ENROLLMENT_UPGRADE_SUCCESS: "You have successfully upgraded your enrollment.",
  ENROLLMENT_DOWNGRADE_SUCCESS: "You have successfully downgraded your enrollment.",
  ENROLLMENT_RENEW_SUCCESS: "You have successfully renewed your enrollment.",
  ENROLLMENT_CANCEL_SUCCESS: "You have successfully cancelled your enrollment.",
  ENROLLMENT_SUCCESS: "You have successfully enrolled.",
  ENROLLMENT_INTENT_SUCCESS: "Enrollment intent was successfully recorded.",

  // errors
  ERROR_UPGRADING_ENROLLMENT: "There was an error upgrading enrollment.",
  ERROR_DOWNGRADING_ENROLLMENT: "There was an error downgrading enrollment.",
  ERROR_FETCHING_UPGRADE_PREVIEW: "There was an error fetching upgrade details.",
  ERROR_FETCHING_ENROLLMENTS: "There was an error fetching enrollments",
  ERROR_FETCHING_CHARGES: "There was an error fetching charges",
  ENROLLMENT_RENEW_ERROR: "There was an error renewing your enrollment.",
  ENROLLMENT_CANCEL_ERROR: "There was an error canceling your enrollment.",
  ENROLLMENT_ERROR: "There was an error processing your enrollment.",
  ENROLLMENT_INTENT_ERROR: "There was an error recording enrollment intent.",
  // INSERTION_PT (for script, do not remove)
};

const learnMoreData = {
  header: "Discover the Powur Platform benefits",
  desc: "With Powur, you are in the driver’s seat. Learn more about why we are the fastest-growing residential solar company in the US.",
  tasks: [
    {
      task: "Welcome from our CEO",
      video: {
        individual: {
          no_experience: {
            en: "https://vimeo.com/738404528/e1c55a69d1",
            es: "https://vimeo.com/761134285/c491432f70",
          },
          sales: {
            en: "https://vimeo.com/738404528/e1c55a69d1",
            es: "https://vimeo.com/761134285/c491432f70",
          },
          solar: {
            en: "https://vimeo.com/738404482/2d8cd084ad",
            es: "https://vimeo.com/761134471/0280584b22",
          },
        },
        team: {
          en: "https://vimeo.com/738404399/2379997ab8",
          es: "https://vimeo.com/761134394/010c331fd9",
        },
      },
      hasVideo: true,
      complete: false,
      header: "Welcome from our CEO",
      powerTip: {
        span: "Our CEO, Jonathan Budd",
        p: "founded Powur after a visit to the Amazon. His vision was to create the largest virtual platform for clean energy in the world. We are just getting started.",
      },
    },
    {
      task: "Grow your solar pipeline with custom marketing tools",
      header: "Grow your solar pipeline with custom marketing tools",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          header: "Marketing Center",
          desc: "Select from a robust and growing library of marketing tools for social, digital, canvassing, trade shows, direct mail and belly to belly efforts. With the Powur platform, all you have to do is plug and play with on point and on brand marketing messages.",
        },
        {
          isVideo: false,
          header: "Personalized Links",
          desc: "Share simple and powerful marketing links with your homeowner prospects that all connect right back to your back office. Any leads that come from your personal marketing pages will flow directly back to your account.",
        },
      ],
    },
    {
      task: "Offer the most customized solution in the industry",
      header: "Offer the most customized solution in the industry ",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          header: "Powerful proposal design tool",
          desc: "Each proposal is fully customizable for each customer. Our platform gives you a max fit design and you can customize from there. Simply locate your customer’s home on Google maps and you’re on your way to a professional, customized presentation for your customer. Customize their rate, usage and other details for a fully personalized proposal.",
        },
        {
          isVideo: false,
          header: "Flexible product options",
          desc: "You’re not tied to one suite of products or equipment with Powur. Our platform model means you’ll have the industry leading options for panels, inverters, racking and any other equipment your homeowner needs so you can build the RIGHT system for their needs. Plus, benefit from our buying power and growing base of sellers to get the best value for your customers on products and equipment.",
        },
        {
          isVideo: false,
          header: "Multiple financing options",
          desc: "Work with your customer to design a payment model that works best for them and their unique financial situation. You’ll have access to the leading financing companies and products for a diverse suite of options. With loan products designed for customers ranging from low FICO scores to cash-paying clients and anything in between, the power is in your hands to create a system that meets their needs.",
        },
      ],
    },
    {
      task: "Earn five ways with the most competitive commissions in the industry",
      header: "Earn five ways with the most competitive commissions in the industry",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          header: "Cost of goods model",
          desc: "You have TOTAL control over pricing at Powur. With complete transparency into the cost of goods, you have the freedom to set the price you need to make the sale and give your customer an affordable and personalized option.  ",
        },
        {
          isVideo: false,
          header: "Personal sales",
          desc: "You’ll earn 70% of the net margin on any personal deal you close yourself while Powur takes only 30%. Yes, it’s the highest commission model, giving our sellers an average of $6,000 - $7,000 per sale. Plus, you can earn as you learn and earn half of this on your first 5 deals while you partner with a certified Tier 3 Sales Partner on the platform.",
        },
        {
          isVideo: false,
          header: "Partnered sales",
          desc: "Complete a handful of sales yourself and meet our certification requirements and you’ll be eligible to be a Tier 3 Sales Partner and help other sellers close their deals. Split commission with them 50/50. Our top sellers use this program as a lucrative and reliable source of incoming leads.",
        },
        {
          isVideo: false,
          header: "Revenue share",
          desc: "With Powur you can refer other sellers to the platform and build a team of solar professionals. You’ll earn passive income in the form of a revenue share on your team’s deals. The larger your team, the more passive income you’ll earn. It’s a great reason to share the message of Powur and how it can change peoples’ lives, just like it will change yours.",
        },
        {
          isVideo: false,
          header: "Finance rebate",
          desc: "Powur offers a first-of-its-kind additional way to get paid. Paid monthly, our Finance Rebate is a rebate you’ll earn on every eligible loan. Just like commissions on sales and partnered sales, you’ll earn this for your own deals, for deals you partner on, and as an override for your team’s deals.",
        },
        {
          isVideo: false,
          header: "Stock options",
          desc: "Own a piece of Powur. The more installations you complete, the more stock options you can earn. With Powur you are not only an owner of your own solar business, but you can be an owner of the Powur platform. With Powur’s goal to go public in the very near future, this income is a significant opportunity to build more wealth.",
        },
      ],
    },
    {
      task: "Become a solar professional",
      header: "Become a solar professional",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          header: "Training & certification",
          desc: "We provide the comprehensive education you need to get up to speed on solar and sell like a pro. You’ll become an authority on solar energy and the solar sales process, ready to bring your prospects the knowledge they need to choose solar and choose Powur!",
        },
        {
          isVideo: false,
          header: "Knowledge base",
          desc: "You’ll be able to access our information-packed knowledge base for almost any question you have about solar. Need an answer about a local incentive offer in a particular market? You got it. Need to know how to read a utility bill in a particular area. We’ve got you covered.",
        },
      ],
    },
    {
      task: "Count on our world-class operations and reliable installs",
      header: "Count on our world-class operations and reliable installs",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          header: "The choice is yours!",
          desc: "You can be as involved in your projects post contract as you want or step back and let Powur take the reigns. Our project services model means that your projects are in great hands once you complete the project.",
        },
        {
          isVideo: false,
          header: "Dedicated project manager",
          desc: "You’ll have one of Powur’s expert project managers ushering your customers’ projects along, ensuring each step is coordinated and on track. Communicate directly with your project manager in the platform if you have any questions about the status of a project.",
        },
        {
          isVideo: false,
          header: "Certified local labor partners",
          desc: "We select only the best local installers, ensure they are trained and certified and hold them to the excellence standards of the Powur platform, so you can rest assured the local labor we contract will provide your customers high quality installations.",
        },
      ],
    },
    {
      task: "Join the Powur community",
      header: "Join the Powur community",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          header: "Stability",
          desc: "Don’t leave your income to chance. Powur is supported by a world-class Board of Directors and has had two back to back crowd-funding rounds. We operate cash flow positive despite holding a healthy and growing cash balance. Unlike other solar companies in the industry, thanks to our innovative platform model, Powur is financially stable and built to last.",
        },
        {
          isVideo: false,
          header: "Culture",
          desc: "We are committed to our culture of emPOWURment, giving you the ability to make your own hours, maximize your income, liberate yourself from a corporate grind, and make a true, meaningful impact on the world.",
        },
        {
          isVideo: false,
          header: "Community",
          desc: "Join thousands of Powur sellers around the country who make their livelihoods bringing renewable energy and lower utility bills to homeowners. With team support, frequent collaboration, and Powur events to keep you motivated and supported.",
        },
        {
          isVideo: false,
          header: "Mission",
          desc: "Powur’s mission is to not only become the largest clean energy platform on Earth, but to give back to our planet and its citizens. How? With Impact Journeys in which we bring shelter and energy to those in need, by removing carbon entering our atmosphere every day while contributing to reforestation, and enabling financial freedom for our sellers and homeowners across the country. We also contribute to reforestation,  (Pachama, Impact Journey, impact report)",
        },
      ],
    },
    {
      task: "Success stories",
      header: "How to succeed at Powur",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: true,
          videos: [
            {
              title: "Juan’s Story",
              linkUrl:
                "https://powur-public.s3-us-west-1.amazonaws.com/assets/videos/Powur_JuanPonce_Homeowner_website_v3_1.mp4",
            },
          ],
          header: "Juan’s Story",
          desc: "Hear how Juan made the decision to go solar with Powur and the many benefits he is experiencing after becoming his own power company.",
        },
        {
          isVideo: true,
          videos: [
            {
              title: "Puja & Amish's Story",
              linkUrl:
                "https://powur-public.s3-us-west-1.amazonaws.com/assets/videos/Powur_Amish_Puja_Homeowner_website_v3_1.mp4",
            },
          ],

          header: "Puja & Amish's Story",
          desc: "See how Puja & Amish have been transformed by the power of positive thought and direct action.",
        },
        {
          isVideo: true,
          videos: [
            {
              title: "John's Story",
              linkUrl:
                "https://powur-public.s3-us-west-1.amazonaws.com/assets/videos/Powur_JohnParis_Homeowner_website_v3_1.mp4",
            },
          ],
          header: "John's Story",
          desc: "John explains how accuracy, honesty, and transparency made it easy to take control of his electric bills by going solar with Powur.",
        },
        {
          isVideo: true,
          videos: [
            {
              title: "Kristy's Story",
              linkUrl:
                "https://powur-public.s3-us-west-1.amazonaws.com/assets/videos/Powur_Kristy_Homeowner_website_v3_1.mp4",
            },
          ],
          header: "Kristy's Story",
          desc: "Rising electric bills and a helping hand from a Powur Seller helped Kristy and her family take the leap to install solar + storage and they aren't looking back.",
        },
      ],
    },
  ],
  colOneButton: "Activate Your Account",
  buttonLabel: "Learn more about our Account Types",
};

export default learnMoreData;

import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/enrollment/types";
import getUpgradePreview from "./getUpgradePreview";
import upgradeEnrollment from "./upgradeEnrollment";
import downgradeEnrollment from "./downgradeEnrollment";

import getEnrollments from "./getEnrollments";
import getCharges from "./getCharges";
import renewEnrollment from "./renewEnrollment";
import cancelEnrollment from "./cancelEnrollment";
import subscribeEnrollment from "./subscribeEnrollment";
import enrollmentIntent from "./enrollmentIntent";
// IMPORT_PT (for script, do not remove)

function* watchEnrollmentSagas() {
  yield trackRequests(takeEvery, types.GET_UPGRADE_PREVIEW, getUpgradePreview);
  yield trackRequests(takeEvery, types.UPGRADE_ENROLLMENT, upgradeEnrollment);
  yield trackRequests(takeEvery, types.DOWNGRADE_ENROLLMENT, downgradeEnrollment);
  yield trackRequests(takeEvery, types.GET_ENROLLMENTS, getEnrollments);
  yield trackRequests(takeEvery, types.GET_CHARGES, getCharges);
  yield trackRequests(takeEvery, types.RENEW_ENROLLMENT, renewEnrollment);
  yield trackRequests(takeEvery, types.CANCEL_ENROLLMENT, cancelEnrollment);
  yield trackRequests(takeEvery, types.SUBSCRIBE_ENROLLMENT, subscribeEnrollment);
  yield trackRequests(takeEvery, types.ENROLLMENT_INTENT, enrollmentIntent);
  // INSERTION_PT (for script, do not remove)
}

export default watchEnrollmentSagas;

import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import useWindowResize from "hooks/useWindowResize";
import useLoading from "@loading";
import types from "@rdxmodules/registration/types";
import messages from "@rdxmodules/registration/messages";
import userMessages from "@rdxmodules/users/messages";
import SessionClient from "util/SessionClient";
import Registration from "models/Registration";
import DesktopCard from "./DesktopCard";
import MobileCard from "./MobileCard";
import EmailVerification from "./components/EmailVerification";
import ContactInfo from "./components/ContactInfo";
import AccountType from "./components/AccountType";
import FinalizeAccount from "./components/FinalizeAccount";

import styles from "./CreateAccount.module.less";

const CreateAccount = ({
  getSellerApplicationAgreement,
  sellerTerms,
  createNewUser,
  latestMessage,
  regInfo,
  getRegistration,
  upgradeAmbassador,
  setAlertMessageVisible,
}) => {
  const { t } = useTranslation(["join", "form_labels"]);
  const { i18n } = useTranslation();
  const passwordValues = useRef({});
  const ambassadorUpgradeValues = useRef({});
  const history = useHistory();
  const { slug, user } = useParams();
  const [activeTab, setActiveTab] = useState(!slug ? 0 : 1);
  const [regIsLoaded, setRegIsLoaded] = useState(false);
  const [passwordCreated, setPasswordCreated] = useState(false);
  const [userWasInvited, setUserWasInvited] = useState(false);
  const [usedOauthValidation, setUsedOauthValidation] = useState(false);
  const [isAmbassador, setIsAmbassador] = useState(false);
  const [ambassadorId, setAmbassadorId] = useState(null);
  const session = new SessionClient();
  const currentLng = i18n.language;
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

  const loading = useLoading({
    watchRequests: [types.CREATE_NEW_USER, types.GET_REGISTRATION_INFO],
  });

  const { width } = useWindowResize();
  const mobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  useEffect(() => {
    if (session?.rbac) {
      const isAmbassadorUpgrade = session?.isAmbassador;
      setIsAmbassador(isAmbassadorUpgrade);
      if (isAmbassadorUpgrade) setAmbassadorId(session.user.props.id);
    }
    if (regIsLoaded) {
      getSellerApplicationAgreement({ enterpriseId: regInfo.pendingSeat?.enterpriseId });
    }
    if (slug) {
      getRegistration({ code: slug });
    } else {
      setRegIsLoaded(true);
    }
  }, [regIsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const enterpriseOwner = regInfo.kind === "enterprise_pro";
    const seatedSeller = regInfo.roleTags?.includes("consultant") && !!regInfo?.pendingSeat;
    const orgStaffRoles = ["lead_generator", "org_manager", "org_support"];
    const orgStaff = regInfo.roleTags?.some((role) => orgStaffRoles.includes(role));
    if (
      latestMessage.message === messages.CREATE_NEW_USER_SUCCESS ||
      latestMessage.message === userMessages.UPGRADE_AMBASSADOR_SUCCESS
    ) {
      session.user.reload();
      if (enterpriseOwner) {
        history.push("/getting-started/account-types");
      } else if (seatedSeller) {
        history.push("/dashboard");
      } else if (orgStaff) {
        history.push("/org-projects/leads");
      } else {
        history.push("/getting-started");
      }
    }
    if (latestMessage.message === messages.ERROR_CREATING_NEW_USER) {
      setAlertMessageVisible({
        message: `${latestMessage.message}`,
        severity: "error",
      });
    }
    if (latestMessage.message === messages.GET_REGISTRATION_SUCCESS) {
      if (regInfo.confirmed) {
        history.push("/login");
      }
      if (regInfo.inviteAt) {
        if (!regIsLoaded) setActiveTab(0);
        setUserWasInvited(true);
      }
      setRegIsLoaded(true);
    }
    if (latestMessage.message === messages.ERROR_GETTING_REGISTRATION) {
      history.push("/login");
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleContinue = () => {
    setActiveTab(activeTab + 1);
  };

  const handleAmbassadorContinue = (v) => {
    ambassadorUpgradeValues.current = { ...ambassadorUpgradeValues.current, ...v };
    setActiveTab(activeTab + 1);
  };

  const handlePreviousTabClick = (clickedIdx) => {
    setActiveTab(clickedIdx);
    if (!usedOauthValidation) setPasswordCreated(false);
  };

  const savePassword = (values) => {
    passwordValues.current = { ...values };
    setPasswordCreated(true);
  };

  const handleSubmit = () => {
    createNewUser({ ...passwordValues.current, slug: regInfo.slug });
  };

  const handleAmbassadorSubmit = () => {
    upgradeAmbassador({ userId: ambassadorId, ...ambassadorUpgradeValues.current });
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    const q = parse(history.location.search);
    if (q.lng && lng === i18n.options.fallbackLng[0]) {
      delete q.lng;
      history.replace({ search: stringify(q) });
    }
  };

  const changeLanguage = () => {
    if (!activeTab) return null;
    return (
      <div className={styles.language}>
        {currentLng === "en" ? (
          <span
            className={styles.languageSelect}
            onClick={() => handleChangeLanguage("es")}
            tabIndex={0}
            role="button"
            onKeyDown={() => handleChangeLanguage("es")}
          >
            Espa&ntilde;ol
          </span>
        ) : (
          <span
            className={styles.languageSelect}
            onClick={() => handleChangeLanguage("en")}
            tabIndex={0}
            role="button"
            onKeyDown={() => handleChangeLanguage("en")}
          >
            English
          </span>
        )}
      </div>
    );
  };

  const tabData = [
    ...(isAmbassador
      ? [
          {
            title: t("join_form.acct_type"),
            tabImg: "/img/installation.png",
            tabImgAlt: "streamlined technology",
            displayTabImg: true,
            badgeImg: "/img/b_corp_logo.png",
            badgeImgAlt: "b corp logo",
            displayBadgeImg: true,
            component: <AccountType handleContinue={handleAmbassadorContinue} isAmbassador />,
          },
          {
            title: t("join_form.dob"),
            tabImg: `${urlPrefix}/assets/img/join/join_WorkAnywhere.jpg`,
            tabImgAlt: "work anywhere",
            tabImgStyle: { objectPosition: "-145px" },
            displayTabImg: true,
            badgeImg: "/img/bbb_logo.png",
            badgeImgStyle: { paddingRight: "15px" },
            badgeImgAlt: "bbb logo",
            displayBadgeImg: true,
            component: <ContactInfo isAmbassador handleContinue={handleAmbassadorContinue} />,
          },
          {
            title: t("join_form.finalize"),
            tabImg: "/img/img_onboarding_password.png",
            tabImgAlt: "streamlined technology",
            displayTabImg: false,
            badgeImg: null,
            badgeImgAlt: null,
            displayBadgeImg: false,
            component: (
              <FinalizeAccount
                isAmbassador
                handleSubmit={handleAmbassadorSubmit}
                applicationAgreement={sellerTerms}
                loading={!!loading}
                passwordCreated
              />
            ),
          },
        ]
      : [
          {
            title: t("join_form.login_credentials"),
            tabImg: `${urlPrefix}/assets/img/join/join_streamlined-technology-bg.png`,
            tabImgAlt: "streamlined technology",
            displayTabImg: true,
            badgeImg: "/img/inc5000_logo_43.png",
            badgeImgAlt: "inc5000 logo",
            displayBadgeImg: true,
            component: (
              <EmailVerification
                handleContinue={handleContinue}
                slug={slug}
                setPasswordCreated={setPasswordCreated}
                sponsor={user}
                regInfo={regInfo}
                userWasInvited={userWasInvited}
                setUsedOauthValidation={setUsedOauthValidation}
              />
            ),
          },
          {
            title: t("join_form.contact_info"),
            tabImg: `${urlPrefix}/assets/img/join/join_WorkAnywhere.jpg`,
            tabImgAlt: "work anywhere",
            tabImgStyle: { objectPosition: "-145px" },
            displayTabImg: true,
            badgeImg: "/img/bbb_logo.png",
            badgeImgStyle: { paddingRight: "15px" },
            badgeImgAlt: "bbb logo",
            displayBadgeImg: true,
            component: <ContactInfo regInfo={regInfo} handleContinue={handleContinue} />,
          },
          ...(!regInfo.pendingSeat
            ? [
                {
                  title: t("join_form.acct_type"),
                  tabImg: "/img/installation.png",
                  tabImgAlt: "streamlined technology",
                  displayTabImg: true,
                  badgeImg: "/img/b_corp_logo.png",
                  badgeImgAlt: "b corp logo",
                  displayBadgeImg: true,
                  component: <AccountType handleContinue={handleContinue} regInfo={regInfo} />,
                },
              ]
            : []),
          {
            title: t("join_form.finalize"),
            tabImg: "/img/img_onboarding_password.png",
            tabImgAlt: "streamlined technology",
            displayTabImg: !passwordCreated,
            badgeImg: null,
            badgeImgAlt: null,
            displayBadgeImg: false,
            component: (
              <FinalizeAccount
                handleSubmit={handleSubmit}
                applicationAgreement={sellerTerms}
                loading={!!loading}
                passwordCreated={passwordCreated}
                savePassword={savePassword}
                usedOauthValidation={usedOauthValidation}
              />
            ),
          },
        ]),
  ];

  return (
    <div className={styles.createAccountContainer}>
      {mobile ? (
        <div className={styles.joinPowurMobileView}>
          <div className={styles.mobileHeader}>
            <span
              tabIndex={0}
              role="button"
              onClick={() => history.push("/join")}
              onKeyDown={() => history.push("/join")}
            >
              <img className={styles.powurLogo} src="/img/powur-logo.png" alt="Powur Logo" />
            </span>
            {changeLanguage()}
          </div>
          <MobileCard
            dataLoaded={regIsLoaded}
            tabData={tabData}
            activeIndex={activeTab}
            handlePreviousTabClick={handlePreviousTabClick}
          />
        </div>
      ) : (
        <div className={styles.joinPowurDesktopView}>
          <div>
            <span
              tabIndex={0}
              role="button"
              onClick={() => history.push("/join")}
              onKeyDown={() => history.push("/join")}
            >
              <img className={styles.powurLogo} src="/img/powur-logo.png" alt="Powur Logo" />
            </span>
            <DesktopCard
              dataLoaded={regIsLoaded}
              tabData={tabData}
              activeIndex={activeTab}
              handlePreviousTabClick={handlePreviousTabClick}
            />
            {changeLanguage()}
          </div>
        </div>
      )}
    </div>
  );
};

CreateAccount.propTypes = {
  getSellerApplicationAgreement: PropTypes.func,
  sellerTerms: PropTypes.shape({
    documentPath: PropTypes.string,
    message: PropTypes.string,
    version: PropTypes.string,
  }),
  createNewUser: PropTypes.func,
  latestMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    payload: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  regInfo: Registration.types(),
  getRegistration: PropTypes.func,
  upgradeAmbassador: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    sellerTerms: "selectSellerApplicationAgreement",
    requests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
    regInfo: "getRegistration",
  }),
  getRdxActionMapper([
    "getSellerApplicationAgreement",
    "createNewUser",
    "getRegistration",
    "upgradeAmbassador",
    "setAlertMessageVisible",
  ]),
)(CreateAccount);

import React from "react";
import { Grid, SxProps, Theme } from "@mui/material";

import { CircleCloseIcon } from "components/Icons";

import { isGeneric, isLocation, isUser, isTeam } from "./guards";
import * as S from "./styles";
import { Avatar } from "./Avatar";

type OutsideTagsPropsT = {
  selectedOptions: Record<string, unknown>[];
  type: "user" | "leadOwner" | "leadSourceName" | "tier3" | "location" | "utility" | "team" | "installer";
  handleRemove: (id: string | number) => void;
  disableAvatar?: boolean;
  customStyles?: SxProps<Theme>;
};

const OutsideTags = ({ selectedOptions, type, handleRemove, disableAvatar, customStyles }: OutsideTagsPropsT) => {
  const handleAvatarContent = (option: Record<string, unknown>) => {
    if (isUser(option)) return option.avatarUrls.thumb;
    if (isTeam(option)) return option.shortName;
    return null;
  };

  return (
    <Grid mt={1} container>
      {selectedOptions.map((option) => (
        <Grid key={`${option.id}-${type}`} mb={1} item xs={12}>
          <S.OutsideSelectedOption sx={customStyles}>
            {!disableAvatar && <Avatar avatarContent={handleAvatarContent(option)} type={type} />}
            {isUser(option) && <S.OutsideSelectedOptionText>{option.fullName}</S.OutsideSelectedOptionText>}
            {isLocation(option) && <S.OutsideSelectedOptionText>{option.id}</S.OutsideSelectedOptionText>}
            {isGeneric(option) && <S.OutsideSelectedOptionText>{option.name}</S.OutsideSelectedOptionText>}
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                handleRemove(option.id as string | number);
              }}
              onClick={() => {
                handleRemove(option.id as string | number);
              }}
            >
              <CircleCloseIcon width="14px" />
            </span>
          </S.OutsideSelectedOption>
        </Grid>
      ))}
    </Grid>
  );
};

export default OutsideTags;

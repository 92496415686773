import React from "react";
import { UserOutlined } from "@ant-design/icons";
import Tooltip from "components/CustomizedPopover/Tooltip";
import BasicAvatar from "./BasicAvatar";
import styles from "./BadgeAvatar.module.less";

type BadgeAvatarT = {
  avatarContent: string;
  avatarIcon: React.ReactNode | string;
  avatarStyle: Record<string, unknown>;
  badgeContent: React.ReactNode;
  badgeStyle: React.CSSProperties;
  showBadge: boolean;
  size: number;
  thumb: string;
  tooltipContent: React.ReactNode | string;
};

function BadgeAvatar({
  badgeContent,
  showBadge,
  thumb,
  size,
  avatarStyle,
  avatarContent,
  avatarIcon,
  tooltipContent,
  badgeStyle,
}: BadgeAvatarT) {
  return (
    <div className={styles.badgeAvatarContainer}>
      <Tooltip
        overlay={tooltipContent}
        placement="topLeft"
        overlayClassName={tooltipContent ? styles.badgeAvatarTooltip : styles.hidden}
      >
        {showBadge && (
          <div className={styles.badgeContainer} style={badgeStyle}>
            {badgeContent}
          </div>
        )}
      </Tooltip>
      <BasicAvatar
        size={size || 48}
        thumb={thumb}
        avatarIcon={avatarIcon || <UserOutlined />}
        avatarStyle={avatarStyle}
      >
        {avatarContent}
      </BasicAvatar>
    </div>
  );
}

export default BadgeAvatar;

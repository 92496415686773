// React/Redux
import React, { useContext } from "react";
import PropTypes from "prop-types";

import * as _ from "lodash";
import { Select, Form } from "antd";
import { CaretBundle } from "@icons";

// Components
import Tag from "components/Tags/mention";

import styles from "./MultiSelectList.module.less";

const { Option } = Select;
const { Item } = Form;

const MultiSelectList = (props) => {
  const { choices, disabled, itemName, label, placeholder, rules } = props;

  return (
    <div className={styles.multiSelectContainer}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.selectContainer}>
        <div className={styles.caretIcon}>
          <CaretBundle />
        </div>
        <Item name={itemName} rules={rules}>
          <Select mode="multiple" className={styles.multiSelect} disabled={disabled} placeholder={placeholder}>
            {choices &&
              choices.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  label={<Tag title={option.label} style={{ padding: "0" }} />}
                >
                  {option.label}
                </Option>
              ))}
          </Select>
        </Item>
      </div>
    </div>
  );
};

MultiSelectList.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  itemName: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.objectOf(PropTypes.string),
};

export default MultiSelectList;

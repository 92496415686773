import { all, put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { camelizeKeys, formatCrmActivityLog } from "lib/helpers";
import { CrmActivityDeletePayloadT, CrmActivityT } from "types/Project/crmActivity";
import messages from "../messages";

type CrmActivityDeleteParamsT = {
  payload: CrmActivityDeletePayloadT;
};

function* deleteCrmActivity(action: CrmActivityDeleteParamsT) {
  const { activityId, leadId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/sales_enablement_activities/${activityId}`, {});

  if (success && data) {
    const updatedActivity = camelizeKeys(data.body);

    const kanbanData = yield select(selectors.selectProviderLeadsKanban);
    const existingActivityLog = yield select(selectors.selectCrmActivityLogFromDetail);
    const existingCrmActivities = yield select(selectors.selectProjectCrmActivities);
    const updatedCrmActivities = existingCrmActivities.filter((activity: CrmActivityT) => activity.id !== activityId);

    const idxOfUpdatedProject = kanbanData.providerLeads.findIndex((project) => project.homeowner.id === leadId);
    const idxOfActivity = kanbanData.providerLeads[idxOfUpdatedProject]?.activities?.findIndex(
      (activity: CrmActivityT) => activity.id === activityId,
    );

    const updatedActivityLog = formatCrmActivityLog({ activityId, activityLog: existingActivityLog });

    const preSalesList = yield select(selectors.getProjectPreSales);
    const idxOfPreSalesProject = preSalesList.projects.findIndex((project) => project.leadId === action.payload.leadId);
    const updatedPreSales = [...preSalesList.projects];

    if (updatedPreSales[idxOfPreSalesProject] && updatedActivity.kind !== "email") {
      updatedPreSales[idxOfPreSalesProject].activities = updatedPreSales[idxOfPreSalesProject].activities.filter(
        (activity) => activity.id !== activityId,
      );
    }

    yield all([
      ...(kanbanData.providerLeads.length
        ? [
            put(
              actions.updateCrmActivityInList({
                projectIdx: idxOfUpdatedProject,
                activityIdx: idxOfActivity,
                updatedActivity,
              }),
            ),
          ]
        : []),
      put(
        actions.newSuccessEvent({
          message: messages.CRM_ACTIVITY_DELETE_SUCCESS,
        }),
      ),
      put(actions.setCrmActivityLog(updatedActivityLog)),
      put(actions.updateProjectCrmActivities(updatedCrmActivities)),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_CRM_ACTIVITY,
    });
  }
  return null;
}

export default deleteCrmActivity;

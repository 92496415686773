import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enrollment/messages";

function* getEnrollments() {
  const { success, data, error } = yield* makeRequest.get("/services/powur_energy/enrollment");

  if (success && data) {
    const { enrollment, service, monthly_total, balance, card_info } = data.body;

    const cardInfo =
      card_info.exp_month && card_info.exp_year
        ? {
            expMonth: card_info.exp_month.toString(),
            expYear: card_info.exp_year.toString(),
            last4: card_info.last4,
          }
        : {};

    yield all([
      put(actions.setEnrollments(enrollment)),
      put(actions.setServices(service)),
      put(actions.setMonthlyTotal(monthly_total)),
      put(actions.setCredit(balance < 0 ? Math.abs(balance) : 0)),
      put(actions.setCardInfo(cardInfo)),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_ENROLLMENTS,
    });
  }

  return null;
}

export default getEnrollments;

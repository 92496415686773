// import { get } from 'lodash';

export default {
  getUsersList: (state) => state.users.usersList,
  getUsersFilters: (state) => state.users.usersList.filters,
  getRoles: (state) => state.users.rolesList.roles,
  getPermissions: (state) => state.users.rolesList.permissions,
  getUserDetails: (state) => state.users.userDetails,
  getMentorRatings: (state) => state.users.mentorRatings,
  getUserName: (state) => state.users.userName,
  getUsersAutoCompleteSuggestions: (state) => state.users.autoCompleteSuggestions,
  getEntities: (state) => state.users.rolesList.entities,
  getOperations: (state) => state.users.rolesList.operations,
  getTeamsList: (state) => state.users.teamsList,
  getLitmosLoginLink: (state) => state.users.litmosLoginLink,
  selectMentorUserLookups: (state) => state.users.mentorLookupSuggestions,
  getMentorRatingsUnsaved: (state) => state.users.unsavedMentorRatings,
  selectSellerApplicationAgreement: (state) => state.users.applicationAgreement,
  selectMarketingToken: (state) => state.users.marketingToken,
};

import { ApplicationResponseT, ReferrerT } from "models/EnterpriseApplication";
import createReducer from "rdx/utils/createReducer";
import { AnyAction } from "redux";
import types from "./types";

type BackgroundCheckT = {
  createdAt: string;
  email: string;
  enterpriseApplicationId: number;
  firstName: string;
  id: number;
  lastName: string;
  phone: string;
  positionHeld: string;
  updatedAt: string;
};

type EnterpriseSharedTypes = {
  businessCity: string;
  businessName: string;
  businessState: string;
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerPhone: string;
  referrer: ReferrerT;
  updatedAt: string;
};

type EnterpriseApplicationDetail = EnterpriseSharedTypes & {
  adminNotes: string | null;
  applicantId: number;
  backgroundChecks: BackgroundCheckT[];
  businessStreet: string;
  businessZip: string;
  id: number;
  locale: string;
  monthlyRevenue: number;
  monthlySales: number;
  numberOfReps: number;
  primaryLeadSource: string;
  response: null | ApplicationResponseT;
  sellerValue: string;
  serviceAreas: Record<string, string>[];
  statesOperatedIn: string[];
  yearsInBusiness: number;
};

type EnterpriseApplicationList = EnterpriseSharedTypes & {
  enrolled: boolean;
  id: number;
  response: {
    decision: null | string;
  };
};

type ApplicationsList = {
  paging: {
    pageSize: number;
    pageNumber: number;
    itemCount: number;
  };
  list: EnterpriseApplicationList[];
};

type EnterpriseApplicationJob = {
  results: boolean;
  state: string;
  ts: string;
};

type CSVJobs = {
  filteredEnterpriseApplicationsReportJob: EnterpriseApplicationJob;
};

type EnterpriseApplicationCsv = {
  createdAt: string;
  id: number | null;
  jobName: string;
  jobs: CSVJobs;
  name: string;
  updatedAt: string;
  url: string;
  userId: number | null;
};

export type EnterpriseApplicationState = {
  applicationsList: ApplicationsList;
  detail: EnterpriseApplicationDetail;
  user: Record<string, unknown>; // temp avoid for using any
  enterpriseApplicationCsvData: EnterpriseApplicationCsv;
};

const initialState: EnterpriseApplicationState = {
  applicationsList: {
    paging: {
      pageSize: 50,
      pageNumber: 1,
      itemCount: 0,
    },
    list: [] as EnterpriseApplicationList[],
  },
  detail: {} as EnterpriseApplicationDetail,
  user: {},
  enterpriseApplicationCsvData: {
    createdAt: "",
    id: null,
    jobName: "",
    jobs: {
      filteredEnterpriseApplicationsReportJob: {
        results: true,
        state: "",
        ts: "",
      },
    },
    name: "",
    updatedAt: "",
    url: "",
    userId: null,
  },
};

export default {
  enterpriseApplications: createReducer(initialState, {
    [types.SET_ENTERPRISE_APPLICATIONS](state: EnterpriseApplicationState, action: AnyAction) {
      return {
        ...state,
        applicationsList: action.payload,
      };
    },

    [types.SET_ENTERPRISE_APPLICATION_DETAIL](state: EnterpriseApplicationState, action: AnyAction) {
      return {
        ...state,
        detail: action.payload,
      };
    },

    [types.SET_LABOR_APPLICATION_STATUS](state: EnterpriseApplicationState, action: AnyAction) {
      return {
        ...state,
        user: action.payload,
      };
    },

    [types.SET_ENTERPRISE_APPLICATIONS_CSV](state: EnterpriseApplicationState, action: AnyAction) {
      return {
        ...state,
        enterpriseApplicationCsvData: action.payload,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};

import React from "react";
import PropTypes from "prop-types";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import SessionClient from "util/SessionClient";

import User from "models/User";
import AlertButton from "components/Buttons/AlertButton";

import styles from "./PlatformPageHeader.module.less";

const ActivateButton = ({ updateActivateAcctBtnClicked, currentUser }) => {
  const history = useHistory();
  const session = new SessionClient();
  const { backOfficeAccess, isEnterpriseProOwner, isEnterpriseProSeatedUser } = session;

  const certificationType = () => {
    if (isEnterpriseProOwner) {
      return "enterprise_owner";
    }
    if (isEnterpriseProSeatedUser) {
      return "enterprise_seated_user";
    }
    return "tier_1";
  };

  const options = {
    needs_certification: {
      text: "Get Certified",
      onClick: () => session.user.getLitmosLoginLink(certificationType()),
    },
    needs_activation: {
      text: "Activate Your Account",
      onClick: () => {
        if (currentUser.manualKindSelected) {
          updateActivateAcctBtnClicked();
        }
        history.push(`/getting-started/account-types${currentUser.manualKindSelected ? "/payment" : ""}`);
      },
    },
  };

  if (!options[backOfficeAccess]) return null;

  return (
    <div className={styles.activateButtonContainer}>
      <AlertButton
        width={230}
        buttonText={options[backOfficeAccess].text}
        handleClick={options[backOfficeAccess].onClick}
      />
    </div>
  );
};

ActivateButton.propTypes = {
  updateActivateAcctBtnClicked: PropTypes.func,
  currentUser: User.types(),
};

// ActivateButton.defaultProps = {};

export default connect(
  getRdxSelectionMapper({
    currentUser: "getCurrentUser",
  }),
  getRdxActionMapper(["updateActivateAcctBtnClicked"]),
)(ActivateButton);

import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/enterpriseProjects/selectors";
import adminProjectsSelectors from "rdx/modules/adminProjects/selectors";

import { keysToCamelCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* requestProposal(action) {
  const { id, realTime, enterprise, isKanban, proposalNotes, proposalType } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/request_proposal`, {
    proposal_notes: proposalNotes,
    real_time: realTime,
    enterprise,
    tpo: proposalType,
  });

  if (success && data) {
    const enterpriseProjects = yield select(selectors.getEnterpriseProjectsList);
    const unchangedEnterpriseProjects = enterpriseProjects.projects.filter((p) => p.id !== id);

    const adminListProjects = yield select(adminProjectsSelectors.getAdminProjectsList);
    const { projects: adminProjects } = adminListProjects;

    const updatedAdminProject = adminProjects.find((project) => project.id === id);
    const unchangedAdminProjects = adminProjects.filter((project) => project.id !== id);

    if (updatedAdminProject) {
      updatedAdminProject.canRequestProposal = data.body.can_request_proposal;
      updatedAdminProject.proposalNonrequestableReason = data.body.proposal_nonrequestable_reason;
    }

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.REQUEST_PROPOSAL_SUCCESS,
        }),
      ),
      put(actions.setProjectDetails({ providerLead: keysToCamelCase(data.body) })),

      put(actions.setAdminProjectsList({ ...adminProjects, projects: unchangedAdminProjects })),
      put(actions.setEnterpriseProjectsList({ ...enterpriseProjects, unchangedEnterpriseProjects })),
      put(actions.getProjectsPreSales()),
      put(
        actions.setAlertMessageVisible({
          message: messages.REQUEST_PROPOSAL_SUCCESS,
          severity: "success",
        }),
      ),
      ...(isKanban ? [put(actions.setProviderLeadStageInKanban({ providerLead: camelizeKeys(data.body) }))] : []),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REQUESTING_PROPOSAL,
    });
  }
  return null;
}

export default requestProposal;

import React from "react";
import { useTranslation } from "react-i18next";
import useNavigation from "hooks/useNavigation";
import AccountTypesTab from "containers/Sales/gettingStarted/components/AccountTypesTab";
import styles from "./PricingAndPlans.module.less";

const PricingAndPlans = () => {
  const { t } = useTranslation(["account_types"]);
  const { navigate } = useNavigation("enterprise", ["tab"], ["modal"]);

  return (
    <div className={styles.plansContainer}>
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.sectionTitle}>{t("pricing_and_plans.section_title")}</div>
          <div className={styles.description}>{t("pricing_and_plans.section_description")}</div>
        </div>
        <AccountTypesTab noCurrentUser navigate={navigate} />
      </div>
    </div>
  );
};

export default PricingAndPlans;

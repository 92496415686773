import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AccountTypeCard from "../AccountTypeCard";

import { AccountDetailsT, EnterpriseAppStatusT } from "../types";
import * as S from "./styles";

type SelectAccountTypePropsT = {
  accountData: AccountDetailsT[];
  noCurrentUser: boolean;
  enterpriseAppStatus: EnterpriseAppStatusT;
  onAccountTypeSelect: (acct: AccountDetailsT) => void;
};

const SelectAccountType = ({
  accountData,
  noCurrentUser,
  enterpriseAppStatus,
  onAccountTypeSelect,
}: SelectAccountTypePropsT) => {
  const [viewAll, setViewAll] = useState(false);
  const { t } = useTranslation(["account_types"]);

  return (
    <S.AcctTypesCardsContainer hasBackground={!noCurrentUser}>
      <S.AcctTypesContent>
        <S.AcctTypesHeader>
          <S.AcctTypesTitle>{t("pricing_and_plans.title") as string}</S.AcctTypesTitle>
          <S.AcctTypesHeaderDetails>{t("pricing_and_plans.subtitle") as string}</S.AcctTypesHeaderDetails>
        </S.AcctTypesHeader>
        <S.AcctTypeCardsRow gap={noCurrentUser ? 30 : 8}>
          {accountData.map((acct) => {
            return (
              <S.AcctTypeCardsCol key={acct.id}>
                <AccountTypeCard
                  accountDetails={acct}
                  noCurrentUser={noCurrentUser}
                  handleClick={onAccountTypeSelect}
                  viewAll={viewAll}
                  setViewAll={setViewAll}
                  enterpriseAppStatus={enterpriseAppStatus}
                />
              </S.AcctTypeCardsCol>
            );
          })}
        </S.AcctTypeCardsRow>
      </S.AcctTypesContent>
    </S.AcctTypesCardsContainer>
  );
};

export default SelectAccountType;

import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const LightbulbOutlineIcon = ({ height = 21, width = 21, fill = cvar("darkest-blue") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 21 " fill={fill}>
      <g clipPath="url(#clip0_478_9528)">
        <path
          d="M12.4688 18.375H8.53125C8.169 18.375 7.875 18.081 7.875 17.7188C7.875 17.3565 8.169 17.0625 8.53125 17.0625H12.4688C12.831 17.0625 13.125 17.3565 13.125 17.7188C13.125 18.081 12.831 18.375 12.4688 18.375Z"
          fill="#002160"
        />
        <path
          d="M10.5 3.02488C10.1377 3.02488 9.84375 2.73088 9.84375 2.36863V0.65625C9.84375 0.294 10.1377 0 10.5 0C10.8622 0 11.1562 0.294 11.1562 0.65625V2.36863C11.1562 2.73088 10.8622 3.02488 10.5 3.02488Z"
          fill="#002160"
        />
        <path
          d="M16.2498 5.40697C16.0818 5.40697 15.9138 5.34309 15.786 5.21447C15.5297 4.95809 15.5297 4.54247 15.786 4.28609L16.997 3.07509C17.2534 2.81872 17.669 2.81872 17.9254 3.07509C18.1818 3.33147 18.1818 3.74709 17.9254 4.00347L16.7144 5.21447C16.5858 5.34222 16.4178 5.40697 16.2498 5.40697Z"
          fill="#002160"
        />
        <path
          d="M20.3437 11.1562H18.6313C18.2691 11.1562 17.9751 10.8622 17.9751 10.5C17.9751 10.1377 18.2691 9.84375 18.6313 9.84375H20.3437C20.706 9.84375 21 10.1377 21 10.5C21 10.8622 20.706 11.1562 20.3437 11.1562Z"
          fill="#002160"
        />
        <path
          d="M17.4608 18.1169C17.2928 18.1169 17.1248 18.0531 16.997 17.9244L15.786 16.7134C15.5297 16.4571 15.5297 16.0414 15.786 15.7851C16.0424 15.5287 16.458 15.5287 16.7144 15.7851L17.9254 16.9961C18.1818 17.2524 18.1818 17.6681 17.9254 17.9244C17.7968 18.0531 17.6288 18.1169 17.4608 18.1169Z"
          fill="#002160"
        />
        <path
          d="M3.53933 18.1169C3.37133 18.1169 3.20333 18.0531 3.07558 17.9244C2.81921 17.6681 2.81921 17.2524 3.07558 16.9961L4.28658 15.7851C4.54296 15.5287 4.95858 15.5287 5.21496 15.7851C5.47133 16.0414 5.47133 16.4571 5.21496 16.7134L4.00396 17.9244C3.87533 18.0531 3.70733 18.1169 3.53933 18.1169Z"
          fill="#002160"
        />
        <path
          d="M2.36863 11.1562H0.65625C0.294 11.1562 0 10.8622 0 10.5C0 10.1377 0.294 9.84375 0.65625 9.84375H2.36863C2.73088 9.84375 3.02488 10.1377 3.02488 10.5C3.02488 10.8622 2.73088 11.1562 2.36863 11.1562Z"
          fill="#002160"
        />
        <path
          d="M4.75033 5.40697C4.58233 5.40697 4.41433 5.34309 4.28658 5.21447L3.07558 4.00347C2.81921 3.74709 2.81921 3.33147 3.07558 3.07509C3.33196 2.81872 3.74758 2.81872 4.00396 3.07509L5.21496 4.28609C5.47133 4.54247 5.47133 4.95809 5.21496 5.21447C5.08546 5.34222 4.91833 5.40697 4.75033 5.40697Z"
          fill="#002160"
        />
        <path
          d="M13.7812 10.9375C13.419 10.9375 13.125 10.6435 13.125 10.2812C13.125 8.95388 12.0461 7.875 10.7188 7.875C10.3565 7.875 10.0625 7.581 10.0625 7.21875C10.0625 6.8565 10.3565 6.5625 10.7188 6.5625C12.7689 6.5625 14.4375 8.23113 14.4375 10.2812C14.4375 10.6435 14.1435 10.9375 13.7812 10.9375Z"
          fill="#002160"
        />
        <path
          d="M11.5936 21H9.40607C8.66845 21 7.87482 20.44 7.87482 19.2115V17.668C7.87482 16.7396 7.47932 15.8874 6.81695 15.3886C4.90507 13.9493 4.02045 11.5815 4.50782 9.2094C4.9847 6.8854 6.86507 4.99802 9.18645 4.5124C11.0318 4.12477 12.9209 4.57365 14.3612 5.7444C15.7997 6.9134 16.6248 8.64677 16.6248 10.5C16.6248 12.3883 15.7717 14.1409 14.2842 15.3108C13.5474 15.89 13.1248 16.6968 13.1248 17.5236V19.4688C13.1248 20.3131 12.4379 21 11.5936 21ZM10.4876 5.68927C10.1446 5.68927 9.79895 5.72515 9.45332 5.7969C7.63682 6.17665 6.16595 7.65365 5.79232 9.47277C5.4082 11.3426 6.10295 13.2081 7.60532 14.3395C8.59495 15.085 9.18645 16.3293 9.18645 17.668V19.2115C9.18645 19.2911 9.19695 19.6875 9.4052 19.6875H11.5927C11.7134 19.6875 11.8114 19.5895 11.8114 19.4688V17.5236C11.8114 16.2908 12.4169 15.1086 13.4722 14.2783C14.6421 13.3595 15.3123 11.9823 15.3123 10.5C15.3123 9.04402 14.6631 7.68165 13.5326 6.7629C12.6681 6.06115 11.5962 5.68927 10.4876 5.68927Z"
          fill="#002160"
        />
      </g>
      <defs>
        <clipPath id="clip0_478_9528">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightbulbOutlineIcon;

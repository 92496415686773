import types from "rdx/modules/filterSets/types";
import createAction from "rdx/utils/createAction";

export default {
  getFilterSetsByView: (payload) => createAction(types.GET_FILTER_SETS_BY_VIEW, payload),
  createFilterSet: (payload) => createAction(types.CREATE_FILTER_SET_REQUEST, payload),
  deleteFilterSet: (payload) => createAction(types.DELETE_FILTER_SET_REQUEST, payload),
  setFilterSets: (payload) => createAction(types.SET_FILTER_SETS, payload),
  updatePreferredView: (payload) => createAction(types.UPDATE_PREFERRED_VIEW, payload),
  updatePreferredViewBulk: (payload) => createAction(types.UPDATE_PREFERRED_VIEW_BULK, payload),
  clearPreferredView: () => createAction(types.SET_CLEAR_PREFERRED_VIEW),
  setPreferredView: (payload) => createAction(types.SET_PREFERRED_VIEW, payload),
  deletePreferredViewFilter: (payload) => createAction(types.DELETE_PREFERRED_VIEW_FILTER, payload),
  getSelectedLookupFilters: (payload) => createAction(types.GET_SELECTED_LOOKUP_FILTERS, payload),
  // INSERTION_PT (for script, do not remove)
};

import types from "rdx/modules/adminProjects/types";
import createAction from "rdx/utils/createAction";

export default {
  getAdminProjectsList: (payload) => createAction(types.GET_ADMIN_PROJECTS_LIST_REQUEST, payload),

  setAdminProjectsList: (payload) => createAction(types.SET_ADMIN_PROJECTS_LIST, payload),

  resetAdminProjectsList: (payload) => createAction(types.RESET_ADMIN_PROJECTS_LIST, payload),

  resetAdminProjectDetails: (payload) => createAction(types.RESET_ADMIN_PROJECT_DETAILS, payload),

  setAdminProjectsListFilters: (payload) => createAction(types.SET_ADMIN_PROJECTS_LIST_FILTERS, payload),

  setAdminProjectsListAutoComplete: (payload) =>
    createAction(types.SET_ADMIN_PROJECTS_AUTOCOMPLETE_SUGGESTIONS, payload),

  getAdminProjectDetails: (payload) => createAction(types.GET_ADMIN_PROJECT_DETAILS_REQUEST, payload),

  setAdminProjectDetails: (payload) => createAction(types.SET_ADMIN_PROJECT_DETAILS, payload),

  getAdminProjectProposals: (payload) => createAction(types.GET_ADMIN_PROJECT_PROPOSALS_REQUEST, payload),

  setAdminProjectProposals: (payload) => createAction(types.SET_ADMIN_PROJECT_PROPOSALS, payload),

  requestAdminRevision: (payload) => createAction(types.ADMIN_REVISION_REQUEST, payload),

  requestAdminVisionRevision: (payload) => createAction(types.ADMIN_VISION_REVISION_REQUEST, payload),

  getProjectMilestones: (payload) => createAction(types.GET_PROJECT_MILESTONES_REQUEST, payload),

  setProjectMilestones: (payload) => createAction(types.SET_PROJECT_MILESTONES, payload),

  updateProjectMilestones: (payload) => createAction(types.UPDATE_PROJECT_MILESTONES_REQUEST, payload),

  updateProjectManagementUsers: (payload) => createAction(types.UPDATE_PROJECT_MANAGEMENT_USERS_REQUEST, payload),

  updateProject: (payload) => createAction(types.UPDATE_ADMIN_PROJECT_REQUEST, payload),

  updateAdminProjectLead: (payload) => createAction(types.UPDATE_ADMIN_PROJECT_LEAD_REQUEST, payload),

  getProjectNotes: (payload) => createAction(types.GET_ADMIN_PROJECT_NOTES_REQUEST, payload),

  setProjectNotes: (payload) => createAction(types.SET_ADMIN_PROJECT_NOTES, payload),

  closeAdminProject: (payload) => createAction(types.CLOSE_ADMIN_PROJECT_REQUEST, payload),

  reopenAdminProject: (payload) => createAction(types.REOPEN_ADMIN_PROJECT_REQUEST, payload),

  requestAdminProposal: (payload) => createAction(types.REQUEST_ADMIN_PROPOSAL, payload),

  requestAdminVisionProposal: (payload) => createAction(types.REQUEST_ADMIN_VISION_PROPOSAL, payload),

  setCreateAdminVisionReqSuccessful: (payload) => createAction(types.SET_CREATE_ADMIN_VISION_REQ_SUCCESS, payload),

  getAdminProjectGeocode: (payload) => createAction(types.GET_ADMIN_PROJECT_ADDRESS_GEOCODE, payload),

  setAdminProjectGeocode: (payload) => createAction(types.SET_ADMIN_PROJECT_ADDRESS_GEOCODE, payload),

  getAdminProposalSettings: (payload) => createAction(types.GET_ADMIN_PROPOSAL_SETTINGS, payload),

  setAdminProposalSettings: (payload) => createAction(types.SET_ADMIN_PROPOSAL_SETTINGS, payload),

  getAutoCompleteLookup: (payload) => createAction(types.GET_AUTOCOMPLETE_LOOKUP, payload),

  setUsersAutoCompleteLookup: (payload) => createAction(types.SET_USERS_AUTOCOMPLETE_LOOKUP, payload),

  setLocationsAutoCompleteLookup: (payload) => createAction(types.SET_LOCATIONS_AUTOCOMPLETE_LOOKUP, payload),

  setTeamsAutoCompleteLookup: (payload) => createAction(types.SET_TEAMS_AUTOCOMPLETE_LOOKUP, payload),

  setUtilitiesAutoCompleteLookup: (payload) => createAction(types.SET_UTILITIES_AUTOCOMPLETE_LOOKUP, payload),

  setInstallersAutoCompleteLookup: (payload) => createAction(types.SET_INSTALLER_AUTOCOMPLETE_LOOKUP, payload),

  getAdminProjectLookupOptions: (payload) => createAction(types.GET_ADMIN_PROJECT_LOOKUP_OPTIONS, payload),

  setAdminProjectLookupOptions: (payload) => createAction(types.SET_ADMIN_PROJECT_LOOKUP_OPTIONS, payload),

  setSelectedProjectDetail: (payload) => createAction(types.SET_SELECTED_PROJECT_DETAIL, payload),
  getSelectedProjectDetail: (payload) => createAction(types.GET_SELECTED_PROJECT_DETAIL, payload),

  setChangeOrderBlockerCompleteForAdmin: () => createAction(types.SET_CHANGE_ORDER_BLOCKER_COMPLETE_FOR_ADMIN),
  removeChangeOrderBlockerFromModalForAdmin: () => createAction(types.REMOVE_CO_BLOCKER_FROM_MODAL_FOR_ADMIN),

  // INSERTION_PT (for script, do not remove)
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions, selectors } from "rdx";
import { useEffectOnce } from "react-use";
import { CardContent, Typography, Container, Box, Card } from "@mui/material";
import learnMoreData from "containers/Sales/gettingStarted/components/LearnMoreTab/LearnMoreData";
import RightArrow2 from "components/Icons/Arrows/rightArrow2";
import { CheckIcon2 } from "components/Icons";

import { LearnMoreCardCss } from "./styles";

type TLearnMoreCard = {
  title?: string;
  subtitle?: string;
  bodyText?: string;
};
type ViewedAssetT = {
  category: string;
  createdAt: string;
  slug: string;
  viewed: boolean;
};
export const LearnMore = ({ title, subtitle, bodyText }: TLearnMoreCard) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [viewedAssets, setViewedAsset] = useState<ViewedAssetT[]>([]);
  const assets = useSelector(selectors.selectViewedAssets);

  useEffect(() => {
    if (assets?.viewableAssets) setViewedAsset(assets?.viewableAssets);
  }, [assets?.viewableAssets]);
  useEffectOnce(() => {
    dispatch(actions.getViewedAssetsTaskCompletion({ category: "learn_more" }));
  });

  const nonViewedAssets = viewedAssets.filter((i) => !i.viewed) || [];
  const nextUnviewedAsset =
    learnMoreData.tasks.filter((i) => {
      const task = i.task.toLowerCase().replaceAll(" ", "_");
      return nonViewedAssets.find((asset) => asset.slug === task);
    })?.[0]?.task || "";

  return (
    <Card css={LearnMoreCardCss.learnMoreCard}>
      <CardContent>
        <Typography css={LearnMoreCardCss.title}>{title}</Typography>
        <Typography css={LearnMoreCardCss.text}>{bodyText}</Typography>
        <Container css={LearnMoreCardCss.learnStageContainer}>
          <Typography css={LearnMoreCardCss.subtitle}>{subtitle}</Typography>
          <Box css={LearnMoreCardCss.next} role="none" onClick={() => history.push("/getting-started/learn-more")}>
            <Box css={LearnMoreCardCss.stage}>
              <Box css={LearnMoreCardCss.svg}>
                <CheckIcon2 />
              </Box>
              <Typography css={LearnMoreCardCss.stageText}>{nextUnviewedAsset}</Typography>
            </Box>
            <RightArrow2 />
          </Box>
        </Container>
      </CardContent>
    </Card>
  );
};

import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getCharges(action) {
  const { payload } = action;
  const { success, data, error } = yield* makeRequest.get("/charges", {
    ...payload,
    sort: payload.sort || "create_at",
  });

  if (success && data) {
    const formattedData = camelizeKeys(data.body);
    const newCharges = formattedData.charges.map((charge, index) => {
      return { ...charge, key: index };
    });

    yield all([put(actions.setCharges({ list: newCharges, paging: formattedData.paging }))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_CHARGES,
      // payload: {},
    });
  }

  return null;
}

export default getCharges;

import { useParams, useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import _ from "lodash";

interface UpdatesI {
  [key: string]: string | number | null | boolean;
}

type OptionsT = {
  [key: string]: boolean;
};

type NavigateT = (updates: UpdatesI, opts?: OptionsT) => void;

export type UseNavigation = (
  routeName: string,
  requiredParams: string[],
  optionalParams?: string[],
) => {
  navigate: (updates: UpdatesI, opts?: OptionsT) => void;
  override: (updates: UpdatesI, opts?: OptionsT) => void;
};

/** Custom hook for generating page-specific navigate and replace navigation functions.
 * @example
 * In the top-level component rendered by react-router
    import useNavigation from "hooks/useNavigation";
    const { navigate, override } = useNavigation(
      "..." // static path
      ["...", "..."] // any required params
      ["...", "..."] // any optional params
    )
    and pass the resulting functions to your child components to execute context-specific navigation actions.
 * @param {string} routeName - the static route path that should remain unchanged from a navigate or override action. e.g. in the Projects component rendered by the route "/projects", set routeName = "projects" to leave the static path "/projects" unchanged and make updates to parameters and query variables under this path.
 * @param {string[]} requiredParams - an array of parameter keys that are required for the top-level component rendered by the route. e.g. in the Projects component, we have one required parameter "tab", which determines the active tab within the view. we must always have a value for this parameter, so we set requiredParams = ["tab"]
 * @param {string[]} [optionalParams = []] - an array of optional parameters that can safely be left undefined for the top-level component to function. e.g. in Projects, we have a parameter for "lead-id", which if defined will open a detail view drawer, but if undefined will leave the drawer closed. we include "lead-id" in the optionalParams array in order to allow that parameter to be undefined.
 * @returns {{override: override, navigate: navigate}} an object with two functions: navigate and override. use navigate to add a new entry to the browser history, override to replace the current entry in the history stack with a new entry.
 */

const useNavigation: UseNavigation = (routeName, requiredParams, optionalParams = []) => {
  const history = useHistory();
  const params = useParams();
  const {
    location: { search },
  } = history;
  const allPageParams = [...requiredParams, ...optionalParams];

  const updateUrl = (updates: UpdatesI, opts: OptionsT = {}) => {
    let nextUrl = `/${routeName}`;
    // output slash-separated param string. remove param from string if updates.param = "none"
    const requiredParamString = requiredParams
      .map((p) => {
        // if update specifies new value for param p, include it in the string, otherwise fall back to current value
        if (updates[p]) return `/${updates[p]}`;
        return `/${params[p as keyof typeof params]}`;
      })
      .join("");

    const optionalParamString = optionalParams
      .map((p) => {
        let value: string | true | number | null = updates[p] || params[p as keyof typeof params];
        if (value === "none") value = null;
        if (value) return `/${value}`;
        return undefined;
      })
      .join("");

    nextUrl = nextUrl + requiredParamString + optionalParamString;

    let newQuery: UpdatesI = {};
    const reqParamsUpdated = !requiredParams.every((p) => !Object.keys(updates).includes(p));

    // delete the query string from the next URL altogether if one of the required params has updated, explicitly set opts.noQueryString to override this default behavior
    const blankQueryString = opts.noQueryString === undefined ? reqParamsUpdated : opts.noQueryString;

    if (!blankQueryString) {
      const currentQuery = parse(search);
      newQuery = { ...(currentQuery as Record<string, string>), ...updates };
      if (opts.clearPrevQuery) newQuery = { ...updates };
      // if updates includes a page parameter, do not include it in the query string
      Object.keys(newQuery).forEach((p) => {
        if (allPageParams.includes(p) || newQuery[p as keyof typeof newQuery] === null) {
          delete newQuery[p as keyof typeof newQuery];
        }
      });

      // trim p from query string if update does not explicitly state to update the page, behavior should remove page any time a user navigates to a new tab or updates filter variables and set the list back to the first page.
      const checkDeletePage = () => {
        if (reqParamsUpdated) return true;
        const newQueryClone = _.clone(newQuery);
        delete currentQuery.p;
        delete newQueryClone.p;
        if (!_.isEqual(currentQuery, newQueryClone)) return true;
        return false;
      };
      if (checkDeletePage()) delete newQuery.p;

      if (!_.isEmpty(newQuery)) nextUrl = `${nextUrl}?${stringify(newQuery)}`;
    }

    return nextUrl;
  };

  /** Use navigate to add a new entry to the browser history
   * @function navigate
   * @param {object} updates - A collection of key-value pairs that we want to update in the URL. if the update key is included in either the requiredParams or optionalParams arrays, it updates that url parameter, if not the key-value pair is appended to the url as part of a query string.
   * @param {object} [opts] - Options to tweak the logic of the functions. currently only has one option that will do anything, noQueryString: true will return the url with no query string no matter the contents of the updates object.
   */

  const navigate = (updates: UpdatesI, opts: OptionsT = {}) => {
    if (!_.isEmpty(updates) || !_.isEmpty(opts)) {
      history.push(updateUrl(updates, opts));
    }
  };

  /** Use override to replace the current entry in the history stack with a new entry
   * @function override
   * @param {object} updates - A collection of key-value pairs that we want to update in the URL. if the update key is included in either the requiredParams or optionalParams arrays, it updates that url parameter, if not the key-value pair is appended to the url as part of a query string.
   * @param {object} [opts] - Options to tweak the logic of the functions. currently only has one option that will do anything, noQueryString: true will return the url with no query string no matter the contents of the updates object.
   */

  const override = (updates: UpdatesI, opts: OptionsT = {}): void => {
    if (!_.isEmpty(updates) || !_.isEmpty(opts)) {
      history.replace(updateUrl(updates, opts));
    }
  };

  return { navigate, override };
};

export type { NavigateT };
export default useNavigation;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import UserDisplay from "../UserDisplay";
import styles from "./styles.module.less";

const { Option } = Select;

const Dropdown = (props) => {
  const { users, darkTheme, onSelect, selectProps, children } = props;

  const options = useMemo(() => {
    if (children) return children;
    const out = [];
    for (const u of users) {
      const isTeam = !!u.color;
      const userLabel =
        u.label === "Consultant" || u.label === "Tier 3 Sales Partner" || u.label === "Project Manager"
          ? ` (${u.label})`
          : "";
      const name = u.fullName ? `${u.fullName}${userLabel}` : u.label;
      const disabled = isTeam && !u.assignable;
      const o = (
        <Option
          key={u.id}
          value={u.id}
          className={disabled ? styles.disabledOption : styles.option}
          disabled={disabled}
          data={u}
          label={u.dropdownRender ? u.dropdownRender : <div className={styles.selected}>{name}</div>}
        >
          {isTeam ? <UserDisplay team={u} disabled={disabled} /> : <UserDisplay user={{ ...u, fullName: name }} />}
        </Option>
      );
      out.push(o);

      if (isTeam && !!u.users) {
        for (const m of u.users) {
          const s = (
            <Option
              key={`${u.id}-${m.id}`}
              value={m.id}
              className={styles.option}
              data={m}
              label={m.dropdownRender ? m.dropdownRender : <div className={styles.selected}>{m.fullName}</div>}
            >
              <div style={{ marginLeft: "20px" }}>
                <UserDisplay user={m} />
              </div>
            </Option>
          );
          out.push(s);
        }
      }
    }
    return out;
  }, [users, children]);

  return (
    <div className={darkTheme ? styles.dropdownDark : styles.dropdown}>
      <Select
        className={styles.select}
        optionLabelProp="label"
        onSelect={onSelect}
        dropdownMatchSelectWidth={false}
        {...selectProps}
      >
        {options}
      </Select>
    </div>
  );
};

Dropdown.propTypes = {
  // Users can be an array of users or an array of teams, each with a
  // users property containing its team members. In the case when users
  // is a team, will render team members indented underneath
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      dropdownRender: PropTypes.node,
      fullName: PropTypes.string,
      label: PropTypes.string,
      shortName: PropTypes.string,
      color: PropTypes.string,
      avatar: PropTypes.shape({
        thumb: PropTypes.string,
        retina: PropTypes.string,
        large: PropTypes.string,
        preview: PropTypes.string,
      }),
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          dropdownRender: PropTypes.node,
          fullName: PropTypes.string,
          avatar: PropTypes.shape({
            thumb: PropTypes.string,
            retina: PropTypes.string,
            large: PropTypes.string,
            preview: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  darkTheme: PropTypes.bool,
  onSelect: PropTypes.func,
  // pass any props directly to the antd Select component via selectProps
  selectProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
};

Dropdown.defaultProps = {
  darkTheme: false,
  selectProps: {},
  onSelect: () => null,
};

export default Dropdown;

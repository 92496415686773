import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/enrollment/messages";

import actions from "rdx/actions";

function* enrollmentIntent(action) {
  const { payload } = action;

  const { success, data, error } = yield* makeRequest.post(`/services/${payload.service}/enrollment/intent`);

  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.ENROLLMENT_INTENT_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ENROLLMENT_INTENT_ERROR,
    });
  }
  return null;
}

export default enrollmentIntent;

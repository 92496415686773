import React, { FC } from "react";

import { buttonProps } from "lib/props";
import SessionClient from "util/SessionClient";
import useWindowResize from "hooks/useWindowResize";

import { rightArrow2 as Arrow, MortarCapIcon } from "components/Icons";
import styles from "./CertificationBanner.module.less";

const CertificationBanner: FC = () => {
  const { width } = useWindowResize();
  const tablet = width < 600;
  const mobile = width < 400;

  const rightTitle = `${mobile ? "" : "Complete the"} Tier 2 Certification Course`;

  const session = new SessionClient();

  return (
    <div
      className={styles.certificationBannerContainer}
      {...buttonProps(() => {
        session?.user?.getLitmosLoginLink("tier_2");
      })}
    >
      <div className={styles.inner}>
        <div className={styles.left}>
          <span className={styles.mortar}>
            <MortarCapIcon height={19} width={29} fill="var(--button-blue)" />
          </span>
          {!tablet && <span className={styles.title}>Become Tier 2 Certified</span>}
        </div>
        <div className={styles.right}>
          <span className={styles.title}>{rightTitle}</span>
          <span className={styles.arrow}>
            <Arrow fill="var(--dark-blue)" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CertificationBanner;

import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { actions, selectors } from "rdx";
import messages from "rdx/modules/users/messages";

import { formatPhoneNumber } from "lib/helpers";
import { cvar } from "styles/colorVariables";
import DefaultAvatar from "components/Icons/Avatars/DefaultAvatar";
import { PrimaryButton } from "components/mui-styled-components";
import { SponsorT } from "../../types";

import * as S from "./styles";

type SelectSponsorCardPropsT = {
  sponsor: SponsorT;
  onSponsorSelect: () => void;
  setSponsorSelectClicked: (bool: boolean) => void;
  disableButton: boolean;
};

const SelectSponsorCard = ({
  sponsor,
  onSponsorSelect,
  setSponsorSelectClicked,
  disableButton,
}: SelectSponsorCardPropsT) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const currentUser = useSelector(selectors.getCurrentUser);
  const latestMessage = useSelector(selectors.getLatestMessageEvt);
  const isPendingSeatInvite = sponsor.isSeatInvite && sponsor.pendingSeatId;

  const handleChooseSponsor = () => {
    if (isPendingSeatInvite) {
      history.push({
        pathname,
        search: `?seat_offer_modal=${sponsor.pendingSeatId}&sponsor_id=${sponsor.id}`,
      });
    } else {
      setSponsorSelectClicked(true);
      dispatch(actions.changeUserSponsor({ userId: currentUser.id, sponsorId: sponsor.id }));
      dispatch(
        actions.setAlertMessageVisible({
          message: "Submitting. . .",
          severity: "info",
          duration: 6000,
        }),
      );
    }
  };

  useEffect(() => {
    if (latestMessage?.message === messages.CHANGE_USER_SPONSOR_SUCCESS) {
      onSponsorSelect();
      dispatch(actions.clearAlertMessage());
    } else if (latestMessage?.message === messages.ERROR_CHANGING_USER_SPONSOR) {
      setSponsorSelectClicked(false);
      dispatch(
        actions.setAlertMessageVisible({
          message: messages.ERROR_CHANGING_USER_SPONSOR,
          severity: "error",
        }),
      );
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <S.SelectSponsorCard>
      <S.SponsorNameSection>
        <S.Avatar>
          {sponsor?.avatar?.thumb ? (
            <Avatar sx={{ height: "60px", width: "60px" }} src={sponsor.avatar.thumb} />
          ) : (
            <Avatar sx={{ height: "60px", width: "60px" }}>
              <DefaultAvatar />
            </Avatar>
          )}
        </S.Avatar>
        <S.HeaderContainer>
          {isPendingSeatInvite && <S.EnterpriseInvite>Enterprise Invite</S.EnterpriseInvite>}
          <S.SponsorName>{sponsor.fullName}</S.SponsorName>
          <S.SponsorAddress>
            {sponsor.address?.city}, {sponsor.address?.state}
          </S.SponsorAddress>
        </S.HeaderContainer>
      </S.SponsorNameSection>
      <S.Divider />
      <S.SponsorContactSection>
        <S.LargeLabel>Contact Info</S.LargeLabel>
        <div>
          <S.SmallLabel>Email</S.SmallLabel>
          <S.ContactText>{sponsor.email}</S.ContactText>
        </div>
        <div>
          <S.SmallLabel>Phone Number</S.SmallLabel>
          <S.ContactText>{formatPhoneNumber(sponsor.phone)}</S.ContactText>
        </div>
      </S.SponsorContactSection>
      <S.Divider />
      <S.ButtonSection>
        <PrimaryButton
          gradient
          disabled={disableButton}
          onClick={handleChooseSponsor}
          style={{ textTransform: "capitalize" }}
          stopColor={isPendingSeatInvite ? cvar("eggplant") : cvar("blue-ribbon")}
          startColor={isPendingSeatInvite ? cvar("heliotrope-magenta") : cvar("picton-blue")}
        >
          {isPendingSeatInvite ? "View Invite Details" : "Choose Sponsor"}
        </PrimaryButton>
      </S.ButtonSection>
    </S.SelectSponsorCard>
  );
};

export default SelectSponsorCard;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "rdx";
import InfoIconOutlined from "components/Icons/InfoIconOutlined";
import { SponsorT } from "../types";
import SelectSponsorCard from "./SelectSponsorCard";

import * as S from "./styles";

type SelectSponsorPropsT = {
  onSponsorSelect: () => void;
};

const SelectSponsor = ({ onSponsorSelect }: SelectSponsorPropsT) => {
  const [sponsorSelectClicked, setSponsorSelectClicked] = useState<boolean>(false);
  const currentUser = useSelector(selectors.getCurrentUser);

  return (
    <S.SelectSponsorContainer>
      <S.SelectSponsorHeader>Select Sponsor</S.SelectSponsorHeader>
      <S.SelectSponsorNote>
        <div>
          <InfoIconOutlined />
        </div>
        <S.SelectSponsorNoteText>
          Hey, we see you&apos;ve accepted multiple invite links since being introduced to Powur. Please select which
          sponsor you intended to sign up with below, you will only be able to do this once.
        </S.SelectSponsorNoteText>
      </S.SelectSponsorNote>
      <S.SponsorCardContainer>
        {currentUser.potentialSponsors.map((sponsor: SponsorT) => (
          <SelectSponsorCard
            sponsor={sponsor}
            onSponsorSelect={onSponsorSelect}
            setSponsorSelectClicked={setSponsorSelectClicked}
            disableButton={sponsorSelectClicked}
          />
        ))}
      </S.SponsorCardContainer>
    </S.SelectSponsorContainer>
  );
};

export default SelectSponsor;

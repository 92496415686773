import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import type { RootState as PreSalesProjectStateT } from "./stateTypes";

const initialState: PreSalesProjectStateT = {
  paging: {
    itemCount: 0,
    pageSize: 0,
    pageNumber: 0,
  },
  projects: [],
  selectedPresaleProject: undefined,
};

export default {
  projectPreSales: createReducer<PreSalesProjectStateT>(initialState, {
    [types.SET_PROJECTS_PRE_SALES](state, action) {
      return {
        ...state,
        paging: action.payload.paging,
        projects: action.payload.projects,
      };
    },
    [types.SET_SELECTED_PRE_SALES_PROJECT](state, action) {
      return {
        ...state,
        selectedPresaleProject: action.payload.selectedPresaleProject,
      };
    },
    [types.RESET_PROJECTS_PRE_SALES](state) {
      return {
        ...state,
        projects: initialState.projects,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, Select } from "antd";
import { states } from "lib/misc/states";
import User from "models/User";
import { CaretBundle } from "components/Icons";
import StreetAddressLookup from "./StreetAddressLookup";

import styles from "./PaymentInfo.module.less";

const AddressForm = ({ form, onFinish, currentUser }) => {
  const { t } = useTranslation(["ent_join_form_labels"]);

  const createStateOptions = () => {
    const out = states.map((state) => (
      <Select.Option key={state.short} value={state.short}>
        {state.long}
      </Select.Option>
    ));
    return out;
  };

  return (
    <div className={styles.addressFormContainer}>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <span className={styles.inputLabel}>{t("first_name")}</span>
            <Form.Item
              name="firstName"
              validateTrigger={["onBlur", "onSubmit"]}
              rules={[
                {
                  required: true,
                  message: t("first_name_required"),
                },
              ]}
              initialValue={currentUser?.firstName}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <span className={styles.inputLabel}>{t("last_name")}</span>
            <Form.Item
              name="lastName"
              validateTrigger={["onBlur", "onSubmit"]}
              rules={[
                {
                  required: true,
                  message: t("last_name_required"),
                },
              ]}
              initialValue={currentUser?.lastName}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <span className={styles.inputLabel}>{t("address")}</span>
        <StreetAddressLookup form={form} dataKey="street" />
        <Row gutter={16}>
          <Col className={styles.city} span={12}>
            <span className={styles.inputLabel}>{t("city")}</span>
            <Form.Item
              validateTrigger={["onBlur", "onSubmit"]}
              name="city"
              rules={[
                {
                  required: true,
                  message: t("city_required"),
                },
              ]}
            >
              <Input size="large" className={styles.input} name="city" />
            </Form.Item>
          </Col>
          <Col className={styles.state} span={5}>
            <span className={styles.inputLabel}>{t("state")}</span>
            <Form.Item
              name="state"
              validateTrigger={["onBlur", "onSubmit"]}
              rules={[
                {
                  required: true,
                  message: t("state_required"),
                },
              ]}
            >
              <Select size="large" optionFilterProp="children" className={styles.select} suffixIcon={<CaretBundle />}>
                {createStateOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col className={styles.zip} span={7}>
            <span className={styles.inputLabel}>{t("zipcode")}</span>
            <Form.Item
              name="zip"
              validateTrigger={["onBlur", "onSubmit"]}
              rules={[
                {
                  required: true,
                  message: t("zipcode_required"),
                },
                {
                  pattern:
                    /^(\d{5}(-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
                  message: "Please enter a valid US or Canadian postal code.",
                },
              ]}
            >
              <Input className={styles.input} size="large" maxLength={10} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

AddressForm.propTypes = {
  form: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onFinish: PropTypes.func,
  currentUser: User.types(),
};

export default AddressForm;
